import classNames from 'classnames';
import Hamburger from 'hamburger-react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import UserAvatar from '../UserAvatar';
import ExternalLinks from './ExternalLinks';
import MyCommunities from './MyCommunities';
import { NavbarContext } from './NavBarContext';
import TopLinks from './TopLinks';
import Logo from 'assets/graphics/logo.svg';
import MagnifyingGlass from 'assets/icons/magnifying-glass.svg';
import Scrollbar from 'components/Scrollbar';
import { useLoggedInUser } from 'contexts/user-context/UserContext';
import Anchor from 'styles/styled-components/Anchor';
import theme, { device } from 'styles/theme';

interface INavBar {
  className?: string;
}

function NavBar({ className }: INavBar): JSX.Element {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { user } = useLoggedInUser();

  return (
    <Container
      id='navbar'
      className={classNames({ 'mobile-menu-open': isMobileMenuOpen }, className)}
    >
      <NavbarContext.Provider value={{ isMobileMenuOpen, setMobileMenuOpen }}>
        <section className='logo'>
          <Link to='/articles'>
            <img src={Logo} />
          </Link>
          <div id='menu' className='mobile-side'>
            <UserAvatar user={user} className='user-avatar' />
            <Hamburger toggle={setMobileMenuOpen} toggled={isMobileMenuOpen} />
          </div>
        </section>
        <nav>

          <Link onClick={() => setMobileMenuOpen(false)} className='search' to='/articles/search'>
            <img src={MagnifyingGlass} />
          </Link>
          <Scrollbar className='scroll-container'>
            <TopLinks className='top-links' />
            <MyCommunities className='my-communities' setMobileMenuOpen={setMobileMenuOpen} />
          </Scrollbar>
          <ExternalLinks />

        </nav>
      </NavbarContext.Provider>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  background-color: ${theme.lighter};
  max-height: 100%;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .logo {
    padding: 30px 49px 65px 47px;

    img {
      width: 130px;
    }

    .mobile-side {
      display: none;
    }
  }

  .my-communities {
    margin-top: 20px;
    margin-bottom: 5px;

    .my-communities-link {
      padding: 5px 0;
      text-align: center;
    }
  }

  nav {
    & > ul .link {
      ${Anchor}
    }

    .search {
      display: none;
    }

    .scroll-container {
      ${device.tablet} {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  &:hover {
    .scroll-container {
      .track {
        opacity: 1 !important;
      }
    }
  }

  @media only screen and (max-height: 765px) {
    a.link {
      height: 49px;
    }

    .logo {
      padding-top: 25px;
      padding-bottom: 23px;
    }

    .my-communities {
      margin-top: 10px;
      margin-bottom: 0;

      .communities-link {
        top: 0;
      }
    }
  }

  ${device.tablet} {
    overflow-y: hidden;
    background-color: white;

    .logo {
      display: flex;
      justify-content: space-between;
      padding: 20px 0 5px;

      img {
        width: 160px;
      }

      .mobile-side {
        display: flex;
        align-items: center;

        .user-avatar {
          margin-right: 20px;
          zoom: 1.1;
        }

        .hamburger-react {
          z-index: 3;
        }
      }
    }

    nav {
      display: none;
    }

    &.mobile-menu-open {
      background-color: ${theme.lighter};

      nav {
        top: 0;
        left: 0;
        display: block;
        position: absolute;
        height: 100vh;
        width: 100vw;
        z-index: 2;
        background-color: inherit;

        .top-links {
          padding-top: 40px;

          @media only screen and (max-height: 680px) {
            padding-top: 20px;
          }
        }

        .search {
          display: flex;
          padding: 28px 25px 0;

          img {
            height: 32px;
          }
        }
      }
    }
  }

  ${device.mobile} {
    transition: top 0.3s;
    height: 64px;

    .logo {
      zoom: 0.8;
    }

    .search {
      zoom: 0.9;
      padding-top: 24px !important;
    }
  }
`;

export default NavBar;
