import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Else, If, Then } from 'react-if';
import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import ResourcesPage from '../resources/ResourcesPage';
import { PageNotFound } from './error/PageNotFound';
import News from './News';
import PageTemplate from './page-template/PageTemplate';
import SearchTabs from './page-template/SearchTabs';
import PrivateRoute from './PrivateRoute';
import ArticlePage from 'articles/ArticlePage';
import ArticlesTabs from 'articles/ArticlesTabs';
import LoginPage from 'auth/login/LoginPage';
import ApplyPasswordResetPage from 'auth/passwordReset/ApplyPasswordResetPage';
import CheckEmail from 'auth/passwordReset/CheckEmail';
import RequestPasswordResetPage from 'auth/passwordReset/RequestPasswordResetPage';
import SignUpPage from 'auth/sign-up/SignUpPage';
import UnsubscribePage from 'auth/unsubscribe/UnsubscribePage';
import CommunityPage from 'communities/CommunityPage';
import { useGlobalUserContext } from 'contexts/user-context/UserContext';
import NewDiscussion from 'discussions/components/NewDiscussion';
import Discussion from 'discussions/Discussion';
import DiscussionsTabs from 'discussions/DiscussionsTabs';
import CustomizeFeeds from 'feeds/CustomizeFeeds';
import ProfilePage from 'profile/ProfilePage';

function RootContainer(): JSX.Element {
  const { userState } = useGlobalUserContext();
  const { i18n } = useTranslation();

  useEffect(() => {
    async function changeLanguage(): Promise<void> {
      const lang = userState.user?.lang;
      if (lang && i18n.language !== lang) {
        await i18n.changeLanguage(lang);
      }
    }
    void changeLanguage();
  }, [i18n, userState]);

  return (
    <>
      <PageTemplate>
        <Switch>
          <Route exact path='/login'>
            <If condition={!!userState.user}>
              <Then>
                <Redirect to='/articles' />
              </Then>
              <Else>
                <LoginPage />
              </Else>
            </If>
          </Route>
          <Route exact path='/unsubscribe'>
            <UnsubscribePage />
          </Route>
          <Route exact path='/signup'>
            <SignUpPage />
          </Route>
          <PrivateRoute exact path='/'>
            <Redirect to='/articles' />
          </PrivateRoute>
          <PrivateRoute exact path='/articles/search'>
            <SearchTabs />
          </PrivateRoute>
          <Route exact path='/articles/:id'>
            <ArticlePage />
          </Route>
          <PrivateRoute exact path='/articles'>
            <ArticlesTabs />
          </PrivateRoute>
          <PrivateRoute exact path='/news'>
            <News />
          </PrivateRoute>
          <PrivateRoute exact path='/resources'>
            <ResourcesPage />
          </PrivateRoute>
          <PrivateRoute exact path={['/feeds', '/follow']}>
            <CustomizeFeeds />
          </PrivateRoute>
          <Route exact path='/communities/:slug'>
            <CommunityPage />
          </Route>
          <Route exact path='/password-reset/message'>
            <CheckEmail />
          </Route>
          <Route exact path='/password-reset'>
            <RequestPasswordResetPage />
          </Route>
          <Route exact path='/password-reset-apply'>
            <ApplyPasswordResetPage />
          </Route>
          <PrivateRoute exact path='/profile/:userId'>
            <ProfilePage />
          </PrivateRoute>
          <PrivateRoute exact path='/discussions/search'>
            <SearchTabs />
          </PrivateRoute>
          <PrivateRoute exact path='/discussions/new'>
            <NewDiscussion />
          </PrivateRoute>
          <PrivateRoute exact path='/discussions/:discussionId/edit'>
            <Discussion edit />
          </PrivateRoute>
          <PrivateRoute exact path='/discussions/:discussionId'>
            <Discussion />
          </PrivateRoute>
          <PrivateRoute exact path='/discussions'>
            <DiscussionsTabs />
          </PrivateRoute>
          <Route>
            <PageNotFound />
          </Route>
        </Switch>
      </PageTemplate>
      <ToastContainer />
    </>
  );
}

export default RootContainer;
