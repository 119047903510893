import styled from 'styled-components';

const FormError = styled.p`
  color: #FF4343;
  font-size: 14px;
  margin-top: 6px;
  margin-bottom: 8px;
`;

function FormErrorWrapper({ message }: { message: string }): JSX.Element {
  return <FormError>{message}</FormError>;
}

export {
  FormErrorWrapper as FormError,
};
