import styled from 'styled-components';

const FeedBadgeSquare = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  border-radius: 3px;
  margin-left: 1px;
  margin-right: 10px;
  width: 26px;
  min-width: 26px;
  height: 26px;
`;

export default FeedBadgeSquare;
