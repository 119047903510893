import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface IEmailLink {
  children: string;
}

export default function EmailLink({ children }: IEmailLink): JSX.Element {
  return (
    <StyledLink
      to='#'
      onClick={(e) => {
        window.location.href = `mailto:${children[0]}`;
        e.preventDefault();
      }}
    >
      {children}
    </StyledLink>
  );
}

const StyledLink = styled(Link)`
  text-decoration: none;
  font-weight: 500;
`;
