import parse from 'html-react-parser';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Else, If, Then } from 'react-if';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import ArticleCard from './ArticleCard';
import { IArticleDto } from 'api/dtos/article.dto';
import PaginatedScroll from 'components/PaginatedScroll';
import theme, { device } from 'styles/theme';

function SearchArticlesPage(): JSX.Element {
  const { search } = useLocation();
  const { t } = useTranslation();
  const [totalResults, setTotalResults] = useState(0);
  const searchParams = new URLSearchParams(search);
  const q = searchParams.get('q') || '';
  const [isLoading, setIsLoading] = useState(true);

  function articleCards(articles: IArticleDto[]): ReactNode {
    return articles.map((article) => <ArticleCard article={article} />);
  }

  const resultText = isLoading ? '' : parse(t('{{count}} results for <span>"{{searchTerm}}<span>"', { count: totalResults, searchTerm: q }));

  return (
    <Container>
      <If condition={q === ''}>
        <Then>
          <div>
            <p className='result'>{t('Make a search...')}</p>
          </div>
        </Then>
        <Else>
          <div>
            <p className='result'>{isLoading ? '' : <>{resultText}...</>}</p>
          </div>
          <PaginatedScroll<IArticleDto>
            url='/api/articles/search'
            queryParams={{ q }}
            onLoad={(data) => {
              setTotalResults(data.total);
              setIsLoading(false);
            }}
            emptyTabHeader={t('No articles found.')}
          >
            {(articles) => articleCards(articles)}
          </PaginatedScroll>
        </Else>
      </If>
    </Container>
  );
}
export default SearchArticlesPage;

const Container = styled.div`
  margin-bottom: 122px;

  .result {
    color: ${theme.gray};
    font-size: 18px;
    margin: 0 0 35px;

    span {
      color: black;
    }
  }

  ${device.tablet} {
    margin-top: -40px;

    .result {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
`;
