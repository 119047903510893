import useAxios from 'axios-hooks';
import { useErrorBoundary } from 'react-error-boundary';
import { Trans, useTranslation } from 'react-i18next';
import {
  Else, If, Then, When,
} from 'react-if';
import { Link, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import ArticleCard from './ArticleCard';
import ArticleTemplate from './ArticleTemplate';
import { IArticleDto } from 'api/dtos/article.dto';
import LoginForm from 'auth/login/LoginForm';
import { AxiosWrapper } from 'components/AxiosWrapper';
import { ListContainer } from 'components/Discussions';
import { useGlobalUserContext } from 'contexts/user-context/UserContext';
import ResourceItem from 'resources/ResourceItem';
import { device } from 'styles/theme';

const legacyArticleIdPattern: RegExp = /^[a-z]+(-.+)*$/;

export default function ArticlePage(): JSX.Element {
  const { showBoundary } = useErrorBoundary();
  const { userState } = useGlobalUserContext();
  const { t } = useTranslation();
  const history = useHistory();
  const articleId = useParams<{ id: string }>();
  const [article] = useAxios<IArticleDto>(`/api/articles/${articleId.id}`);

  if (article.error) {
    if (article.error.response?.status === 401) {
      history.push('/login');
    } else if (article.error.response?.status === 404 && legacyArticleIdPattern.test(articleId.id)) {
      history.push('/articles');
    } else {
      showBoundary(article.error);
    }
  }

  return (
    <AxiosWrapper requests={[{ loading: !article.data, error: null }]}>
      <If condition={!!userState.user}>
        <Then>
          <ListContainer>
            <If condition={article.data?.feed?.type === 'RESOURCES'}>
              <Then>
                <ResourceItem article={article.data} />
              </Then>
              <Else>
                <ArticleCard article={article.data} showPreview={article.data?.cropped} />
              </Else>
            </If>
          </ListContainer>
        </Then>

        <Else>
          <Container>
            <When condition={device.tablet}>
              <Trans><p className='sign-in'>To view more articles, please <Link to='/login'>sign in.</Link></p></Trans>
            </When>
            <ArticleTemplate article={article.data}>
              <div className='form-container'>
                <h5>{t('Member Login')}</h5>
                <LoginForm />
              </div>
            </ArticleTemplate>
          </Container>
        </Else>
      </If>
    </AxiosWrapper>
  );
}

const Container = styled.div`
  margin: 0 auto;
  width: 96.7%;

  .bottom-section {
    button {
      width: 160px;
    }
  }

  .sign-in {
    display: none;
    height: 0;
  }

  .form-container {
    width: 355px;
    margin-bottom: 245px;

    h5 {
      margin-bottom: 30px;
    }

    h1 {
      font-size: 40px;
      margin-bottom: 25px;
    }
  }

  ${device.tablet} {
    margin-top: 10px;

    .form-container {
      width: 100%;

      h1 {
        font-size: 35px;
      }
    }

    .sign-in {
      display: initial;
      position: relative;
      top: 15px;
    }
  }

  ${device.mobile} {
    .form-container {
      margin-bottom: 0;
    }
  }
`;
