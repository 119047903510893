import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import axios, { AxiosResponse } from 'axios';
import useAxios from 'axios-hooks';
import { format } from 'date-fns';
import DOMPurify from 'dompurify';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import parse from 'html-react-parser';
import { filter } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { When } from 'react-if';
import Modal from 'react-modal';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { ICommunity, IFile } from '../api/tables';
import { AxiosWrapper } from '../components/AxiosWrapper';
import Attachments from './components/Attachments';
import EditDiscussion from './components/EditDiscussion';
import EditorFileDrop from './components/EditorFileDrop';
import { removeInlineStyles } from './components/NewDiscussion';
import Reply from './components/Reply';
import { sanitizeBody } from './DiscussionHelpers';
import { IDiscussionDto, IDiscussionReplyDto } from 'api/dtos/discussion.dto';
import { getDisplayName } from 'api/utils';
import pencil from 'assets/icons/draft/pencil.svg';
import CommunityLogo from 'components/CommunityLogo';
import { addExpandableImages } from 'components/ExpandableImage';
import { AsyncButton } from 'components/form/AsyncButton';
import LinkifyInNewTab from 'components/LinkifyInNewTab';
import UserAvatar from 'components/page-template/UserAvatar';
import { useLoggedInUser } from 'contexts/user-context/UserContext';
import { StyledLink } from 'styles/styled-components';
import theme, { device } from 'styles/theme';

interface INewReply {
  body: string;
  parent_id?: number;
  attachments: IFile[];
}

export default function Discussion({ edit = false }: { edit?: boolean }): JSX.Element {
  const { t } = useTranslation();
  const { handleSubmit, formState } = useForm();
  const [editing, setEditing] = useState<boolean>(edit);
  const [replying, setReplying] = useState<boolean>(false);
  const [attachments, setAttachments] = useState<IFile[]>([]);
  const [prevAttachments, setPrevAttachments] = useState<IFile[]>([]);
  const { discussionId } = useParams<{ discussionId: string }>();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [data, refetchDiscussion] = useAxios<IDiscussionDto>(`/api/discussions/${discussionId}`);
  const [replies, refetchReplies] = useAxios<IDiscussionReplyDto[]>(`/api/discussions/${discussionId}/replies`);
  const [community, refetchCommunity] = useAxios<ICommunity>(`/api/communities/${data.data?.community.id}`);
  const refetch = async (): Promise<void> => {
    await refetchDiscussion();
    await refetchReplies();
    await refetchCommunity();
  };
  const { user: loggedUser } = useLoggedInUser();
  const path = document.location.href.replace('/edit', '');

  const discussion = data.data;
  const user = discussion?.user;
  useEffect(() => {
    const menu = document.getElementById('menu') as HTMLElement;
    if (editing) {
      menu.style.zIndex = '0';
      window.history.pushState('', '', `${path}/edit`);
    } else {
      menu.style.zIndex = '3';
      window.history.pushState('', '', path);
    }
  }, [editing, path]);

  function getParentReplies(discussionReplies: IDiscussionReplyDto[] | undefined): JSX.Element[] | null {
    if (!discussionReplies) return null;
    const parentReplies = filter(discussionReplies, { parent_id: null });
    const replyElements = parentReplies.map((e) => <Reply key={e.id} reply={e} replies={discussionReplies} refetch={refetch} />);

    return replyElements;
  }

  useEffect(() => {
    async function getFiles(): Promise<void> {
      if (discussion?.attachments) {
        const files = [] as AxiosResponse<any, any>[];
        const promises = discussion.attachments.map((attachment) => axios.get(`/api/files/${attachment.file_id}`)
          .then((file) => files.push(file)));

        await Promise.all(promises);

        const filesData = files.map((file, i) => ({
          id: discussion.attachments![i].file_id,
          name: file.headers.name,
          size: Number(file.headers.size),
          extension: file.headers.extension,
          created_at: new Date(),
          updated_at: new Date(),
        }));
        setPrevAttachments(filesData);
      }
    }
    void getFiles();
  }, [setPrevAttachments, discussion?.attachments, editing]);

  async function onSubmit(): Promise<void> {
    if (draftToHtml(convertToRaw(editorState.getCurrentContent())).trim() === '<p></p>') {
      toast.error(t('Your reply appears to be empty!'));
      return;
    }

    setReplying(false);
    try {
      await axios.post<Partial<INewReply>, AxiosResponse>(
        `/api/discussions/${discussionId}/replies`,
        {
          body: removeInlineStyles(editorState),
          parent_id: null,
          attachments,
        },
      );
      toast.success('Posted Reply!');
      setEditorState(EditorState.createEmpty());
      void refetchReplies();
    } catch (error) {
      toast.error('Error posting reply');
      console.error(error);
    }

    await refetchDiscussion();
  }

  const creationDate = useMemo(() => (discussion ? format(new Date(discussion.created_at), 'PP') : null), [discussion]);
  const numOfReplies = useMemo(() => {
    if (!discussion?.replyCount) return t('No replies yet. Can you help?');
    if (discussion.replyCount === 1) return t('1 reply');
    return t('{{count}} replies', { count: discussion.replyCount });
  }, [discussion?.replyCount, t]);

  // Add a hook to make all links open a new window
  DOMPurify.addHook('afterSanitizeAttributes', (node) => {
    // set all elements owning target to target=_blank
    if ('target' in node) {
      node.setAttribute('target', '_blank');
    }
    // set non-HTML/MathML links to xlink:show=new
    if (
      !node.hasAttribute('target')
      && (node.hasAttribute('xlink:href') || node.hasAttribute('href'))
    ) {
      node.setAttribute('xlink:show', 'new');
    }
  });
  const discussionBody = parse(sanitizeBody(discussion?.body || ''), addExpandableImages);
  return (
    <AxiosWrapper requests={[data]}>
      <Container>
        {/* <div className='back-button' onClick={() => { localStorage.setItem('editing', 'false'); setEditing(false); }}>
          <Link to={editing ? `/discussions/${discussionId}` : '/discussions'}>
            <p>{`← ${t('Back')}`}</p>
          </Link>
        </div> */}

        <When condition={editing}>
          <Modal id='edit-modal' isOpen={editing} style={editModal} ariaHideApp={false}>
            <EditDiscussion onClose={() => setEditing(false)} discussion={discussion!} refetch={refetchDiscussion} prevAttachments={prevAttachments} />
          </Modal>
        </When>

        <div className='avatar-name-logo'>
          <div className='avatar-name'>
            <UserAvatar className='avatar' user={user!} size={60} profileLink />
            <p className='name'>
              <StyledLink className='user-link' to={`/profile/${user?.id}`}>{user ? getDisplayName(user) : ''}&nbsp;</StyledLink>
              <span className='city-country'>({user?.city}, {user?.country})&nbsp;</span>
              <span className='community'>{t('asked in')} {discussion?.community.name}&nbsp;</span>
              <span className='date'>{t('on')} {creationDate} &nbsp;&nbsp;</span>
            </p>
          </div>
          <CommunityLogo id={discussion?.community.id || 0} logoUrl={community.data?.logo_url || ''} name={discussion?.community.name} className='community-logo' />
        </div>
        <h3 className='discussion-title'>{discussion?.title}</h3>
        <br />
        <When condition={!!discussion}>
          <LinkifyInNewTab>
            <div className='discussion-body'>{discussionBody}</div>
          </LinkifyInNewTab>
        </When>
        <Attachments attachments={discussion ? discussion.attachments : null} />

        <When condition={replying}>
          <form onSubmit={handleSubmit(onSubmit)} className='reply-form'>
            <div className='reply-editor'>
              <EditorFileDrop
                attachments={attachments}
                setAttachments={setAttachments}
                editorState={editorState}
                setEditorState={setEditorState}
              />
            </div>
            <div className='submit-cancel'>
              <AsyncButton resetAfterSubmit className='reply' type='submit' formState={formState}>{t('Reply')}</AsyncButton>
              <button className='cancel' type='button' onClick={() => setReplying(false)}>{t('Cancel')}</button>
            </div>
          </form>
        </When>

        <When condition={!replying && !editing}>
          <button className='reply' onClick={() => setReplying(true)}>{t('Reply')}</button>
          <When condition={loggedUser.id === user?.id}>
            <span
              className='edit-question'
              onClick={() => {
                localStorage.setItem('editing', 'true');
                setEditing(true);
                setReplying(false);
              }}
            >
              <img src={pencil} />{t('Edit')}
            </span>
          </When>
        </When>

        <AxiosWrapper requests={[replies]}>
          <p className='number-replies'>{numOfReplies}</p>
          <div className='replies'>
            {getParentReplies(replies.data)}
          </div>
        </AxiosWrapper>

      </Container>
    </AxiosWrapper>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  border-top: 4px solid #F0F6FF;
  padding: 65px 110px 90px;
  padding-right: 130px;
  letter-spacing: 0;

  @media (max-width: 1308px) {
    padding: 65px 85px 90px;
    padding-right: 85px;
  }

  p {
    margin: 0;
    text-transform: None;
    color: #BAB9B9;
    line-height: normal;
    font-size: 14px;
    font-family: 'Liberation Sans', Inter, sans-serif;
  }

  .back-button {
    margin-bottom: 45px;

    p {
      font-size: 16px;
      font-family: Inter, sans-serif;
      color: ${theme.grayDark};
    }
  }

  .avatar {
    margin-bottom: 2px;
  }

  .user-link {
    text-decoration: none;
    display: inline-block;
  }

  .avatar-name-logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0 0 12px;

    .avatar-name {
      display: flex;
      align-items: center;

      .name {
        font-size: 16px;
        margin: 0 0 0 10px;
      }

      .community,
      .date,
      .city-country {
        display: inline-block;
      }
    }

    img.community-logo {
      width: 75px;
    }
  }

  .edit-question {
    color: ${theme.dark};
    margin-left: 27px;
    font-size: 17px;
    cursor: pointer;

    img {
      width: 19px;
      height: 19px;
      position: relative;
      top: 3px;
      right: 5px;
    }
  }

  h3.discussion-title {
    color: #000;
    font-family: Lora, serif;
    font-size: 23px;
    line-height: 30px;
    font-weight: 500;
  }

  .discussion-body {
    font-size: 16px;
    margin: 0 0 25px;
    color: ${theme.text};

    li {
      color: ${theme.text};
    }

    ol,
    ul {
      list-style-position: outside;
      margin-left: 25px;
      margin-bottom: 15px;
      line-height: 1.3;
    }

    p,
    span {
      color: ${theme.text} !important;
      font-size: 16px;
      margin-bottom: 15px;
    }
  }

  .reply-editor {
    position: relative;
  }

  button.reply {
    padding: 20px 52px;
  }

  .submit-cancel {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 30px 0 67px;

    button.cancel {
      text-transform: None;
      background-color: transparent;
      box-shadow: none;
      color: #000;
      padding: 13px 25px;
      position: relative;

      &:hover {
        background-color: transparent;
      }
    }
  }

  p.number-replies {
    font-size: 18px;
    color: ${theme.grayDark};
    margin: 65px 0 33px;
  }

  .replies {
    margin: 0 0 0 30px;
  }

  .reply-form {
    right: 1px;
    position: relative;

    span {
      bottom: 0 !important;
    }
  }

  ${device.mobile} {
    padding: 65px 0 90px;
    padding-right: 0;

    .avatar-name-logo {
      .avatar-name {
        .name {
          margin: 0 0 0 7px;
        }
      }

      img.community-logo {
        display: none;
      }
    }

    .discussion-body {
      margin: 0 0 16px;
    }

    p.number-replies {
      font-size: 16px;
      margin: 65px 0 18px;
    }

    .replies {
      margin: 0;
      gap: 10px;
    }
  }
`;
/* eslint-disable @typescript-eslint/indent */
const editModal = {
    overflowY: 'scroll',

    content: {
      height: 'auto',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      border: 'none',
      borderRadius: '4px',
      boxShadow: '0 14px 54px rgb(112 112 112 / 35%)',
    },
};
/* eslint-enable @typescript-eslint/indent */
