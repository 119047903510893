import styled from 'styled-components';

import Article from './Article';
import { IArticleDto } from 'api/dtos/article.dto';
import Card from 'styles/styled-components/Card';
import { device } from 'styles/theme';

interface IArticleCard {
  article: IArticleDto | undefined | null;
  showPreview?: boolean;
}

export default function ArticleCard({ article, showPreview }: IArticleCard): JSX.Element {
  return (
    <CardContainer className='article-card'>
      <Article article={article} showPreview={showPreview} />
    </CardContainer>
  );
}

const CardContainer = styled(Card)`
  margin-bottom: 25px;
  min-width: 777px;

  ${device.tablet} {
    min-width: 100px;
  }
`;
