import axios, { AxiosResponse } from 'axios';
import draftToHtml from 'draftjs-to-html';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import EditorFileDrop from './EditorFileDrop';
import { removeInlineStyles } from './NewDiscussion';
import { IDiscussionDto, INewDiscussionWithAttachmentIdsDto } from 'api/dtos/discussion.dto';
import { IFile } from 'api/tables';
import { AsyncButton } from 'components/form/AsyncButton';
import TextArea from 'components/form/TextArea';
import { useLoggedInUser } from 'contexts/user-context/UserContext';
import { EditorState } from 'discussions/EditorState';
import { device } from 'styles/theme';

interface ISubmitDataType {
  title: string;
  community_id: string;
}

interface INewDiscussionFormType extends ISubmitDataType {
  body: string;
  user_id: number;
  attachmentIds: string[];
}

interface IEditDiscussion {
  discussion: IDiscussionDto;
  prevAttachments: IFile[];
  onClose(): void;
  refetch(): void;
}

export default function EditDiscussion({
  discussion, prevAttachments, onClose, refetch,
}: IEditDiscussion): JSX.Element {
  const { t } = useTranslation();
  const { user } = useLoggedInUser();
  const [editorState, setEditorState] = useState(() => EditorState.createWithBody(discussion.body!));
  const [attachments, setAttachments] = useState<IFile[]>(prevAttachments || []);
  const {
    register, handleSubmit, setValue, control, formState,
  } = useForm<INewDiscussionFormType>();

  useEffect(() => setValue('title', discussion.title), []);

  async function onSubmit(submitData: ISubmitDataType): Promise<void> {
    const { title } = submitData;
    const { community_id } = submitData;

    if (!title) {
      toast.error(t('Cannot post a question without a title!'));
      return;
    }

    try {
      await axios.post<INewDiscussionWithAttachmentIdsDto, AxiosResponse>(
        `/api/discussions/${discussion.id}`,
        {
          discussion: {
            id: discussion.id,
            body: removeInlineStyles(editorState),
            title,
            community_id,
            user_id: user.id,
          },
          attachments,
        },
      );
      toast.success(t('Question updated'));
      refetch();
      onClose();
    } catch (e) {
      toast.error(t('Error updating question'));
      console.error(e);
    }
  }

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextArea
          className='discussion-title'
          type='text'
          label={t('Title')}
          placeholder={`${t('Ask your question here')}.`}
          control={control}
          maxLength={500}
          {...register('title', {})}
        />
        <div className='discussion-description'>
          <label className="input-label-text">{t('Additional details')}</label>
          <EditorFileDrop
            attachments={attachments}
            setAttachments={setAttachments}
            editorState={editorState}
            setEditorState={setEditorState}
            placeholder={t('Please provide your colleagues with any details or background that would help them answer your question.')}
            type='edit-discussion'
          />
        </div>
        <div className='submit-cancel'>
          <AsyncButton resetAfterSubmit value='Submit Question' type='submit' formState={formState}>{t('Save Changes')}</AsyncButton>
          <button className='cancel' type='button' onClick={onClose}>{t('Cancel')}</button>
        </div>
      </form>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  max-height: 83vh;
  max-width: 656px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  textarea {
    width: 644px;
  }

  textarea:active {
    transform: none;
    text-transform: none;
  }

  textarea:focus,
  textarea:active {
    outline: none;
    border-color: black;
    outline-color: black;
  }

  .discussion-title,
  .discussion-community {
    margin: 0 0 35px;
  }

  .discussion-community {
    margin-top: 57px;
  }

  .discussion-description {
    margin: 0 0 20px;
    position: relative;

    .file-name {
      padding-top: 2px;
      position: relative;
      right: 5px;
    }
  }

  label {
    font-size: 18px;
    color: #000;
    margin: 0 0 11px;
    text-transform: None;
  }

  .submit-cancel {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 30px 0 40px;

    button.cancel {
      text-transform: None;
      background-color: transparent;
      color: #000;
      box-shadow: none;
      position: relative;
      right: 5px;

      &:hover {
        background-color: transparent;
      }
    }
  }

  ${device.mobile} {
    max-width: 480px;
    overflow-x: hidden;

    .submit-cancel {
      margin: 0;
      margin-top: -5px;
      width: 110%;
    }

    .discussion-description {
      margin-top: -5px;
    }

    textarea {
      width: 80vw;
    }
  }
`;
