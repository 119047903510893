import axios, { AxiosResponse } from 'axios';
import { format } from 'date-fns';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import parse from 'html-react-parser';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { When } from 'react-if';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import Attachments from './Attachments';
import EditorFileDrop from './EditorFileDrop';
import { removeInlineStyles } from './NewDiscussion';
import { IDiscussionReplyDto } from 'api/dtos/discussion.dto';
import { IFile } from 'api/tables';
import { getDisplayName } from 'api/utils';
import pencil from 'assets/icons/draft/pencil.svg';
import { addExpandableImages } from 'components/ExpandableImage';
import { AsyncButton } from 'components/form/AsyncButton';
import LinkifyInNewTab from 'components/LinkifyInNewTab';
import UserAvatar from 'components/page-template/UserAvatar';
import { useLoggedInUser } from 'contexts/user-context/UserContext';
import { sanitizeBody } from 'discussions/DiscussionHelpers';
import { EditorState } from 'discussions/EditorState';
import { StyledLink } from 'styles/styled-components';
import theme, { device } from 'styles/theme';

interface IReply {
  reply: IDiscussionReplyDto;
  replies: IDiscussionReplyDto[];
  refetch(): void;
}

interface INewReply {
  body: string;
  parent_id?: number;
  attachmentIds: string[];
}

export default function Reply({ reply, replies, refetch }: IReply): JSX.Element {
  const { t } = useTranslation();
  const { handleSubmit, formState } = useForm();
  const [replying, setReplying] = useState(false);
  const [editing, setEditing] = useState(false);
  const directChildren = replies.filter((r) => r.parent_id === reply.id);
  const [attachments, setAttachments] = useState<IFile[]>([]);
  const [prevAttachments, setPrevAttachments] = useState<IFile[]>([]);
  const { user: loggedUser } = useLoggedInUser();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [updateState, setUpdateState] = useState(EditorState.createWithBody(reply.body));

  const children = useMemo(() => {
    const elements = directChildren.map((r) => <Reply key={r.id} reply={r} replies={replies} refetch={refetch} />);
    return elements;
  }, [directChildren, refetch, replies]);

  useEffect(() => {
    async function getFiles(): Promise<void> {
      const files = [] as AxiosResponse<any, any>[];
      const promises = reply.attachments.map((attachment, i) => axios.get(`/api/files/${attachment.file_id}`)
        .then((file) => files.push(file)));

      await Promise.all(promises);

      const filesData = files.map((file, i) => ({
        id: reply.attachments[i].file_id,
        name: file.headers.name,
        size: Number(file.headers.size),
        extension: file.headers.extension,
        created_at: new Date(),
        updated_at: new Date(),
      }));

      setPrevAttachments(filesData as unknown as IFile[]);
    }
    void getFiles();
  }, [setPrevAttachments, reply.attachments]);

  async function onSubmit(): Promise<void> {
    if (draftToHtml(convertToRaw(editorState.getCurrentContent())).trim() === '<p></p>') {
      toast.error(t('Your reply appears to be empty'));
      return;
    }
    try {
      await axios.post<INewReply, AxiosResponse>(
        `/api/discussions/${reply.discussion_id}/replies`,
        {
          body: removeInlineStyles(editorState),
          parent_id: reply.id,
          attachments,
        },
      );

      setReplying(false);
      toast.success(t('Reply posted'));
      setEditorState(EditorState.createEmpty());
      refetch();
    } catch (error) {
      toast.error(t('Error posting reply'));
      console.error(error);
    }
  }

  async function onUpdate(): Promise<void> {
    if (draftToHtml(convertToRaw(updateState.getCurrentContent())).trim() === '<p></p>') {
      toast.error(t('Your reply appears to be empty'));
      return;
    }
    try {
      await axios.post<INewReply, AxiosResponse>(
        `/api/discussions/${reply.discussion_id}/replies/${reply.id}`,
        {
          body: removeInlineStyles(updateState),
          attachments: prevAttachments,
        },
      );
      setEditing(false);
      toast.success(t('Reply updated'));
      refetch();
    } catch (error) {
      toast.error(t('Error updating reply'));
      console.error(error);
    }
  }

  const creationDate = format(new Date(reply.created_at), 'PP');
  const replyBody = parse(sanitizeBody(reply.body), addExpandableImages);

  return (
    <Container>
      <div className='avatar-name'>
        <UserAvatar className='avatar' user={reply.user} size={50} profileLink />
        <p className='name'>
          <StyledLink className='user-link' to={`/profile/${reply.user.id}`}>{getDisplayName(reply.user)}&nbsp;</StyledLink>
          <span className='city-country'>({reply.user.city}, {reply.user.country})&nbsp;</span>
          <span className='date'>{t('answered on')} {creationDate} &nbsp;&nbsp;</span>
        </p>
      </div>
      <div className='reply-line'>
        <div className='vertical-line' />

        <When condition={editing}>
          <form onSubmit={handleSubmit(onUpdate)} className='edit-reply-form'>
            <div className='reply-editor'>
              <EditorFileDrop
                attachments={prevAttachments}
                setAttachments={setPrevAttachments}
                editorState={updateState}
                setEditorState={setUpdateState}
              />
            </div>
            <div className='submit-cancel'>
              <AsyncButton resetAfterSubmit className='submit' type='submit' formState={formState}>{t('Save changes')}</AsyncButton>
              <button className='cancel' type='button' onClick={() => setEditing(false)}>{t('Cancel')}</button>
            </div>
          </form>
        </When>

        <When condition={!editing}>
          <div className='reply'>
            <div className='reply-body'>
              <LinkifyInNewTab>
                {replyBody}
              </LinkifyInNewTab>
            </div>
            <Attachments attachments={reply.attachments} />

            <button className='toggle-reply' onClick={() => setReplying(!replying)}>{t('Reply')}</button>
            <When condition={loggedUser.id === reply.user.id}>
              <span className='edit-reply' onClick={() => setEditing(!editing)}><img src={pencil} />{t('Edit')}</span>
            </When>

            <When condition={replying}>
              <form onSubmit={handleSubmit(onSubmit)} className='reply-form'>
                <div className='reply-editor'>
                  <EditorFileDrop
                    attachments={attachments}
                    setAttachments={setAttachments}
                    editorState={editorState}
                    setEditorState={setEditorState}
                  />
                </div>
                <div className='submit-cancel'>
                  <AsyncButton resetAfterSubmit className='submit' type='submit' formState={formState}>{t('Reply')}</AsyncButton>
                  <button className='cancel' type='button' onClick={() => setReplying(!replying)}>{t('Cancel')}</button>
                </div>
              </form>
            </When>
            <div className='child-replies'>
              {children}
            </div>
          </div>
        </When>
      </div>

    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .avatar-name {
    display: flex;
    align-items: center;
    margin-top: 30px;

    .name {
      margin: 0 0 0 17px;
      font-size: 16px;

      .city-country,
      .date {
        color: #BAB9B9;
        display: inline-block;
      }
    }

    .user-link {
      text-decoration: none;
      display: inline-block;
    }
  }

  .edit-reply {
    color: ${theme.dark};
    position: relative;
    top: 16px;
    margin-left: 27px;
    font-size: 17px;
    cursor: pointer;
    height: 0;
    padding: 0;

    img {
      width: 19px;
      height: 19px;
      position: relative;
      top: 3px;
      right: 5px;
    }
  }

  .reply-form {
    margin-top: 10px;

    .reply-editor {
      position: relative;
    }
  }

  .reply-form,
  .edit-reply-form {
    position: relative;

    .submit {
      height: 55px;
      margin: 0;
      align-items: center;
      display: inline-flex;
    }

    li,
    ol,
    ul {
      color: black;
    }

    .submit-cancel {
      margin-top: 5px;
    }
  }

  .reply-line {
    display: flex;

    .vertical-line {
      border: 2px solid #ECECEC;
      margin: -5px 23px -30px;
      background-color: #ECECEC;
    }

    .reply {
      width: 100%;
      margin: 0 0 0 17px;

      .container {
        padding-bottom: 0;
      }

      .image-name {
        color: black !important;
      }

      .create-date {
        color: ${theme.grayLight};
        font-size: 16px;
        margin: 0 0 10px;
        display: inline-block;
      }

      p {
        color: ${theme.text} !important;
        font-size: 16px;
        margin-bottom: 15px;
      }

      .reply-body {
        font-size: 16px;
        color: ${theme.text} !important;
        text-wrap: normal;

        p,
        span {
          color: ${theme.text} !important;
          overflow-wrap: break-word;
        }

        li,
        ul,
        ol {
          font-size: 16px;
        }
      }

      button.toggle-reply {
        position: relative;
        top: 16px;
        text-transform: None;
        background-color: transparent;
        color: ${theme.primary};
        padding: 0;
        box-shadow: none;
        margin: 0 0 19px;
        font-size: 16px;

        &:hover {
          background-color: transparent;
        }
      }

      .submit-cancel {
        margin: 5px 0 30px;

        .submit {
          padding: 0 50px;
          margin: 10px 0 0 !important;
        }

        button.cancel {
          text-transform: None;
          background-color: transparent;
          color: #000;
          padding: 13px 25px;

          &:hover {
            background-color: transparent;
          }
        }
      }
    }
  }

  ${device.mobile} {
    .avatar-name {
      .avatar {
        flex-shrink: 2;
      }

      .name {
        margin: 0 0 0 5px;
      }
    }

    .reply-line {
      .vertical-line {
        display: none;
      }

      .reply {
        margin: 10px 0 0;

        button.toggle-reply {
          top: 12px;
          margin: -5px 0 50px;
        }

        .child-replies {
          margin: 0 0 0 20px;
        }
      }
    }
  }
`;
