import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import PasswordResetForm from './RequestPasswordResetForm';
import SignInTemplate from 'components/page-template/SignInTemplate';
import { device } from 'styles/theme';

export default function RequestPasswordResetPage(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Container>
      <SignInTemplate>
        <div className="form-container">
          <h1>{t('Reset my password?')}</h1>
          <p>{t('Enter the email associated with your account and we\'ll send an email with instructions to reset your password.')}</p>
          <PasswordResetForm />
        </div>
      </SignInTemplate>
    </Container>
  );
}

const Container = styled.div`
  .form-container {
    width: 100%;

    & > h1 {
      font-size: 40px;
      font-family: 'Liberation Sans', Helvetica, sans-serif;
      margin-bottom: 21px;
    }

    & > p {
      font-size: 16px;
      color: #787878;
      margin-bottom: 25px;
    }
  }

  ${device.mobile} {
    .form-container {
      & > h1 {
        font-size: 35px;
        margin-bottom: 17px;
      }

      & > p {
        margin-bottom: 23px;
      }
    }
  }
`;
