import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import LoginForm from './LoginForm';
import SignInTemplate from 'components/page-template/SignInTemplate';
import { device } from 'styles/theme';

function LoginPage(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Container>
      <SignInTemplate>
        <div className="form-container">
          <h5>{t('Sign In')}</h5>
          <h1>{t('Welcome back!')}</h1>
          <LoginForm />
        </div>
      </SignInTemplate>
    </Container>
  );
}

export default LoginPage;

const Container = styled.div`
  .form-container {
    width: 464px;

    h5 {
      margin-bottom: 10px;
    }

    h1 {
      font-size: 40px;
      margin-bottom: 25px;
    }
  }

  ${device.mobile} {
    .form-container {
      width: 100%;

      h1 {
        font-size: 35px;
      }
    }
  }
`;
