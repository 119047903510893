import styled, { css } from 'styled-components';

import theme, { device } from 'styles/theme';

const button = css`
  border: none;
  background-color: ${theme.primary};
  border-radius: 30px;
  color: white;
  padding: 23px 38px;
  line-height: 15px;
  font-size: 17px;
  transition: opacity 250ms;
  box-shadow: 0 2px 4px 0 #003CFF38;

  &:hover {
    cursor: pointer;
    background-color: ${theme.dark};
  }

  &:disabled {
    opacity: 0.8;

    &:hover {
      cursor: wait;
    }
  }

  /* stylelint-disable-next-line selector-class-pattern */
  &.Toastify__close-button {
    background-color: transparent;
  }
`;

export const ActionButton = styled.button`
  position: absolute;
  bottom: 40px;
  right: 41px;

  ${device.mobile} {
    height: 54px;
    width: calc(100vw - 40px);
    right: 20px;
    left: 17.5px;
    bottom: calc(0vh + 10px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export default button;
