import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';
import {
  ForwardedRef, HTMLInputTypeAttribute, forwardRef, useState,
} from 'react';
import {
  Control, FieldErrors, FieldValues, UseFormRegisterReturn, useFormState,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { When } from 'react-if';
import styled from 'styled-components';

import resizer from 'assets/icons/draft/resizer.svg';

interface IInput extends UseFormRegisterReturn {
  id?: string;
  label?: string;
  placeholder?: string;
  className?: string;
  type: HTMLInputTypeAttribute;
  control?: Control<any>;
  maxLength?: number;
}

function TextArea({
  className, label, name, control, maxLength, ...attr
}: IInput, ref: ForwardedRef<HTMLTextAreaElement>): JSX.Element {
  const { errors } = useFormState({ control });
  const { t } = useTranslation();
  const [count, setCount] = useState(0);

  return (
    <Container className={className}>
      <When condition={label}>
        <label>{label}</label>
      </When>
      <div className='input-group'>
        <img className='resizer' src={resizer} />
        <textarea
          className={classNames({ invalid: !!errors[name] })}
          name={name}
          {...attr}
          placeholder={attr.placeholder || label}
          ref={ref}
          maxLength={maxLength}
          onChange={(e) => setCount(e.target.value.length)}
        />
      </div>
      <ErrorMessage errors={errors} name={name} as="p" />
      <When condition={!!maxLength}>
        <div className='character-counter'>
          {`${t('Characters remaining')}: `} <span>{maxLength as number - count}</span>
        </div>
      </When>
    </Container>
  );
}

export default forwardRef(TextArea);

const Container = styled.div`
  .input-group {
    text-align: right;
    position: relative;
    display: inline-block;
  }

  .resizer {
    position: absolute;
    bottom: 13px;
    right: 5px;
    pointer-events: none;
    z-index: 2;
  }

  textarea {
    border-radius: 10px;
    border: 0.5px solid #A4A4A4;
    width: 656px;
    min-height: 54px;
    height: 100px;
    resize: vertical !important;
    -ms-overflow-style: none;
    scrollbar-width: none;
    font-size: 16px;
    padding: 10px;
    padding-top: 16px;
  }

  textarea::-webkit-resizer {
    display: none;
  }

  textarea::-webkit-scrollbar {
    color: white;
  }

  p {
    color: #FF4343;
    font-size: 14px;
    margin-top: 6px;
    margin-bottom: 8px;
  }

  .character-counter {
    margin-top: 0;
  }

  span {
    font-size: 14px;
    font-weight: 700;
  }
`;
