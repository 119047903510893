import {
  Else, If, Then, When,
} from 'react-if';
import styled from 'styled-components';

import { Footer } from './Footer';
import LoginNav from './nav/LoginNav';
import NavBar from './nav/NavBar';
import TopBar from './TopBar';
import { useGlobalUserContext } from 'contexts/user-context/UserContext';
import { device } from 'styles/theme';

interface IPageTemplate {
  children: JSX.Element | JSX.Element[];
}

function PageTemplate({ children }: IPageTemplate): JSX.Element {
  const { userState: { user } } = useGlobalUserContext();

  return (
    <Container className={user ? '' : 'public'}>
      <When condition={!!user}>
        <NavBar className='navbar' />
      </When>
      <main id='main'>
        <div>
          <If condition={!!user}>
            <Then>
              <TopBar className='topbar' />
            </Then>
            <Else>
              <LoginNav />
            </Else>
          </If>
          <div className='content'>
            {children}
          </div>
          <When condition={!user}>
            <Footer className='footer' />
          </When>
        </div>
      </main>
    </Container>
  );
}

export default PageTemplate;

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  position: relative;

  main {
    height: 100%;
    width: calc(100% - 247px);
    overflow-y: auto;

    & > div {
      display: grid;
      padding: 0 40px;
      max-width: 1400px;
      grid-template-columns: 100%;
      grid-template-rows: 127px auto auto;
      min-height: 100%;
      height: auto;

      .footer {
        align-self: flex-end;
        grid-row: -1;
      }
    }
  }

  .navbar {
    width: 248px;
    overflow-y: scroll;
  }

  .navbar::-webkit-scrollbar {
    display: none;
  }

  &.public {
    margin: 0 auto;

    main {
      width: 100%;

      .content {
        grid-row: 2 / 3;
      }
    }
  }

  ${device.tablet} {
    flex-direction: column;

    main {
      width: 100%;

      & > div {
        grid-template-rows: fit-content auto auto;

        .topbar {
          margin-bottom: 40px;
          align-self: flex-start;
        }
      }
    }

    main > div,
    .navbar {
      padding: 0 25px;
      overflow-y: hidden;
    }

    .navbar {
      width: 100%;
      padding-bottom: 30px;
    }
  }

  ${device.mobile} {
    .navbar {
      margin-bottom: 0;
    }
  }
`;
