/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { captureException } from '@sentry/react';
import i18n, { InitOptions } from 'i18next';
import Backend from 'i18next-locize-backend';
import { initReactI18next } from 'react-i18next';

import asyncBrowserLanguageDetector from './asyncBrowserLanguageDetector';

const locizeOptions = {
  projectId: 'd7957c64-8a85-44bf-81b5-e58d76248edf',
  apiKey: 'b617a70d-b5cb-475b-9dd1-73dedbd590a4',
  referenceLng: 'en-CA',
};

const baseConfig: InitOptions = {
  fallbackLng: (code) => {
    if (code.startsWith('fr')) return 'fr-CA';
    return 'en-CA';
  },
  interpolation: {
    escapeValue: false,
  },
  returnEmptyString: false,
  returnNull: false,
};

function initDev(): void {
  i18n
    .use(asyncBrowserLanguageDetector)
    .use(Backend)
    .use(initReactI18next)
    .init({
      ...baseConfig,
      debug: true,
      backend: locizeOptions,
    });
}

function initProd(): void {
  try {
    i18n
      .use(asyncBrowserLanguageDetector)
      .use(initReactI18next)
      .init({
        ...baseConfig,
      })
      .catch((e) => {
        console.error(e);
        captureException(e);
      });

    // TODO - Figure out this issue with ESLint
    const fr = require('locales/fr-CA/translation.json');
    // eslint-disable-next-line global-require
    const en = require('locales/en-CA/translation.json');

    i18n.addResourceBundle('en-CA', 'translation', en);
    i18n.addResourceBundle('fr-CA', 'translation', fr);
  } catch (e) {
    captureException(e);
  }
}

if (process.env.NODE_ENV === 'production') initProd();
else initDev();
