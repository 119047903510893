import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import useAxios from 'axios-hooks';
import { useMemo } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { When } from 'react-if';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  SchemaOf, array, boolean, number, object, string,
} from 'yup';

import { EditProfileForm } from './components/EditProfileForm';
import { IUpdateProfileForm } from './IUpdateProfileForm';
import titleOptions from './titleOptions.json';
import UploadAvatar from './UploadAvatar';
import { INewUserDto, IUserDto } from 'api/dtos/user.dto';
import {
  ICountry,
  IOrganization,
  IPracticeArea,
  IProfessionalRole,
} from 'api/tables';
import EmailLink from 'components/EmailLink';
import { AsyncButton } from 'components/form/AsyncButton';
import AsyncControlledSelect from 'components/form/AsyncControlledSelect';
import ControlledSelect, { IOption } from 'components/form/ControlledSelect';
import Input from 'components/form/Input';
import { useGlobalUserContext, useLoggedInUser } from 'contexts/user-context/UserContext';

interface IRefetchUser {
  refetch(): void;
}

const schema: SchemaOf<IUpdateProfileForm> = object({
  first_name: string()
    .required('Please enter your first name')
    .nullable(),
  last_name: string()
    .required('Please enter your last name')
    .nullable(),
  middle_name: string().optional()
    .nullable(),
  title: string().optional()
    .nullable(),
  credentials: string().optional()
    .nullable(),
  avatar_url: string().optional()
    .nullable(),
  city: string()
    .required('Please enter a city')
    .nullable(),
  prov_state: string().optional()
    .nullable(),
  email: string()
    .required('Please enter your email')
    .email('Please enter a valid email address'),
  lang: string().optional()
    .nullable(),
  country_id: number().optional()
    .nullable(),
  show_email: boolean().default(false),
  jobs: array().of(object({
    name: string().optional(),
    organizationId: number().optional(),
  })),
  roles: array().of(object({
    id: number().optional(),
  })),
  areas: array().of(object({
    id: number().optional(),
  })),
});

export default function EditProfile({ refetch }: IRefetchUser): JSX.Element {
  const { user: loggedUser } = useLoggedInUser();
  const { userStateDispatch } = useGlobalUserContext();

  const formMethods = useForm<INewUserDto>({
    defaultValues: {
      ...loggedUser,
      roles: loggedUser.roles?.length ? loggedUser.roles.map((role) => ({ name: role.name })) : [{}],
      areas: loggedUser.areas?.length ? loggedUser.areas.map((area) => ({ name: area.name })) : [{}],
      jobs: loggedUser.jobs?.length ? loggedUser.jobs.map((job) => ({ name: job.name, organizationName: job.organizationName })) : [{}],
    },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const {
    control, formState, register, handleSubmit,
  } = formMethods;
  const { fields: jobFields, append: appendJob, remove: removeJob } = useFieldArray({ control, name: 'jobs' });
  const { fields: roleFields, append: appendRole, remove: removeRole } = useFieldArray({ control, name: 'roles' });
  const { fields: areaFields, append: appendArea, remove: removeArea } = useFieldArray({ control, name: 'areas' });
  const { t } = useTranslation();

  async function onSubmit(userData: INewUserDto): Promise<void> {
    try {
      const result = await axios.put<IUserDto>('/api/users', userData);
      userStateDispatch({ type: 'update', user: result.data });
      toast.success(t('Successfully updated profile!'));
    } catch (e) {
      toast.error(t('Error updating profile'));
      console.error(e);
    }

    refetch();
    (document.activeElement as HTMLElement).blur();
  }

  const [countries] = useAxios<ICountry[]>('/api/data/countries');
  const [organizations] = useAxios<IOrganization[]>('/api/data/organizations');
  const [professionalRoles] = useAxios<IProfessionalRole[]>('/api/data/roles');
  const [practiceAreas] = useAxios<IPracticeArea[]>('/api/data/areas');

  const countryOptions = useMemo(() => {
    if (!countries.data) return [];

    return countries.data.map((country) => ({ value: country.id, label: t(country.name) }));
  }, [countries.data, t]);

  const organizationOptions = useMemo(() => {
    if (!organizations.data) return [];

    return organizations.data.map((organization) => ({ value: organization.title, label: organization.title }));
  }, [organizations.data]);

  const professionalRolesOptions = useMemo(() => {
    if (!professionalRoles.data) return [];

    return professionalRoles.data.map((role) => ({ value: role.name, label: role.name }));
  }, [professionalRoles.data]);

  const practiceAreaOptions = useMemo(() => {
    if (!practiceAreas.data) return [];

    return practiceAreas.data.map((area) => ({ value: area.name, label: area.name }));
  }, [practiceAreas.data]);

  const languageOptions = [
    { value: 'en-CA', label: t('English') },
    { value: 'fr-CA', label: t('French') },
  ];

  function handleEnterKey(e: React.KeyboardEvent<HTMLFormElement>): void {
    if (e.key === 'Enter') {
      const form = document.activeElement;
      document.getElementById('first_name')?.focus();
      (form as HTMLElement).focus();
      handleSubmit(onSubmit);
    }
  }

  return (
    <FormProvider {...formMethods}>
      <EditProfileForm onSubmit={handleSubmit(onSubmit)} onKeyDown={(e: React.KeyboardEvent<HTMLFormElement>) => handleEnterKey(e)}>
        <div className='user-avatar'>
          <UploadAvatar />
        </div>
        <section className='top'>
          <Input type="text" label={t('First name')} {...register('first_name')} />
          <Input type="text" label={t('Last name')} {...register('last_name')} />
          <Input type="text" label={t('Middle initial')} {...register('middle_name')} />
          <div>
            <label>{t('Title')}</label>
            <ControlledSelect control={control} name='title' options={titleOptions.titleOptions} />
          </div>
          <Input type="text" label={t('Credentials')} {...register('credentials')} />
          <div />
          <Input type="text" label={t('City')} {...register('city')} />
          <Input type="text" label={t('Province/State')} {...register('prov_state')} />
          <div>
            <label>{t('Country')}</label>
            <ControlledSelect control={control} name='country_id' options={countryOptions} />
          </div>
        </section>

        <h3>{t('Affiliations')}</h3>
        <section className='top mid'>

          <div> {/* Job Forms */}
            {jobFields.map((field, index) => (
              <div id={index.toString()} className='job-form' key={field.id}>
                <div className='job-title'>
                  <Input type="text" label={t('Job title and/or department')} {...register(`jobs.${index}.name`)} />
                </div>
                <div className='job-org'>
                  <label>{t('Organization')}</label>
                  <ControlledSelect control={control} options={organizationOptions} name={`jobs.${index}.organizationName`} />
                  <When condition={index <= 0}><div className='space' /></When>
                  <When condition={index > 0}>
                    <button
                      type='button'
                      className='remove-job'
                      onClick={() => {
                        removeJob(index);
                      }}
                    >
                      {t('Remove')}
                    </button>
                  </When>
                </div>
              </div>
            ))}
            <button type='button' className='add-affiliation' onClick={() => appendJob({})}>
              {t('Add new affiliation')}
            </button>
            <p className='info'>
              <Trans>
                If your organization isn&apos;t listed, please email <EmailLink>contact@sosido.com</EmailLink>
              </Trans>
            </p>
          </div>

          <div> {/* Role Forms */}
            {roleFields.map((field, index) => (
              <div id={index.toString()} className='role-form' key={field.id}>
                <label>{t('Professional role')}</label>
                <ControlledSelect control={control} options={professionalRolesOptions} name={`roles.${index}.name`} />
                <When condition={index <= 0}><div className='space' /></When>
                <When condition={index > 0}>
                  <button type='button' className='remove-role' onClick={() => removeRole(index)}>
                    {t('Remove')}
                  </button>
                </When>
              </div>
            ))}
            <button type='button' className='add-professional-role' onClick={() => appendRole({})}>
              {t('Add a professional role')}
            </button>
            <ErrorMessage errors={formState.errors} name='roleIds' as="p" />
          </div>

          <div> {/* Area Forms */}
            {areaFields.map((field, index) => (
              <div id={index.toString()} className='area-form' key={field.id}>
                <label>{t('Practice area')}</label>
                <ControlledSelect control={control} options={practiceAreaOptions} name={`areas.${index}.name`} />
                <When condition={index <= 0}><div className='space' /></When>
                <When condition={index > 0}>
                  <button type='button' className='remove-area' onClick={() => removeArea(index)}>
                    {t('Remove')}
                  </button>
                </When>
              </div>
            ))}
            <button type='button' className='add-practice-area' onClick={() => appendArea({})}>
              {t('Add a practice area')}
            </button>
            <ErrorMessage errors={formState.errors} name='areaIds' as="p" />
          </div>
        </section>

        <Link to='/follow' className='change-communities'>
          {`${t('Change my communities')} »`}
        </Link>

        <div className='hl' />

        <section className='bottom'>
          <h3>{t('Language Settings')}</h3>
          <ControlledSelect control={control} name='lang' options={languageOptions} placeholder={t('Choose a language')} color='#000' />
          <AsyncButton resetAfterSubmit className='submit' formState={formState}>{t('Save changes')}</AsyncButton>
        </section>
      </EditProfileForm>
    </FormProvider>
  );
}
