import { DetectorOptions } from 'i18next-browser-languagedetector';

function getDefaults(): DetectorOptions {
  return {
    order: ['user', 'navigator'],
    lookupQuerystring: 'lng',
    lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLng',
    lookupSessionStorage: 'i18nextLng',

    // cache user language
    // caches: ['localStorage'],
    excludeCacheFor: ['cimode'],
    // cookieMinutes: 10,
    // cookieDomain: 'myDomain'
  };
}

export default getDefaults;
