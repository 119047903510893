import useAxios, { ResponseValues } from 'axios-hooks';
import { useTranslation } from 'react-i18next';
import { Else, If, Then } from 'react-if';
import { useParams } from 'react-router-dom';

import ProfileDisplay from './components/ProfileDisplay';
import EditProfile from './EditProfile';
import { IUserDto } from 'api/dtos/user.dto';
import { IUserCommunityDto } from 'api/dtos/userCommunity.dto';
import { AxiosWrapper } from 'components/AxiosWrapper';
import TabNavigator from 'components/TabNavigator';
import { useLoggedInUser } from 'contexts/user-context/UserContext';
import EmailAndPassword from 'profile/EmailAndPassword';

interface IProfile {
  user: ResponseValues<IUserDto, any, any>;
  communities: ResponseValues<IUserCommunityDto[], any, any>;
}
function Profile({ user, communities }: IProfile): JSX.Element {
  return (
    <AxiosWrapper requests={[user, communities]}>
      <ProfileDisplay user={user.data as IUserDto} communities={communities.data as IUserCommunityDto[]} />
    </AxiosWrapper>
  );
}

function ProfilePage(): JSX.Element {
  const { t } = useTranslation();
  const { user: loggedUser } = useLoggedInUser();
  const { userId: userIdParam } = useParams<{ userId: string }>();
  const [user, refetch] = useAxios<IUserDto>(`/api/users/${userIdParam}`, { useCache: false });
  const [communities] = useAxios<IUserCommunityDto[]>(`/api/communities/user/${userIdParam}`);
  const tabs = [
    {
      label: t('My profile'),
      component: <Profile user={user} communities={communities} />,
    },
    {
      label: t('Edit profile'),
      component: <EditProfile refetch={refetch} />,
    },
    {
      label: t('Email & password'),
      component: <EmailAndPassword refetch={refetch} />,
    },
  ];

  return (
    <If condition={loggedUser.id === parseInt(userIdParam)}>
      <Then>
        <TabNavigator tabs={tabs} />
      </Then>
      <Else>
        <Profile user={user} communities={communities} />
      </Else>
    </If>
  );
}

export default ProfilePage;
