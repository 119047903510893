/* eslint-disable react/prop-types */ // eslint might be outdated?
/* eslint-disable @typescript-eslint/indent */
import { Element, HTMLReactParserOptions, attributesToProps } from 'html-react-parser';
import { Props } from 'html-react-parser/lib/attributes-to-props';
import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

import fullscreen from '../assets/icons/fullscreen.svg';
import x from '../assets/icons/x.svg';

interface IExpandableImage {
  props: Props;
}

export const addExpandableImages: HTMLReactParserOptions = {
  replace: (domNode) => {
    if (domNode instanceof Element && domNode.name === 'img') {
      const props = attributesToProps(domNode.attribs);
      return <ExpandableImage props={props} />;
    }
  },
};

export default function ExpandableImage({ props }: IExpandableImage): JSX.Element {
  const [isFullscreen, setFullscreen] = useState(false);
  const [isMobile, setMobile] = useState(false);
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange(): void {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    setMobile(width <= 600);
    return () => window.removeEventListener('resize', handleWindowSizeChange);
  }, [width]);

  return (
    <>
      <Container onClick={() => setFullscreen(true)}>
        <img {...props} />
        <img src={fullscreen} />
      </Container>
      <Modal isOpen={isFullscreen} style={imgModal} onRequestClose={() => setFullscreen(false)} ariaHideApp={false}>
        <img
          style={{
    cursor: 'pointer', marginLeft: '99%', position: 'relative', bottom: '6px',
}}
          src={x}
          onClick={() => setFullscreen(false)}
        />
        <div>
          <img style={{ maxWidth: isMobile ? '88vw' : '70vw', maxHeight: '80vh', alignSelf: 'center' }} src={props.src} />
        </div>
      </Modal>
    </>
  );
}

const Container = styled.div`
  display: inline-flex;

  img:first-child {
    margin-top: 20px;
    margin-bottom: 20px;
    max-height: 350px;
    max-width: 100%;
    cursor: pointer;
  }

  img:last-child {
    width: 20px;
    height: 20px;
    margin: 0;
    position: relative;
    top: 28px;
    right: 28px;
    cursor: pointer;
    border-radius: 3px;
    background-color: white;
    opacity: 0.5;
    object-fit: contain;
  }

  :hover {
    img:last-child {
      opacity: 0.9;
    }
  }
`;

const imgModal = {
    content: {
      width: 'auto',
      height: 'auto',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      border: 'none',
      borderRadius: '10px',
      boxShadow: '0 14px 54px rgb(112 112 112 / 35%)',
    },
};
