import useAxios from 'axios-hooks';
import { ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AxiosWrapper } from '../components/AxiosWrapper';
import CommunityCard from './components/CommunityCard';
import { formatCommunityList } from './utils/FormatCommunityList';
import { ICommunity } from 'api/tables';
import { useLoggedInUser } from 'contexts/user-context/UserContext';
import { device } from 'styles/theme';
import { getMarketingUrl } from 'utils';

export default function FollowCommunitiesPage(): JSX.Element {
  const { t } = useTranslation();
  const { communities: userCommunities } = useLoggedInUser();
  const [publicCommunities] = useAxios<ICommunity[]>('/api/communities/public');

  function allCommunitiesList(): ReactNode {
    if (!publicCommunities.data) return [];
    const orderedCommunities = publicCommunities.data.sort((a, b) => a.name.localeCompare(b.name));
    return orderedCommunities.map((community) => <CommunityCard key={community.id} community={community} />);
  }

  const memberPubsFaq = `${getMarketingUrl()}/faq?questionId=cc2be0a8-5d3a-4a9d-b297-987d9341dcf2&appDefId=14c92d28-031e-7910-c9a8-a670011e062d`;
  const memberAndFollowersFaq = `${getMarketingUrl()}/faq?questionId=b67e15a0-a5b8-4ca1-9565-b64add53d8f8&appDefId=14c92d28-031e-7910-c9a8-a670011e062d`;

  return (
    <Container>
      <AxiosWrapper requests={[publicCommunities]}>
        <div className='top'>
          <h3>{t('My Communities')}</h3>
          <div className='current-communities'>
            <p>
              <Trans>You are currently a</Trans> {formatCommunityList(userCommunities)}.&nbsp;
              <Trans>Updates on these communities will be included in your weekly Sosido email digest.</Trans>
            </p>
          </div>
          <div className='member-vs-follower'>
            <Trans>
              <br />
              <p>
                As a <b>member</b>, you have full access to the community on Sosido, including the community&apos;s private Q&A and Resources.
                Publications you author will be tracked and featured for the community.
                <a href={memberPubsFaq} target='_blank' rel='noopener noreferrer'> Read more about how member publications are featured on Sosido here. </a>
                To become a member, please contact the group.
              </p>
              <br />
              <p>
                As a <b>follower</b>, you&apos;ll receive the community&apos;s news and member publications, but you won&apos;t have access
                to the community&apos;s Q&A and Resources. Your own publications won&apos;t be featured for the community.
                <a href={memberAndFollowersFaq} target='_blank' rel='noopener noreferrer'> Read more about the difference between members and followers on Sosido here. </a>
                You can follow and unfollow communities below.
              </p>
            </Trans>
          </div>
        </div>

        <div className='bottom'>
          <div className='community-list'>
            {allCommunitiesList()}
          </div>
        </div>
      </AxiosWrapper>
    </Container>
  );
}

const Container = styled.div`
  margin: 0 0 55px;

  h3 {
    font-size: 24px;
    font-weight: 700;
  }

  .top,
  .bottom {
    & > p {
      color: black;
      margin-bottom: 25px;
      font-size: 18px;
      text-transform: none;
      line-height: 25px;
    }

    & > h3 {
      text-transform: none;
      margin-bottom: 15px;
    }
  }

  .top {
    margin: 0 0 106px;

    .current-communities {
      background: #F9F9F9;
      border-radius: 10px;
      padding: 30px;
      width: 85.3%;
      min-width: 150px;

      p {
        font-family: 'Liberation Sans', Inter, sans-serif;
        font-size: 18px;
        line-height: 25px;
      }
    }

    .member-vs-follower {
      width: 87%;
      padding: 15px;
      padding-left: 22px;

      p {
        font-size: 16px;
        color: #6D6D6D;
        line-height: 25px;
        margin-bottom: 5px;
      }

      a {
        color: #0038FF;
      }
    }
  }

  .bottom {
    margin-top: -55px;
  }

  ${device.mobile} {
    .top {
      margin: 0 0 70px;
    }

    .current-communities,
    .member-vs-follower {
      width: 100% !important;

      p {
        font-size: 18px !important;
      }
    }

    .member-vs-follower {
      padding-left: 10px;
    }

    .top,
    .bottom {
      & > p {
        margin: 0 0 19px;
      }
    }

    .member,
    .sources-follow-button {
      margin-top: 17px;
      width: calc(100vw - 140px);
    }
  }
`;
