import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { When } from 'react-if';
import styled from 'styled-components';

import UserCommunities from './UserCommunities';
import { IUserDto } from 'api/dtos/user.dto';
import { IUserCommunityDto } from 'api/dtos/userCommunity.dto';
import { getDisplayLocation, getDisplayName } from 'api/utils';
import Discussions from 'components/Discussions';
import UserAvatar from 'components/page-template/UserAvatar';
import Publications from 'components/Publications';
import TabNavigator from 'components/TabNavigator';
import { device } from 'styles/theme';

interface IProfileDisplay {
  user: IUserDto;
  communities: IUserCommunityDto[];
}

export default function ProfileDisplay({ user, communities }: IProfileDisplay): JSX.Element {
  const { t } = useTranslation();
  document.getElementById('main')?.scrollTo(0, 0);
  const {
    credentials, email, show_email, jobs, roles, areas, // eslint-disable-line @typescript-eslint/naming-convention
  } = user;

  const getJobNames = useMemo(() => jobs?.map((job) => {
    let jobName = job.name;
    if (!job.name && job.organizationName) jobName = job.organizationName;
    if (job.name && job.organizationName) jobName = `${job.name}, ${job.organizationName}`;
    return <p key={jobName} className='job'>{jobName}</p>;
  }), [jobs]);

  const getRoleNames = useMemo(() => roles?.map((role) => role.name)?.join(' | '), [roles]);
  const getAreaNames = useMemo(() => areas?.map((area) => area.name).join(' | '), [areas]);

  const tabs = [
    {
      label: t('Publications'),
      component: <Publications emptyTabHeader={t('No articles featured on Sosido yet.')} userId={user.id} />,
    },
    {
      label: t('Q&A'),
      component: <Discussions emptyTabHeader={t('No questions or answers on Sosido yet.')} userId={user.id} />,
    },
  ];

  return (
    <Container>
      <div className='profile'>
        <UserAvatar user={user} size={90} className='avatar' showMenu={false} />
        <div className='top'>
          <h3>{getDisplayName(user)}
            <When condition={credentials}>
              <span className='credentials'>{credentials}</span>
            </When>
          </h3>
          <p>{getDisplayLocation(user)}</p>
        </div>
        <When condition={!!getJobNames?.length || !!show_email}>
          <div className='top-middle'>
            {getJobNames}
            <When condition={show_email}>
              <p>{email}</p>
            </When>
          </div>
        </When>
        <When condition={!!getRoleNames && !!getAreaNames}>
          <div className='middle'>
            <When condition={!!getRoleNames}>
              <p><b>{getRoleNames?.includes('|') ? `${t('Professional roles')}: ` : `${t('Professional role')}: `}</b>{getRoleNames}</p>
            </When>
            <When condition={!!getAreaNames}>
              <p><b>{getAreaNames?.includes('|') ? `${t('Practice areas')}: ` : `${t('Practice area')}: `}</b>{getAreaNames}</p>
            </When>
          </div>
        </When>
        <div className='bottom'>
          <UserCommunities communities={communities} />
        </div>
      </div>
      <TabNavigator tabs={tabs} />
    </Container>
  );
}

const Container = styled.div`
  flex-direction: column;
  display: flex;

  .profile {
    line-height: 25px;
    margin-bottom: 78px;
    padding: 35px 0;
    padding-right: 55px;
    min-width: 777px;
    width: 100%;
    display: grid;
    grid-template-columns: 130px auto;
    grid-gap: 20px 35px;
    box-shadow: 0 3px 15px #E6E6E6;
    border-radius: 12px;

    p {
      margin-bottom: 10px;
      font-size: 16px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .credentials {
      font-size: 16px;
      font-weight: 400;
      line-height: 18px;
      margin-left: 10px;
    }

    & > .avatar {
      grid-row: 1 / 3;
      justify-self: end;
      margin-top: 18px;
    }

    .top,
    .top-middle,
    .middle,
    .bottom {
      grid-column: 2/ 3;
    }

    .top {
      display: flex;
      flex-direction: column;

      h3 {
        margin-top: 52px;
        margin-bottom: 8px;
        font-weight: 700;
        font-size: 24px;
      }
    }

    .top-middle {
      margin-top: 30px;

      h3 {
        margin-top: 52px;
        margin-bottom: 8px;
        font-weight: 700;
        font-size: 26px;
      }

      p {
        white-space: pre-wrap;
      }
    }

    .middle {
      flex-direction: column;
      display: flex;
    }

    .bottom {
      margin-bottom: 5px;

      & > div {
        margin-bottom: 15px;
      }
    }
  }

  div.hl {
    margin-top: 6px;
    margin-bottom: 12px;
    border: 2.5px solid #F0F6FF;
  }

  ${device.tablet} {
    .profile {
      margin-bottom: 130px;
      padding-left: 16px;
      grid-gap: 20px 10px;
      width: 100.5%;
      min-width: 100px;
      grid-template-columns: 100px auto;

      .top-middle,
      .middle,
      .bottom {
        grid-column: 1 / 3;
      }

      & > .avatar {
        margin-top: 23px;
        grid-row: initial;
        justify-self: start;
      }
    }
  }

  ${device.mobile} {
    .profile {
      overflow-x: hidden;
      box-sizing: border-box;
      position: relative;
      width: 102%;
      min-width: 100px;
      padding-right: 0;
      padding-top: 15px;
      padding-bottom: 15px;

      h3 {
        width: 100%;
      }

      .credentials::before {
        content: '';
        width: 100%;
      }

      .credentials {
        display: block;
        margin-left: 0;
        margin-top: 10px;
        white-space: pre-line;
      }

      & > .avatar {
        font-size: 20px;
      }

      .top {
        margin-bottom: 10px;

        p {
          width: 90%;
        }
      }

      .top-middle {
        margin: 0;
        margin-top: 10px;

        .job {
          line-height: 1.3;
        }
      }

      .middle {
        flex-direction: column;
        display: flex;
      }

      .bottom {
        text-align: justify;
      }
    }

    div.hl {
      width: 145%;
    }
  }
`;
