import 'react-toggle/style.css';
import useAxios from 'axios-hooks';
import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Toggle from 'react-toggle';
import styled from 'styled-components';

import { FeedTypes, IFeed } from 'api/tables';
import { AxiosWrapper } from 'components/AxiosWrapper';
import { useFeedsPageContext } from 'feeds/FeedsPageContext';
import Card from 'styles/styled-components/Card';
import FeedBadgeSquare from 'styles/styled-components/FeedBadgeSquare';
import theme from 'styles/theme';

function checkSubscription(feed: IFeed, userFeeds: IFeed[]): boolean {
  return userFeeds.some((userFeed) => userFeed.id === feed.id);
}

export default function FeedToggle({ feed, className }: { feed: IFeed, className?: string }): JSX.Element {
  const { t } = useTranslation();
  const { userFeeds, setUserFeeds } = useFeedsPageContext();
  const [isSubscribed, setIsSubscribed] = useState<boolean>(checkSubscription(feed, userFeeds));
  const [feedToggleResponse, sendToggleRequest] = useAxios<IFeed[]>(`/api/feeds/${feed.id}`, { manual: true });

  useEffect(() => {
    setIsSubscribed(checkSubscription(feed, userFeeds));
  }, [feed, userFeeds]);

  const squareColor = `#${feed.colour || 'black'}`;
  const isDisabled = useMemo(() => feedToggleResponse.loading || feed.type !== FeedTypes.JOURNAL, [feed.type, feedToggleResponse.loading]);

  async function handleChange(): Promise<void> {
    try {
      if (isDisabled) return;
      const newUserFeeds = await (isSubscribed ? sendToggleRequest({ method: 'DELETE' }) : sendToggleRequest({ method: 'POST' }));
      setUserFeeds(newUserFeeds.data);
    } catch (error) {
      console.error(error);
      toast.error(t('Failed to follow journal'));
    }
  }

  return (
    <AxiosWrapper requests={[feedToggleResponse]}>
      <Container className={className}>
        <FeedBadgeSquare color={squareColor} />
        <span>{feed.title}</span>
        {/* can't use disabled prop https://github.com/aaronshaf/react-toggle/issues/164 */}
        <Toggle className={classNames({ 'react-toggle--disabled': isDisabled })} checked={isSubscribed} icons={false} onChange={handleChange} />
      </Container>
    </AxiosWrapper>
  );
}

const Container = styled(Card)`
  display: flex;
  align-items: center;
  padding: 30px 25px;

  input {
    width: auto;
    height: auto;
  }

  span {
    width: 70%;
    font-size: 14px;
    font-weight: 400;
  }

  .react-toggle {
    margin-left: auto;

    .react-toggle-track {
      background-color: #DEE8F5;
    }

    .react-toggle-thumb {
      border: none;
    }

    /* stylelint-disable-next-line selector-class-pattern */
    &.react-toggle--checked .react-toggle-track {
      background-color: ${theme.light} !important;
    }
    /* stylelint-disable-next-line selector-class-pattern */
    &.react-toggle--disabled:hover {
      cursor: wait;
    }
    /* stylelint-disable-next-line selector-class-pattern */
    &:hover:not(.react-toggle--disabled) .react-toggle-track {
      background-color: #DEE8F5;
    }
  }
`;
