/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { ReactNode, useMemo } from 'react';
import styled from 'styled-components';

import PaginatedScroll from './PaginatedScroll';
import { IArticleDto } from 'api/dtos/article.dto';
import ArticleCard from 'articles/ArticleCard';

interface ICommunityPublications {
  communityId: number;
  userId?: number;
  path?: string;
}

interface IUserPublications {
  userId: number;
  communityId?: number;
  path?: string;
}

type IPublications = (ICommunityPublications | IUserPublications) & {
  emptyTabHeader: string;
  emptyTabMessage?: string | JSX.Element
};

export default function Publications({
  userId, communityId, path, emptyTabHeader, emptyTabMessage,
}: IPublications): JSX.Element {
  function publications(articles: IArticleDto[]): ReactNode {
    return articles.map((article) => <ArticleCard key={article.id} article={article} />);
  }

  const url = useMemo(() => {
    if (communityId) {
      return path
        ? `/api/communities/${communityId}/${path}` : `/api/articles/community/${communityId}?single=true`;
    }
    return `/api/articles/user/${userId}`;
  }, [communityId, path, userId]);

  return (
    <Container>
      <PaginatedScroll<IArticleDto>
        url={url}
        emptyTabHeader={emptyTabHeader}
        emptyTabMessage={emptyTabMessage}
      >
        {publications}
      </PaginatedScroll>
    </Container>
  );
}

const Container = styled.div`
  & > div {
    margin-bottom: 60px;
  }
`;
