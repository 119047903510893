import { InitOptions, LanguageDetectorAsyncModule, Services } from 'i18next';
import { CustomDetector, DetectorOptions } from 'i18next-browser-languagedetector';
import { defaults } from 'lodash';

import getDefaults from './getDefaults';
import navigatorDetector from './lookups/navigatorDetector';
import userDetector from './lookups/userDetector';

export interface IAsyncCustomDetector extends CustomDetector {
  lookupAsync(options: DetectorOptions): Promise<string | string[] | undefined>;
}

interface IAsyncBrowserLanguageDetector extends LanguageDetectorAsyncModule {
  addDetector(detector: IAsyncCustomDetector): void;
  type: 'languageDetector';
  detectors: { [index: string]: IAsyncCustomDetector | undefined };
  options: DetectorOptions;
  i18nOptions?: InitOptions;
  services?: Services;
}

// based on https://github.com/i18next/i18next-browser-languageDetector/blob/master/index.js
const asyncBrowserLanguageDetector: IAsyncBrowserLanguageDetector = {
  detectors: {},
  options: getDefaults(),
  type: 'languageDetector',
  async: true,
  init(services, options: DetectorOptions, i18nOptions = {}) {
    this.services = services;
    this.options = defaults(options, this.options);
    this.i18nOptions = i18nOptions;

    this.addDetector(userDetector);
    this.addDetector(navigatorDetector);
  },
  detect(callback) {
    let detected: string[] = [];
    const promises: Promise<string | string[] | undefined>[] = [];
    this.options.order?.forEach((detectorName: string) => {
      const detector = this.detectors[detectorName];
      if (detector) {
        promises.push(detector.lookupAsync(this.options));
      }
    });
    Promise.all(promises)
      .then((lookups) => {
        lookups.forEach((lookup) => {
          if (lookup && typeof lookup === 'string') {
            lookup = [lookup];
          }
          if (lookup) {
            detected = detected.concat(lookup);
          }
        });

        if (this.services?.languageUtils.getBestMatchFromCodes) {
          callback(detected);
          return;
        } // new i18next v19.5.0
        callback(detected.length > 0 ? detected[0] : undefined); // a little backward compatibility
      }, (reject) => {
        console.error(reject);
      });
  },
  addDetector(detector) {
    this.detectors[detector.name] = detector;
  },
  cacheUserLanguage(lng) {
    const { caches } = this.options;
    if (!caches) return;
    if (this.options.excludeCacheFor && this.options.excludeCacheFor.includes(lng)) return;

    caches.forEach((cacheName) => {
      const detector = this.detectors[cacheName];
      if (!detector || !detector.cacheUserLanguage) return;
      detector.cacheUserLanguage(lng, this.options);
    });
  },
};

export default asyncBrowserLanguageDetector;
