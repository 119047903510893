import useAxios from 'axios-hooks';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import FeedToggleList from './FeedToggleList';
import { IFeed } from 'api/tables';
import { AxiosWrapper } from 'components/AxiosWrapper';
import theme, { device } from 'styles/theme';

interface IFeedDictionary {
  className?: string;
}

interface ILetterGroup {
  letter: string;
  feeds: IFeed[] | undefined;
}

export default function FeedDictionary({ className }: IFeedDictionary): JSX.Element {
  const { t } = useTranslation();
  const [journalFeeds] = useAxios<IFeed[]>('/api/feeds/journals');

  const alphabet = Array.from(Array(26))
    .map((e, i) => i + 65)
    .map((code) => String.fromCharCode(code));

  const letterGroups: ILetterGroup[] = useMemo(() => {
    if (!journalFeeds.data) return [];

    return alphabet.map((letter) => {
      const feeds = journalFeeds.data?.filter((feed) => feed.title.startsWith(letter));
      return { letter, feeds };
    })
      .filter((group) => {
        if (group.feeds) return group.feeds.length > 0;
      });
  }, [alphabet, journalFeeds.data]);

  const letterGroupsElements = useMemo(() => letterGroups
    .map((group, index) => (
      <div className="letter-group" key={index}>
        <div className='top'>
          <h2 id={group.letter}>{group.letter}</h2>
          <a href="#top">{t('Go to Top')}</a>
        </div>
        <FeedToggleList feeds={group.feeds as IFeed[]} />
      </div>
    )), [letterGroups, t]);

  const buttons = useMemo(() => letterGroups.map((group, index) => <a href={`#${group.letter}`} key={index}>{group.letter}</a>), [letterGroups]);

  return (
    <Container className={className}>
      <h3 id="top">{t('All Journals')}</h3>
      <AxiosWrapper requests={[journalFeeds]}>
        <>
          <div className='alphabet-interface'>
            {buttons}
          </div>
          {letterGroupsElements}
        </>
      </AxiosWrapper>
    </Container>
  );
}

const Container = styled.div`
  h2 {
    margin-bottom: 24px;
    font-size: 32px;
  }

  h3 {
    color: ${theme.gray};
    font-size: 24px;
  }

  .alphabet-interface {
    display: flex;
    justify-content: space-between;
    margin: 24px 0 50px;

    a {
      background: transparent;
      color: ${theme.primary};
      padding: 0;
      font-size: 24px;
      font-weight: 500;
    }
  }

  .letter-group .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  ${device.mobile} {
    .alphabet-interface {
      justify-content: flex-start;
      gap: 10px;
      flex-wrap: wrap;
      margin: 19px 0 20px;
    }
  }
`;
