import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import {
  SchemaOf, boolean, object, string,
} from 'yup';

import UpdateEmailModal from './components/UpdateEmailModal';
import UpdatePasswordModal from './components/UpdatePasswordModal';
import { IUserDto } from 'api/dtos/user.dto';
import { IUser } from 'api/tables';
import Input from 'components/form/Input';
import { useGlobalUserContext, useLoggedInUser } from 'contexts/user-context/UserContext';
import theme, { device } from 'styles/theme';

interface IRefetchUser {
  refetch(): void;
}

type IUpdateProfileForm =
  Pick<IUserDto,
  'show_email'
  | 'email'
  >;

const schema: SchemaOf<IUpdateProfileForm> = object({
  show_email: boolean().default(false),
  email: string().email()
    .required(),
});

export default function EmailAndPassword({ refetch }: IRefetchUser): JSX.Element {
  const { user: loggedUser } = useLoggedInUser();
  const { userState, userStateDispatch } = useGlobalUserContext();
  const formMethods = useForm<IUpdateProfileForm>({
    defaultValues: { ...userState.user },
    resolver: yupResolver(schema),
  });
  const { register, handleSubmit } = formMethods;
  const { t } = useTranslation();
  const [isChangingEmail, setIsChangingEmail] = useState(false);
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  let i = 1;

  function openEmailForm(): void {
    setIsChangingEmail(true);
  }

  function openPasswordForm(): void {
    setIsChangingPassword(true);
  }

  function closeModal(): void {
    setIsChangingEmail(false);
    setIsChangingPassword(false);
  }

  async function onSubmit(userData: IUpdateProfileForm): Promise<void> {
    try {
      const result = await axios.put<IUser>(
        '/api/users',
        { show_email: !userData.show_email },
      );
      userStateDispatch({ type: 'update', user: result.data });

      i += 1;
      if (result.data.show_email === true) {
        if (i % 2 === 0) {
          toast.success(t('Email is now displayed.'));
        }
      } else if (result.data.show_email === false) {
        if (i % 2 === 0) {
          toast.success(t('Email is now hidden.'));
        }
      }
    } catch (e) {
      toast.error(t('Error updating profile'));
      console.error(e);
    }

    refetch();
  }

  return (
    <>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...formMethods}>
          <div className='current-email'>
            <Input disabled placeholder={loggedUser.email} className='current-email' label="Email" type="text" {...register('email')} />
            <div className='checkbox'>
              <label onClick={() => setTimeout(handleSubmit(onSubmit), 10)}> {/* eslint-disable-line @typescript-eslint/no-misused-promises */}
                <Input type="checkbox" {...register('show_email')} />&nbsp;{t('Show email on profile')}
              </label>
              <button type='button' className='side-button change-email' onClick={openEmailForm}>{t('Change')}</button>
            </div>
          </div>

          <div className='current-password'>
            <Input disabled className='current-password' label="Password" type="password" {...register('email')} />
            <button type='button' className='side-button change-password' onClick={openPasswordForm}>{t('Change')}</button>
            <Link to='/password-reset' className='forgot-password'>{t('Forgot password')}</Link>
          </div>

        </FormProvider>
      </StyledForm>

      <UpdateEmailModal isOpen={isChangingEmail} onRequestClose={closeModal} closeModal={closeModal} />
      <UpdatePasswordModal isOpen={isChangingPassword} onRequestClose={closeModal} closeModal={closeModal} />
    </>
  );
}

const StyledForm = styled.form`
  display: grid;
  grid-template-columns: 410px 410px;
  gap: 18px 42px;
  margin-bottom: 50px;

  .current-password,
  .current-email,
  .new-password,
  .new-email,
  button,
  a {
    grid-column: 1 / 2;
  }

  .current-email {
    grid-row: 1;
    display: grid;

    button.change-email {
      position: relative;
      padding: 0;
      bottom: 50px;
      left: 66.9%;
    }

    ${device.tablet} {
      button.change-email {
        left: 6.1%;
      }
    }
  }

  .submit {
    padding: 0;
    margin: 0;
    width: 0;
    height: 0;
    position: relative;
    right: 150px;
    z-index: -1;
  }

  .side-button {
    margin: 0;
    padding: 0;
    font-size: 14px;
    background: #FFF;
    color: #000;
    text-decoration: underline;
    cursor: pointer;
    width: 0;
  }

  .current-password {
    grid-row: 2;
    margin-bottom: -3px;

    button.change-password {
      position: relative;
      bottom: 32px;
      left: 105%;
    }
  }

  .new-password,
  .new-email {
    grid-row: 2 / 3;
  }

  .confirm-password,
  .confirm-email {
    grid-column: 2 / 2;
    grid-row: 2 / 3;
  }

  .forgot-password {
    position: relative;
    font-size: 14px;
    top: 15px;
    text-decoration: underline;
  }

  label {
    font-size: 18px;
    font-weight: 400;
    color: #000;
    margin-bottom: 10px;
  }

  button {
    width: 209px;
    margin: 31px 0 30px;
  }

  a {
    font-size: 14px;
    width: fit-content;
  }

  .checkbox {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 10px 0 25px 1px;

    label {
      display: inline-flex;
      margin: 0 0 10px;
      color: ${theme.text};
      font-weight: 400;
      font-size: 14px;
      text-transform: none;
      cursor: pointer;

      input {
        width: 18px;
        height: 18px;
        cursor: pointer;
      }
    }

    ${device.tablet} {
      label {
        width: 100%;
      }
    }

    ${device.mobile} {
      label {
        width: 100%;
      }
    }
  }

  ${device.tablet} {
    grid-template-columns: 365px 365px;
  }

  ${device.mobile} {
    display: flex;
    flex-direction: column;
    align-items: center;

    .current-password,
    .current-email,
    .new-password,
    .confirm-password,
    button {
      width: 100%;
    }

    .current-password,
    .new-password {
      margin-bottom: 18px;
    }

    button {
      margin: 49px 0 48px;
    }

    a {
      margin-bottom: 110px;
    }

    .forgot-password {
      position: relative;
      top: 20px;
      left: 0;
    }

    .checkbox {
      padding-top: 30px;
    }

    .current-email {
      position: relative;

      button.change-email {
        position: absolute;
        bottom: 65px;
        left: 0;
      }
    }

    .current-password {
      position: relative;

      button.change-password {
        position: absolute;
        top: 90px;
        left: 0;
        bottom: 0;
      }
    }
  }
`;
