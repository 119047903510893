import styled from 'styled-components';

import Resources from './Resources';
import { ListContainer } from 'components/Discussions';
import TabNavigator from 'components/TabNavigator';

export default function ResourcesPage(): JSX.Element {
  return (
    <Container>
      <TabNavigator className='tab-navigator' tabs={[]} />
      <Resources />
    </Container>
  );
}

const Container = styled(ListContainer)`
  width: 100%;

  .tab-navigator {
    margin-bottom: 0;
  }
`;
