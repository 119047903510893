import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { When } from 'react-if';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import PaginatedScroll from './PaginatedScroll';
import { IDiscussionDto } from 'api/dtos/discussion.dto';
import { useLoggedInUser } from 'contexts/user-context/UserContext';
import { DiscussionListItem } from 'discussions/components/DiscussionListItem';
import { ActionButton } from 'styles/styled-components/button';

interface ICommunityDiscussions {
  userId?: never;
  communityId: number;
}

interface IUserDiscussions {
  userId: number;
  communityId?: never;
}

interface IAllDiscussions {
  userId?: never;
  communityId?: never;
}

type IDiscussions = (ICommunityDiscussions | IUserDiscussions | IAllDiscussions) & {
  emptyTabHeader: string,
  emptyTabMessage?: JSX.Element
};

export default function Discussions({
  userId, communityId, emptyTabHeader, emptyTabMessage,
}: IDiscussions): JSX.Element {
  const { user } = useLoggedInUser();
  const url = useMemo<string>(() => {
    const prefix = '/api/discussions';

    if (communityId) return `${prefix}/communities/${communityId}`;
    if (userId) return `${prefix}/users/${userId}`;
    return `${prefix}/all`;
  }, [communityId, userId]);

  const { t } = useTranslation();
  const history = useHistory();

  function discussionList(discussions: IDiscussionDto[]): ReactNode {
    return discussions.map((discussion) => <DiscussionListItem key={discussion.id} discussion={discussion} />);
  }

  return (
    <>
      <ListContainer>
        <PaginatedScroll<IDiscussionDto>
          url={url}
          emptyTabHeader={emptyTabHeader}
          emptyTabMessage={emptyTabMessage}
        >
          {(data) => discussionList(data as IDiscussionDto[])}
        </PaginatedScroll>
      </ListContainer>
      <When condition={user.unsubscribed_at === null}>
        <ActionButton onClick={() => history.push('/discussions/new')}>{t('Ask a new question')}</ActionButton>
      </When>
    </>
  );
}

export const ListContainer = styled.div`
  & > div {
    margin-bottom: 60px;
  }

  h4 {
    text-align: center;
  }
`;
