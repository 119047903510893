import axios from 'axios';
import {
  ReactNode, useEffect, useMemo, useState,
} from 'react';
import { Else, If, Then } from 'react-if';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled from 'styled-components';
import useAsyncEffect from 'use-async-effect';

import Spinner from './spinner/Spinner';
import { IPagedResultDto } from 'api/dtos/pagination.dto';

export interface IPaginated<T> {
  url: string;
  children(data: T[]): ReactNode;
  onLoad?(data: IPagedResultDto<T>): void;
  queryParams?: Record<string, string>;
  emptyTabHeader: string;
  emptyTabMessage?: string | JSX.Element;
}

export default function PaginatedScroll<T>({
  children, onLoad, url, queryParams, emptyTabHeader, emptyTabMessage,
}: IPaginated<T>): JSX.Element {
  const [data, setData] = useState<ReactNode[]>([]);
  const [page, setPage] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [scroll, setScroll] = useState<number>(0);
  const scrollY = url.includes('discussions') ? Number(sessionStorage.getItem('discussionsScrollY')) : 0;

  useEffect(() => {
    window.addEventListener('beforeunload', () => sessionStorage.setItem('discussionsScrollY', String(0)));
    return () => window.removeEventListener('beforeunload', () => sessionStorage.setItem('discussionsScrollY', String(0)));
  }, []);

  useEffect(() => {
    setPage(0);
    setTotal(0);
    setData([]);
  }, [url, queryParams]);
  const isNewSearch = useMemo(() => data.length === 0, [data.length]);

  useAsyncEffect(async () => {
    if (!isNewSearch && page === 0) return;
    await fetchData();
  }, [isNewSearch, page]);

  async function fetchData(): Promise<void> {
    const response = await axios.get<IPagedResultDto<T>>(url, {
      params: {
        take: 10,
        skip: 10 * page,
        ...queryParams,
      },
    });

    const array: ReactNode[] = [];
    setData(isNewSearch ? array.concat(children(response.data.data)) : data.concat(children(response.data.data)));
    setTotal(response.data.total);
    if (onLoad) onLoad(response.data);
    if (response && (response.data.total === total)) setLoading(false);
  }

  function onScroll(e: MouseEvent): void {
    if ((e.target as Element).scrollTop > scroll) {
      window.scrollTo({ top: 100, behavior: 'smooth' });
    } else if ((e.target as Element).scrollTop < scroll) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    setScroll((e.target as Element).scrollTop);
  }

  function saveScrollPosition(): void {
    const main = document.getElementById('main');
    if (url.includes('discussions/all')) {
      sessionStorage.setItem('discussionsScrollY', String(main?.scrollTop));
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <Container onClick={saveScrollPosition}>
      <If condition={total > 0}>
        <Then>
          <InfiniteScroll
            dataLength={data.length}
            initialScrollY={scrollY}
            next={() => setPage(page + 1)}
            hasMore={(page + 1) * 10 < total}
            loader={<Spinner />}
            onScroll={(e) => onScroll(e)}
            scrollableTarget='main'
            style={{ overflow: 'visible' }}
          >
            {data}
          </InfiniteScroll>
        </Then>
        <Else>
          <If condition={loading}>
            <Then>
              <Spinner />
            </Then>
            <Else>
              <div className='not-found'>
                <h4>{emptyTabHeader}</h4>
                <div>{emptyTabMessage}</div>
              </div>
            </Else>
          </If>
        </Else>
      </If>
    </Container>
  );
}

const Container = styled.div`
  .end-message {
    text-align: center;

    h3 {
      font-family: Lora, serif;
      font-size: 23px;
      line-height: 30px;
      color: #000;
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }

  .not-found {
    margin: 0 auto;
    max-width: 350px;
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 12vh;

    img {
      width: 200px;
    }

    h4 {
      text-transform: none;
      margin: 25px 0 10px;
    }
  }
`;
