import { useTranslation } from 'react-i18next';

import ArticleList from './page-template/ArticleList';

export default function News(): JSX.Element {
  const { t } = useTranslation();

  return (
    <ArticleList
      url='/api/communities/news'
      emptyTabHeader={t('There are no news items from your communities.')}
      emptyTabMessage={t('When your communities post a news item, it will be shown here and in your weekly email digest.')}
    />
  );
}
