import { createContext, useContext } from 'react';

import { IFeed } from 'api/tables';

interface IFeedsPageContext {
  userFeeds: IFeed[];
  setUserFeeds(this: void, feeds: IFeed[]): void;
}

export const FeedsPageContext = createContext<IFeedsPageContext>({
  userFeeds: [],
  setUserFeeds: () => undefined,
});

export function useFeedsPageContext(): IFeedsPageContext {
  return useContext(FeedsPageContext);
}
