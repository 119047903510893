import { useTranslation } from 'react-i18next';
import { When } from 'react-if';
import styled from 'styled-components';

import { IUserCommunityDto } from 'api/dtos/userCommunity.dto';

interface IUserCommunities {
  communities: IUserCommunityDto[];
}

export default function UserCommunities({ communities }: IUserCommunities): JSX.Element {
  const { t } = useTranslation();

  const memberCommunities = communities
    .filter((community) => community.user.relationship === 'MEMBER')
    .map((community) => community.name);
  const followerCommunities = communities
    .filter((community) => community.user.relationship === 'FOLLOWER')
    .map((community) => community.name);

  const memberElement = <p><b>{`${t('Member of')}: `}</b>{memberCommunities.join(', ')}</p>;
  const followerElement = <p><b>{`${t('Following')}: `}</b>{followerCommunities.join(', ')}</p>;

  return (
    <Container>
      <When condition={memberCommunities.length > 0}>
        {memberElement}
      </When>
      <When condition={followerCommunities.length > 0}>
        {followerElement}
      </When>
    </Container>
  );
}

const Container = styled.div`
  p {
    text-align: left;
    font-size: 16px;
    margin-bottom: 10px;
  }
`;
