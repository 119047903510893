import { css } from 'styled-components';

import theme from 'styles/theme';

const input = css`
  background-color: white;
  border: 1px solid ${theme.grayLight};
  width: 100%;
  height: 54px;
  font-size: 16px;
  display: block;
  padding: 9px 0 9px 14px;
  line-height: 40px;

  &:focus {
    outline: none;
  }

  &.invalid {
    background-color: #FFEBEB;
    border-color: #FF7272;
  }
`;

const label = css`
  font-size: 14px;
  margin-bottom: 5px;
  display: block;
  text-transform: capitalize;
  color: ${theme.gray};
  font-weight: 700;

  &.required::after {
    content: ' *';
  }
`;

export { input, label };
