import useAxios from 'axios-hooks';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import FeedToggleList from './FeedToggleList';
import { ICommunity, IFeed } from 'api/tables';
import { AxiosWrapper } from 'components/AxiosWrapper';
import theme, { device } from 'styles/theme';

interface ICommunityFeeds {
  community: ICommunity;
}

export default function CommunityFeeds({ community }: ICommunityFeeds): JSX.Element {
  const { t } = useTranslation();
  const [communityFeed] = useAxios<IFeed[]>(`/api/feeds/community/${community.id}`);

  return (
    <Container>
      <h3>{t('Sources of interest for')} {community.name}</h3>
      <AxiosWrapper requests={[communityFeed]}>
        <FeedToggleList feeds={communityFeed.data as IFeed[]} />
      </AxiosWrapper>
    </Container>
  );
}

const Container = styled.div`
  &:not(:first-child) {
    margin-top: 30px;
  }

  h3 {
    color: ${theme.gray};
    margin-bottom: 35px;
    font-size: 24px;
  }

  ${device.tablet} {
    .feed-list .feed {
      width: 100%;
    }
  }
`;
