interface ICommunityLogo {
  id: number | string;
  logoUrl?: string;
  name?: string;
  className?: string;
}

export default function CommunityLogo({
  id, logoUrl, name, className,
}: ICommunityLogo): JSX.Element {
  const logoName = name || 'community';
  const logoStyles = () => {
    if (id === 51 && className === 'community-page-logo') return { maxWidth: '125px' };
    if (id === 141 && className === 'community-page-logo') return { maxWidth: '120px' };
    if (id === 145 && className === 'community-page-logo') return { maxWidth: '135px' };
    return {};
  };

  return (
    <img src={logoUrl} alt={`${logoName} logo`} className={className} style={logoStyles()} />
  );
}
