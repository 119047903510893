import { t } from 'i18next';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import MagnifyingGlass from 'assets/icons/magnifying-glass.svg';
import theme, { device } from 'styles/theme';

interface ISearch {
  search: string;
}

function SearchBar({ className }: { className?: string }): JSX.Element {
  const { register, handleSubmit } = useForm<ISearch>();
  const history = useHistory();
  const path = history.location.pathname;

  function onSubmit({ search }: ISearch): void {
    history.replace('/articles');
    history.push(`/articles/search?q=${encodeURIComponent(search)}`);
  }

  return (
    <Container inSearchPage={path.endsWith('/search')} className={className} onSubmit={handleSubmit(onSubmit)}>
      <img src={MagnifyingGlass} />
      <input placeholder={`${t('Search')}...`} {...register('search')} />
    </Container>
  );
}

export default SearchBar;

const Container = styled.form<{ inSearchPage: boolean }>`
  display: flex;
  align-items: center;
  padding: 11px 0 10px 16px;
  margin-right: 23px;
  border-radius: 30px;
  background-color: ${theme.grayMid};
  height: 44px;
  width: 100%;

  img {
    height: 23px;
  }

  input {
    border: none;
    width: calc(100% - 30px);
    padding: 0;
    padding-left: 10px;
    height: 100%;
    background-color: transparent;

    &::placeholder {
      color: ${theme.gray};
    }
  }

  ${device.tablet} {
    display: ${(props) => (props.inSearchPage ? 'flex' : 'none')};
    position: fixed;
    top: 25px;
    left: 21px;
    height: 46px;
    width: calc(100vw - 175px) !important;

    input {
      height: 130%;
    }
  }

  ${device.mobile} {
    margin-top: -3px;
  }
`;
