import useAxios from 'axios-hooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Else, If, Then, Unless, When,
} from 'react-if';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { IUserCommunityDto } from 'api/dtos/userCommunity.dto';
import { ICommunity } from 'api/tables';
import { isFollower, isMember } from 'api/utils';
import star from 'assets/icons/star.svg';
import { AsyncButton } from 'components/form/AsyncButton';
import { useGlobalUserContext } from 'contexts/user-context/UserContext';
import theme from 'styles/theme';

export default function FollowButton({ community, className }: { community: ICommunity, className?: string }): JSX.Element {
  const { t } = useTranslation();
  const { userStateDispatch, userState: { user, communities: userCommunities } } = useGlobalUserContext();
  const [isFollowing, setIsFollowing] = useState<boolean>(false);
  const [isCommunityMember, setIsCommunityMember] = useState<boolean>(false);
  const [{ loading }, sendRequest] = useAxios<IUserCommunityDto[]>(`/api/communities/${community.id}`, { manual: true });
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [label, setLabel] = useState<string>(t('Follower'));
  const [style, setStyle] = useState(FollowerStyle);
  const history = useHistory();

  useEffect(() => {
    setIsFollowing(false);
    setIsCommunityMember(false);
    const userCommunity = user ? userCommunities.find((c) => c.id === community.id) : null;
    if (!userCommunity) {
      setIsFollowing(false);
    }

    if (userCommunity) {
      if (isFollower(userCommunity)) setIsFollowing(true);
      if (isMember(userCommunity)) setIsCommunityMember(true);
    }

    if (user) {
      setIsLoggedIn(true);
    }
  }, [community, t, userCommunities, user]);

  async function onClick(): Promise<void> {
    try {
      const newUserCommunities = await (isFollowing ? sendRequest({ method: 'DELETE' }) : sendRequest({ method: 'POST' }));
      userStateDispatch({ type: 'update', communities: newUserCommunities.data });
      setIsFollowing(!isFollowing);
      if (!isFollowing) {
        setStyle(FollowerStyle);
        setLabel(t('Follower'));
        toast.success(t('You are now following  {{communityName}}.', { communityName: community.name }));
      }
    } catch (error) {
      console.error(error);
      toast.error(t('Failed to follow community'));
    }
  }

  function onHover(): void {
    if (isFollowing) {
      setStyle(UnfollowStyle);
      setLabel(t('Unfollow'));
    }
  }

  function onLeave(): void {
    if (isFollowing) {
      setStyle(FollowerStyle);
      setLabel(t('Follower'));
    }
  }

  function loginRedirect(): void {
    history.push('/login');
  }

  return (
    <Container>
      <When condition={isLoggedIn}>
        <If condition={isCommunityMember}>

          <Then>
            <button className='member'>
              <img src={star} /><span className='text'>{t('Member')}</span>
            </button>
          </Then>

          <Else>
            <AsyncButton
              resetAfterSubmit
              disabled={loading}
              onClick={onClick}
              className={className}
              style={isFollowing ? style : undefined}
              onMouseEnter={onHover}
              onMouseLeave={onLeave}
            >
              {isFollowing ? label : t('Follow')}
            </AsyncButton>
          </Else>

        </If>
      </When>

      <Unless condition={isLoggedIn}>
        <button disabled={loading} onClick={loginRedirect} className={className}>
          {t('Sign in')}
        </button>
      </Unless>
    </Container>
  );
}

const Container = styled.div`
  align-self: center;

  button {
    height: 50px !important;
    width: 100px;
    min-width: 155px;
    border: 2.5px solid ${theme.primary};
    padding: 0;
    background: #F0F6FF;
    box-shadow: 0 5px 6px #24429B3D;
    color: black;
    text-transform: none;
    text-align: center;
    font-weight: 700;

    &:hover {
      background: initial;
    }
  }

  .member {
    background-color: white !important;
    border: none !important;
    color: ${theme.text} !important;
    font-weight: 700;
    box-shadow: 0 0 10px #C3C3C3A4 !important;
    cursor: initial;

    .text {
      padding-bottom: 2px;
      margin-bottom: 3px;
      position: relative;
      bottom: 5px;
    }

    img {
      padding-top: 2px;
      padding-right: 6px;
    }
  }
`;

const FollowerStyle = {
  backgroundColor: theme.primary,
  color: 'white',
};

const UnfollowStyle = {
  backgroundColor: 'white',
  color: 'black',
};
