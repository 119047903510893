import axios from 'axios';
import { useEffect, useState } from 'react';
import { Else, If, Then } from 'react-if';
import styled from 'styled-components';

import DocItem from 'components/DocItem';

interface IDownloadAttachment {
  attachmentId: string;
}

export default function DownloadAttachment({ attachmentId }: IDownloadAttachment): JSX.Element {
  const [fileName, setFileName] = useState<string>('');
  const [isImage, setIsImage] = useState<boolean>(false);
  const [isPDF, setIsPDF] = useState<boolean>(false);

  useEffect(() => {
    async function getFileName(): Promise<void> {
      const file = await axios.get(`/api/files/${attachmentId}`);
      setFileName(file.headers.name);
      if (file.headers['content-type'].includes('image')) setIsImage(true);
      if (file.headers['content-type'].includes('pdf')) setIsPDF(true);
    }
    void getFileName();
  }, [attachmentId]);

  return (
    <Container>
      <a href={`/api/files/${attachmentId}`} target={isPDF ? '_blank' : ''} rel='noopener noreferrer' style={{ textDecoration: 'none' }}>
        <If condition={isImage}>
          <Then>
            <div className='img-container'>
              <img src={`/api/files/${attachmentId}`} alt={fileName} />
              <p className='image-name'>{fileName}</p>
            </div>
          </Then>
          <Else>
            <DocItem fileName={fileName} />
          </Else>
        </If>
      </a>
    </Container>
  );
}

const Container = styled.div`
  a {
    padding: 0;
    margin: 0;
  }

  .image-name {
    font-size: 10px !important;
    color: black !important;
    background-color: #E8E8E8;
    height: auto;
    max-width: 200px;
    width: 100%;
    padding: 5px;
  }

  .img-container {
    border: 1px solid #CFCFCF;
    text-align: center;
    max-width: 200px;
    margin: 5px 0;

    img {
      max-width: 80px;
      max-height: 80px;
      margin: 0 auto;
    }
  }
`;
