import axios from 'axios';
import {
  DraftEditorCommand, DraftHandleValue,
} from 'draft-js';
import { useMemo, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import useAsyncEffect from 'use-async-effect';

import { IFile } from 'api/tables';
import bold from 'assets/icons/draft/bold.svg';
import emoji from 'assets/icons/draft/emoji.svg';
import image from 'assets/icons/draft/image.svg';
import indent from 'assets/icons/draft/indent.svg';
import italic from 'assets/icons/draft/italic.svg';
import link from 'assets/icons/draft/link.svg';
import ordered from 'assets/icons/draft/ordered-list.svg';
import outdent from 'assets/icons/draft/outdent.svg';
import redo from 'assets/icons/draft/redo.svg';
import resizer from 'assets/icons/draft/resizer.svg';
import strikethrough from 'assets/icons/draft/strike.svg';
import underline from 'assets/icons/draft/underline.svg';
import undo from 'assets/icons/draft/undo.svg';
import unordered from 'assets/icons/draft/unordered-list.svg';
import DocItem from 'components/DocItem';
import { EditorState } from 'discussions/EditorState';
import theme, { device } from 'styles/theme';

interface IEditorFileDrop {
  attachments?: IFile[];
  setAttachments(value: IFile[]): void;
  editorState: EditorState;
  setEditorState(editorState: EditorState): void;
  placeholder?: string | undefined;
  type?: string;
}

export default function EditorFileDrop({
  type,
  placeholder,
  editorState,
  attachments = [],
  setAttachments,
  setEditorState,
}: IEditorFileDrop): JSX.Element {
  const [numOfUploadingAttachments, setNumOfUploadingAttachments] = useState(0);
  const { t } = useTranslation();
  const List = styled.ul`
  height: 0;
  margin-bottom: calc(40px * ${attachments.length + numOfUploadingAttachments});
`;

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDropRejected: () => toast.warn(t('File must be less than 15 MB.')),
    maxSize: 15000000,
  });

  useAsyncEffect(async () => {
    if (acceptedFiles.length === 0) return;

    setNumOfUploadingAttachments(acceptedFiles.length);
    const formData = new FormData();
    for (const file of acceptedFiles) {
      formData.append(file.type, file);
    }

    const newFiles = await axios.post<IFile[]>('/api/files/upload', formData);
    setAttachments([...attachments, ...newFiles.data]);
    setNumOfUploadingAttachments(0);
  }, [acceptedFiles]);

  /**
   *
   * By default, attempting to backspace delete an atomic block will only delete an empty unstyled block.
   * This function will delete both the atomic block and the empty unstyled block
   */
  function handleAtomicBlockDeletion(command: DraftEditorCommand, state: EditorState): DraftHandleValue {
    if (command !== 'backspace') return 'not-handled';

    const currentContent = state.getCurrentContent();
    const selectedBlockKey = state.getSelection().getAnchorKey();
    const block = currentContent.getBlockForKey(selectedBlockKey);
    const blockBefore = currentContent.getBlockBefore(selectedBlockKey);
    if (block.getType() === 'unstyled' && block.getText() === '' && blockBefore?.getType() === 'atomic') {
      let newEditorState = EditorState.removeBlock(state, block);
      newEditorState = EditorState.removeBlock(newEditorState, blockBefore);
      newEditorState = EditorState.trimEmptyBlocks(newEditorState);
      setEditorState(newEditorState);
      return 'handled';
    }

    return 'not-handled';
  }

  async function uploadImage(file: File): Promise<{ data: { link: string } }> {
    const imageObject = {
      file,
      localSrc: URL.createObjectURL(file),
    };

    const formData = new FormData();
    const extension = imageObject.file.name.split('.').at(-1) as string;
    formData.append(`image/${extension}`, imageObject.file);
    const newFiles = await axios.post<IFile[]>('/api/files/upload', formData);
    const fileId = newFiles.data[0].id;

    return {
      data: { link: `/api/files/${fileId}` },
    };
  }

  const attachmentElements = useMemo(() => {
    function removeAttachment(fileId: string): void {
      setAttachments(attachments.filter((file) => file.id !== fileId));
    }

    const uploaded = attachments.map((file) => (
      <DocItem
        key={file.id}
        fileName={file.name}
        onClick={() => removeAttachment(file.id)}
      />
    ));

    const uploading = new Array(numOfUploadingAttachments)
      .fill(undefined)
      .map((i: number = 0) => <DocItem key={i} fileName='Loading...' />);

    return [...uploaded, ...uploading];
  }, [attachments, setAttachments, numOfUploadingAttachments]);

  return (
    <Container>
      <img className={type ? `${type}-pull-tab` : 'pull-tab'} src={resizer} />
      <Editor
        editorState={editorState}
        onEditorStateChange={setEditorState}
        wrapperClassName='demo-wrapper'
        editorClassName='demo-editor'
        wrapperStyle={wrapperStyle}
        editorStyle={editorStyle}
        handleKeyCommand={handleAtomicBlockDeletion}
        placeholder={placeholder || ''}
        toolbar={{
          options: ['inline', 'list', 'image', 'link', 'emoji', 'history'],
          inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough'],
            bold: { icon: bold },
            italic: { icon: italic },
            underline: { icon: underline },
            strikethrough: { icon: strikethrough },
          },
          list: {
            options: ['unordered', 'ordered', 'indent', 'outdent'],
            unordered: { icon: unordered },
            ordered: { icon: ordered },
            indent: { icon: indent },
            outdent: { icon: outdent },
          },
          image: {
            icon: image,
            urlEnabled: true,
            uploadEnabled: true,
            alignmentEnabled: false,
            uploadCallback: uploadImage,
            alt: { mandatory: false, present: true },
            defaultSize: { height: 'auto', width: 'auto' },
            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg,image/webp',
            previewImage: true,
          },
          fontFamily: { options: ['Inter'] },
          link: {
            popupClassName: { width: '10px' },
            link: { icon: link },
            options: ['link'],
            defaultTargetOption: '_blank',
          },
          emoji: { icon: emoji },
          history: {
            undo: { icon: undo },
            redo: { icon: redo },
          },

        }}
      />
      <div className='file-drop'>
        <div className='btn-group'>
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <div className='attach-file-btn'>
              <p>{t('Attach file')}</p>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className='attached-files'>
        <List>
          {attachmentElements}
        </List>
      </div>
    </Container>
  );
}

const Container = styled.div`
  font-size: 16px;
  margin-top: 20px;
  text-align: right;

  .demo-editor {
    resize: vertical !important;
    -ms-overflow-style: none;
    scrollbar-width: none;
    margin-bottom: 5px;
  }

  .demo-editor::-webkit-resizer {
    display: none;
  }

  .demo-editor::-webkit-scrollbar {
    color: white;
  }

  .pull-tab,
  .new-discussion-pull-tab,
  .edit-discussion-pull-tab,
  .reply-pull-tab,
  .edit-reply-pull-tab,
  .nested-reply-pull-tab,
  .nested-edit-reply-pull-tab {
    position: absolute;
    bottom: 86px;
    margin: 0;
    padding: 0;
    pointer-events: none;
    right: 8px;
  }

  .new-discussion-pull-tab,
  .edit-discussion-pull-tab {
    bottom: 94px;
  }

  .rdw-editor-main {
    ol {
      line-height: 1;
    }

    ul {
      line-height: 1;
    }

    .rdw-image-imagewrapper {
      margin-bottom: -30px;
    }
  }

  .rdw-editor-toolbar {
    border-top: none;
    border-bottom: 1px solid ${theme.gray};
  }

  .rdw-option-wrapper {
    border: none;
    min-width: 35px;
    height: 26px;
    margin: 0 4px;
    box-shadow: none;

    .row-option-wrapper:active {
      background-color: gray;
      border-radius: 50px;
      padding: 10px;
    }

    .rdw-option-active {
      box-shadow: none;
    }

    .rdw-dropdown-wrapper:hover {
      box-shadow: none;
      background-color: none;
    }

    .rdw-dropdown-wrapper:active {
      box-shadow: none;
    }
  }

  .rdw-emoji-wrapper {
    img {
      width: 22px;
      height: 22px;
    }
  }

  .rdw-inline-wrapper {
    .rdw-option-active {
      background-color: #DEDEDE;
      border-radius: 50px;
    }
  }

  .rdw-history-wrapper {
    margin-left: 1px;

    img {
      width: 21px;
      height: 21px;
    }
  }

  .rdw-link-modal {
    padding: 10px;
    border-radius: 10px;
    font-size: 14px;
    text-align: left;
    ${device.mobile} {
      left: -175px;
    }

    .rdw-link-modal-label {
      font-size: 14px;
      margin-bottom: 0 !important;
      padding-bottom: 0 !important;
      color: black;
    }

    .rdw-link-modal-input {
      margin-top: 0;
      font-size: 12px;
      border: 1px solid #CFCFCF;
      margin-bottom: 10px;
    }

    .rdw-link-modal-btn {
      border-radius: 15px;
      padding: 0;
      cursor: pointer;
      background: ${theme.primary};
      font-size: 14px;
    }

    .rdw-link-modal-buttonsection {
      margin: 0;
      margin-left: 13px;
    }

    .rdw-link-modal-target-option {
      font-size: 13px;
      height: 20px;
      padding-top: 2.5px;
      display: flex;

      input[type='checkbox'] {
        height: 17px;
        width: 17px;
      }

      span {
        position: relative;
        bottom: 3px;
        color: black;
      }
    }
  }

  .rdw-image-modal {
    border-radius: 10px;
    box-shadow: 3px 3px 5px #BFBDBD;
    left: -25px;
    text-align: left;

    ${device.mobile} {
      left: -132px;
    }

    .rdw-image-modal-header {
      font-size: 15px;
      margin: 10px 0;
      display: flex;
    }

    .rdw-image-modal-url-input {
      border-color: #CFCFCF;
      height: 25px;
      font-size: 11px;
      width: 105%;
    }

    .rdw-image-modal-btn {
      margin: 0 5px;
      padding: 0;
      background: ${theme.primary};
      border-radius: 15px;
      font-size: 14px;
    }

    .rdw-image-modal-size {
      visibility: hidden !important;
      height: 0;
      margin: 0;
      padding: 0;
    }
  }

  .rdw-image-alignment {
    img {
      max-width: 90%;
      max-height: 90%;
    }
  }

  .file-drop {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .btn-group {
      display: flex;
      justify-content: space-between;
      gap: 10px;

      .attach-file-btn {
        padding: 6px 17px;
        background-color: white;
        border: 2px solid ${theme.primary};
        cursor: pointer;

        p {
          color: black;
          text-align: center;
          font-size: 16px;
        }
      }

      .remove-file-btn {
        padding: 6px 17px;
        background-color: ${theme.primary};
        border: 2px solid ${theme.primary};
        cursor: pointer;

        p {
          color: white;
          text-align: center;
          font-size: 16px;
        }
      }
    }

    h4 {
      text-transform: none;
      margin: 20px 0;
    }
  }
`;

const wrapperStyle = {
  border: '1px solid #787878',
  margin: '0 0 21px',
  borderRadius: '10px',
  paddingTop: '7px',
};

const editorStyle = {
  padding: '10px',
  paddingBottom: '40px',
  paddingRight: '30px',
  paddingTop: '0',
  minHeight: '250px',
  height: 'auto',
};
