import { When } from 'react-if';
import styled from 'styled-components';

import Doc from 'assets/icons/doc.svg';
import x from 'assets/icons/x.svg';

export default function DocItem({ fileName, onClick }: { fileName: string, onClick?(): void }): JSX.Element {
  return (
    <Container>
      <div onMouseDown={onClick}>
        <img src={Doc} />
        <p className='file-name'>{fileName}
          <When condition={!!onClick}>
            <img src={x} onClick={onClick} />
          </When>
        </p>
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;

  div {
    display: flex;
    justify-content: left;
    width: 100%;

    img {
      width: 22px;
      height: 22px;
      margin: 0 0 14px;
    }
  }

  .file-name {
    color: #595959;
    text-decoration: none;
    font-size: 12px !important;
    padding-top: 5px;
    padding-left: 5px;
    margin-left: 10px;

    img {
      cursor: pointer;
      position: relative;
      top: 0.5px;
      left: 8px;
      width: 9px;
      height: 9px;
      margin: 0;
      padding: 0;
    }
  }
`;
