const theme = {
  primary: '#203C8D',
  dark: '#253F8C',
  darker: '#1A3279',
  blue: '#2648ab',
  light: '#1648FA',
  lighter: '#F5F9FF',
  mid: '#80A5D7',
  black: '#000000',
  gray: '#929292',
  text: '#595959',
  grayDark: '#7C7C7C',
  grayLight: '#C1C1C1',
  grayMid: '#EDEDED',
  grayBackground: '#F8F8F8',
};

export const device = {
  tablet: '@media only screen and (max-width: 1120px)',
  mobile: '@media only screen and (max-width: 600px)',
  short: '@media only screen and (max-height: 950px)',
  tiny: '@media only screen and (max-height: 700px)',
};

export default theme;
