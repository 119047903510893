import ReactGA from 'react-ga4';

import { IUserDto } from 'api/dtos/user.dto';
import { IUserCommunityDto } from 'api/dtos/userCommunity.dto';

export function setupAnalyticsWithUser(user?: IUserDto & { communities: IUserCommunityDto[] }): void {
  if (!process.env.REACT_APP_GA_TRACKING_ID) {
    throw new Error('Google Analytics Tracking ID (REACT_APP_GA_TRACKING_ID) is missing in the environment variables!');
  }
  if (user) {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID, {
      gaOptions: { userId: user.id },
      gtagOptions: {
        user_properties: { community: user.communities.map((community) => community.name).join(',') },
        send_page_view: false, // Ref: https://stackoverflow.com/a/66318326
      },
    });
  } else {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID, {
      gaOptions: { userId: undefined },
      gtagOptions: {
        send_page_view: false,
      },
    });
  }
}
