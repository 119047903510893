import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import Avatar from 'react-avatar';
import { When } from 'react-if';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import UserMenu from './UserMenu';
import { IUserDto } from 'api/dtos/user.dto';
import { IUser } from 'api/tables';
import { getDisplayName } from 'api/utils';
import { useLoggedInUser } from 'contexts/user-context/UserContext';
import useNonInitialEffect from 'hooks/useNonInitialEffect';
import { device } from 'styles/theme';

interface IUserAvatar {
  user: IUser | IUserDto;
  className?: string;
  size?: number;
  showMenu?: boolean;
  profileLink?: boolean;
  fileId?: string;
}

function UserAvatar({
  className, fileId, size = 38, profileLink = false, showMenu = true, user,
}: IUserAvatar): JSX.Element {
  const { user: loggedUser } = useLoggedInUser();
  const history = useHistory();
  const [showInitials, setShowInitials] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);

  const img = useMemo(() => {
    if (fileId) return `/api/files/${fileId}`;
    if (user.avatar_url) return `/api/files/${user.avatar_url}`;
    return undefined;
  }, [fileId, user.avatar_url]);

  const onClick = useCallback((e: MouseEvent) => {
    if (profileLink) return history.push(`/profile/${user.id}`);
    if (!showMenu) return;
    if (loggedUser.id !== user.id) return history.push(`/profile/${user.id}`);

    e.stopPropagation();
    setShowUserMenu((s) => !s);
  }, [history, loggedUser.id, showMenu, user.id, profileLink]);

  const onClickWindow = useCallback(() => setShowUserMenu(false), []);

  useNonInitialEffect(() => {
    if (fileId === undefined) setShowInitials(true);
    else setShowInitials(false);
  }, [fileId]);

  useEffect(() => {
    window.addEventListener('click', onClickWindow);

    return () => window.removeEventListener('click', onClickWindow);
  }, [onClickWindow]);

  return (
    <Container hover={showMenu || profileLink} onClick={(e) => onClick(e.nativeEvent)} className={className}>
      <Avatar className='avatar' src={showInitials ? undefined : img} name={getDisplayName(user, false)} round size={size.toString()} />
      <When condition={showUserMenu}>
        <UserMenu className='menu' />
      </When>
    </Container>
  );
}

export default UserAvatar;

const Container = styled.div<{ hover: boolean }>`
  position: relative;

  .menu {
    overflow-y: hidden;
    position: absolute;
    top: 140%;
    z-index: 3;
    right: 0;
    box-shadow: 0 4px 30px 5px #EAEAEA;
    zoom: 1.1 !important;
  }

  .avatar {
    font-weight: 700;
    font-size: 25px;
  }

  .avatar:hover {
    cursor: ${(props) => (props.hover ? 'pointer' : 'default')};
  }

  ${device.tablet},
  ${device.mobile} {
    position: static;

    .menu {
      top: 70px;
      right: 90px;
      z-index: 3;
    }
  }
`;
