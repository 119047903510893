import styled from 'styled-components';

import theme, { device } from 'styles/theme';

const ArticleDescription = styled.div`
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 0;
  color: ${theme.text};
  padding-right: 4%;
  overflow-wrap: break-word;

  p {
    font-family: inherit;
    font-size: 15px;
  }

  ${device.tablet} {
    font-size: 15px;
    padding-right: 0%;
    line-height: 24px;
  }
`;

export default ArticleDescription;
