import { yupResolver } from '@hookform/resolvers/yup';
import axios, { AxiosResponse } from 'axios';
import classNames from 'classnames';
import { useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  SchemaOf, mixed, object, string,
} from 'yup';

import { IUserCommunityDto } from 'api/dtos/userCommunity.dto';
import { IUser } from 'api/tables';
import { AsyncButton } from 'components/form/AsyncButton';
import Input from 'components/form/Input';
import ValidationErrors from 'components/form/validation-errors';
import { IUserState } from 'contexts/user-context/interfaces/IUserState';
import { useGlobalUserContext } from 'contexts/user-context/UserContext';
import { StyledLink } from 'styles/styled-components';
import { device } from 'styles/theme';

interface ILoginForm {
  email: string;
  password: string;
  auth: undefined | null;
}

const schema: SchemaOf<ILoginForm> = object({
  email: string().required('Email is required')
    .email(),
  password: string().required('Password is required'),
  auth: mixed(),
});

function LoginForm(): JSX.Element {
  const { t } = useTranslation();
  const { showBoundary } = useErrorBoundary();
  const formMethods = useForm<ILoginForm>({
    resolver: yupResolver(schema),
  });
  const {
    register, handleSubmit, formState, setError, clearErrors,
  } = formMethods;
  const { userStateDispatch } = useGlobalUserContext();

  async function onSubmit(data: ILoginForm): Promise<void> {
    const { email, password } = data;
    try {
      const user = await axios.post<IUserState, AxiosResponse<IUser>>('/api/auth/login', {
        email,
        password,
      });
      window.location.reload(); // reload to get new user context
      const communities = await axios.get<IUserCommunityDto[]>(`/api/communities/user/${user.data.id}`);

      userStateDispatch({
        type: 'update', user: user.data, communities: communities.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        setError('email', { type: 'manual', message: error.response.data.message });
        setError('password', { type: 'manual' });
        return;
      }
      showBoundary(error);
    }
  }

  const rule = { required: ValidationErrors.REQUIRED };
  const emailClassName = classNames('input-email', { invalid: !!formState.errors.email });
  const passwordClassName = classNames('input-password', { invalid: !!formState.errors.password });

  return (
    <StyledForm
      onSubmit={handleSubmit(onSubmit)}
      onChange={() => {
        // validate if user using browser autofill
        if (document.activeElement?.id === 'email') {
          ['password', 'email'].forEach((item) => document.getElementsByName(item)[0].focus());
        } else {
          ['email', 'password'].forEach((item) => document.getElementsByName(item)[0].focus());
        }
        clearErrors(['email', 'password']);
      }}
    >
      <FormProvider {...formMethods}>
        <Input
          id='email'
          className={emailClassName}
          type='email'
          label={t('Email *')}
          {...register('email', rule)}
        />
        <Input
          id='password'
          className={passwordClassName}
          type='password'
          label={t('Password *')}
          {...register('password', rule)}
        />

        <div className='bottom-section'>
          <AsyncButton type='submit' formState={formState}>{t('Sign In')}</AsyncButton>
          <StyledLink className='forgot-password' to="/password-reset">{t('Forgot your password?')}</StyledLink>
          <div className='signup-section'>
            <p className='setup-user'>
              <Trans>
                Do you receive the weekly Sosido email digests? <StyledLink className='sign-up' to="/password-reset">Set your password</StyledLink>
              </Trans>
            </p>
            <p className='setup-user'>
              <Trans>
                Don&apos;t receive Sosido email digests? <StyledLink className='sign-up' to="/signup">Sign up</StyledLink>
              </Trans>
            </p>
          </div>
        </div>
      </FormProvider>
    </StyledForm>
  );
}

export default LoginForm;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  p {
    margin-bottom: 0;
    font-size: 14px;
  }

  .input-email,
  .input-password {
    width: 100%;

    input {
      border-radius: 10px;
    }
  }

  .input-email {
    margin-bottom: 25px;
  }

  .bottom-section {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;

    button {
      margin: 36px 0;
      width: 191px;
      height: 61px;
    }

    .forgot-password {
      align-self: center;
      justify-self: end;
      font-size: 14px;
      font-weight: 400;
    }

    p {
      line-height: 40px;
      grid-column: 1 / 3;
      font-family: 'Liberation Sans', Inter, sans-serif;
      font-size: 14px !important;

      .sign-up {
        text-decoration: none;
      }
    }

    .setup-user {
      line-height: 20px;
    }

    .signup-section {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: fit-content;
    }
  }

  ${device.mobile} {
    width: 100%;

    .bottom-section {
      display: flex;
      flex-direction: column;

      button {
        width: 100%;
      }

      .forgot-password {
        align-self: flex-start;
        margin-bottom: 20px;
        margin-top: -20px;
      }

      .sign-up {
        display: inline-block;
        white-space: nowrap;
      }

      .set-password {
        line-height: 20px;
      }

      & > p {
        font-size: 18px;
      }
    }
  }
`;
