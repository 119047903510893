import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import styled, { StyledComponent } from 'styled-components';

import Checkmark from 'assets/icons/check-mark.svg';
import theme from 'styles/theme';

type Component = Select | AsyncSelect;

export default function createStyledSelect<C extends Component>(component: C, prefix: string): StyledComponent<C, any> {
  return styled(component)`
  * {
    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
      position: relative;
      right: 2px;
    }

    ::-webkit-scrollbar-thumb {
      background: #C4C7CC;
      border-radius: 2px;
    }
  }

  .${prefix}__control {
    border: 0.5px solid #A4A4A4 !important;
    border-radius: 10px;
    height: 54px;
    font-size: 16px;

    .${prefix}__indicator {
      height: 23px;
      width: 23px;
      padding: 0;
      margin: 0 12px 0 0;
      transform: rotate(0deg) scale(130%);

      img {
        width: 100%;
        height: 100%;
      }
    }

    .${prefix}__indicator-separator {
      display: none;
    }

    .${prefix}__value-container {
      padding: 0 8px;
    }

    .${prefix}__input-container {
      height: 100%;
      margin: 0;
      padding: 0;
    }

    &.${prefix}__control--menu-is-open {
      border-color: ${theme.light};

      .${prefix}__indicator {
        height: 23px;
        width: 23px;
        padding: 0;
        margin: 0 16px 0 0;
        transform: rotate(180deg) scale(130%);
      }

      .${prefix}__loading-indicator {
        position: relative;
        top: 0;
        height: 5px;
        left: 2px;
      }
    }

    &.${prefix}__control--is-focused {
      box-shadow: none;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .${prefix}__menu {
    box-shadow: none;
    border-radius: 0 0 10px 10px;
    overflow: hidden;
    margin: 0;
    border: 1px solid ${theme.light};
    border-top: none;
    top: calc(100% - 9px);

    .${prefix}__menu-list {
      padding: 0;

      .${prefix}__option:hover {
        cursor: pointer;
      }

      .${prefix}__option--is-selected {
        background-color: white;
        color: black;

        &::after {
          content: '';
          width: 23px;
          height: 23px;
          background-size: contain;
          background-repeat: no-repeat;
          background-image: url(${Checkmark});
          position: absolute;
          right: 12px;
        }
      }

      .${prefix}__option--is-focused {
        background-color: ${theme.grayBackground};
      }
    }
  }
`;
}
