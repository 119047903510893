import { css } from 'styled-components';

import theme, { device } from 'styles/theme';

const h6 = css`
  font-weight: 700;
  line-height: 40px;
  font-size: 14px;
`;

const h5 = css`
  font-weight: 400;
  color: ${theme.light};
  margin-bottom: 10px;
  line-height: 40px;
  font-size: 16px;
  text-transform: uppercase;
`;

const h4 = css`
  font-weight: 700;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 20px;
`;

const h3 = css`
  font-size: 25px;
  font-weight: 400;
  font-family: Inter, sans-serif;
`;

const h2 = css`
  font-size: 28px;
  font-family: Poppins, Inter, sans-serif;
  font-weight: 500;
`;

const h1 = css`
  font-size: 35px;
  line-height: 40px;
  font-family: Poppins, Inter, sans-serif;
  font-weight: 700;

  ${device.tablet} {
    font-size: 30px;
  }
`;

export {
  h1, h2, h3, h4, h5, h6,
};
