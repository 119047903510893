import classNames from 'classnames';
import Hamburger from 'hamburger-react';
import { useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { NavbarContext } from './NavBarContext';
import Logo from 'assets/graphics/logo.svg';
import { StyledLink } from 'styles/styled-components';
import theme, { device } from 'styles/theme';

export default function LoginNav(): JSX.Element {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { t } = useTranslation();
  const { resetBoundary } = useErrorBoundary();
  const history = useHistory();

  return (
    <Container className={classNames({ 'mobile-menu-open': isMobileMenuOpen })}>
      <NavbarContext.Provider value={{ isMobileMenuOpen, setMobileMenuOpen }}>
        <section className='logo-burger'>
          <Link to="/">
            <img src={Logo} />
          </Link>
          <div className='hamburger'>
            <Hamburger toggle={setMobileMenuOpen} toggled={isMobileMenuOpen} />
          </div>
        </section>
        <nav className='sign-in-join'>
          <StyledLink
            onClick={() => { setMobileMenuOpen(false); resetBoundary(); }}
            className='sign-in'
            to='/login'
          >{t('Sign In')}
          </StyledLink>
          <button
            onClick={() => { history.push('/signup'); setMobileMenuOpen(false); resetBoundary(); }}
          >{t('Join')}
          </button>
        </nav>
      </NavbarContext.Provider>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 53px;
  margin: 16px 35px 0 0;

  .logo-burger {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    img {
      width: 140px;
      height: 100%;
      align-self: center;
    }

    .hamburger {
      display: none;
    }
  }

  .sign-in-join {
    display: flex;
    align-items: center;

    .sign-in {
      margin-right: 22px;
      font-size: 18px;
      font-weight: 600;
      color: #000;
      text-decoration: none;
      font-family: Poppins, sans-serif;
      white-space: nowrap;
    }

    button {
      width: 167px;
      height: 53px;
      line-height: 0;
    }
  }

  ${device.tablet} {
    overflow-y: visible;
    background-color: white;

    .logo-burger {
      zoom: 0.9;

      .hamburger {
        display: block;

        .hamburger-react {
          z-index: 3;
        }
      }
    }

    .sign-in-join {
      display: none;
    }

    &.mobile-menu-open {
      background-color: ${theme.lighter};

      .sign-in-join {
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        height: 100vh;
        width: 100vw;
        z-index: 2;
        background-color: inherit;

        .sign-in,
        button {
          margin: 15px;
        }
      }
    }
  }
`;
