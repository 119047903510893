import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Logo from 'assets/graphics/logo.svg';
import theme, { device } from 'styles/theme';
import { getMarketingUrl } from 'utils';

interface IFooter {
  className: string;
}

export function Footer({ className }: IFooter): JSX.Element {
  const { t } = useTranslation();

  const basePath = getMarketingUrl();

  return (
    <Container className={className}>
      <nav>

        <div className='reserved'>
          <img className="logo" src={Logo} />
        </div>

        <div className="links">
          <ul>
            <li><h6>{t('Menu')}</h6></li>
            <li><a target='_blank' href={`${basePath}/about`} rel="noreferrer">{t('About')}</a></li>
            <li><a target='_blank' href={`${basePath}/communities`} rel="noreferrer">{t('Communities')}</a></li>
            <li><a target='_blank' href="http://blog.sosido.com/blog" rel="noreferrer">{t('Blog')}</a></li>
            <li><a target='_blank' href={`${basePath}/contact`} rel="noreferrer">{t('Contact')}</a></li>
          </ul>

          <ul>
            <li><h6>{t('Legal')}</h6></li>
            <li><a target='_blank' href={`${basePath}/privacy-policy`} rel="noreferrer">{t('Privacy Policy')}</a></li>
            <li><a target='_blank' href={`${basePath}/terms-of-use`} rel="noreferrer">{t('Terms of Use')}</a></li>
            <li><a target='_blank' href={`${basePath}/faq`} rel="noreferrer">{t('FAQ')}</a></li>
          </ul>
        </div>

      </nav>

      <p>{`© ${t('Sosido Networks. All rights reserved.')}`}</p>
    </Container>
  );
}

const lineHeight = 'line-height: 24px;';

const Container = styled.footer`
  width: 107.5%;
  font-size: 12px;
  padding-top: 50px;
  z-index: 10;
  box-shadow: -10px 1px 9px #00000006, -10px -1px 9px #00000006;
  position: relative;
  right: 40px;
  overflow-y: scroll;        /* hide scrollbar for */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  p {
    text-align: center;
    color: ${theme.gray};
    margin-bottom: 15px;
    margin-right: 20px;
    font-size: 12px;
    ${lineHeight}
    ${device.mobile} {
      margin-right: 0;
      margin-left: 10vw;
    }
  }

  nav {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 30px;

    .links {
      display: flex;
      font-size: 14px;
      padding-right: 90px;
      ${device.tablet} {
        padding-right: 20px;
      }

      ul {
        list-style: none;
        padding-right: 50px;

        h6 {
          margin-bottom: 19px;
          ${lineHeight}
        }

        li {
          margin-bottom: 11px;
          ${lineHeight}
          a {
            color: ${theme.grayDark};
            &:hover { color: ${theme.light}; }
          }
        }
      }
    }

    .reserved {
      display: block;
      align-items: left;

      .logo {
        height: 35px;
        display: flex;
        width: auto;
        margin-left: 110px;

        ${device.mobile} {
          margin-left: 0;
        }

        /* Turns any image into the ${theme.gray} color */

        filter: invert(69%) sepia(0%) saturate(0%) hue-rotate(123deg) brightness(85%) contrast(87%);
      }
    }

    ${device.mobile} {
      flex-direction: column;

      .logo {
        margin-bottom: 65px;
        position: relative;
        left: 40px;
      }

      .links {
        font-size: 20px;
        flex-direction: column;
        position: relative;
        left: 48px;

        ul:first-of-type { margin-bottom: 56px; }

        h6,
        ul li {
          font-size: inherit;
          margin-bottom: 27px;
        }

        ul li:last-of-type { margin-bottom: 0; }
      }
    }
  }
`;
