import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import classNames from 'classnames';
import { useErrorBoundary } from 'react-error-boundary';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  SchemaOf, mixed, object, string,
} from 'yup';

import { AsyncButton } from 'components/form/AsyncButton';
import Input from 'components/form/Input';
import ValidationErrors from 'components/form/validation-errors';
import { device } from 'styles/theme';

interface IPasswordResetForm {
  email: string;
  auth: undefined | null;
}

const schema: SchemaOf<IPasswordResetForm> = object({
  email: string().required()
    .email(),
  auth: mixed(),
});

export default function RequestPasswordResetForm(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const { showBoundary } = useErrorBoundary();
  const formMethods = useForm<IPasswordResetForm>({
    resolver: yupResolver(schema),
  });
  const {
    register, handleSubmit, formState, setError,
  } = formMethods;

  async function onSubmit(data: IPasswordResetForm): Promise<void> {
    const { email } = data;
    try {
      await axios.post('/api/auth/password/reset', { email });
      history.push('/password-reset/message');
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 400) {
        setError('email', { type: 'manual', message: error.response.data.message });
        return;
      }
      showBoundary(error);
    }
  }

  const rule = { required: ValidationErrors.REQUIRED };
  const emailClassName = classNames('input-email', { invalid: !!formState.errors.email });

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...formMethods}>
        <Input className={emailClassName} type="email" label={t('Email address')} {...register('email', rule)} />
        <AsyncButton className='send' formState={formState}>{t('Send')}</AsyncButton>
      </FormProvider>
      <a className='back' onClick={() => history.goBack()}>{t('Back')}</a>
    </StyledForm>
  );
}

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  .input-email {
    width: 100%;
    margin-bottom: 22px;

    label {
      text-transform: none;
    }

    input {
      border-radius: 10px;
    }
  }

  .send {
    width: 191px;
    margin-bottom: 31px;
  }

  .back {
    cursor: pointer;
  }

  ${device.mobile} {
    .input-email {
      margin-bottom: 38px;
    }

    .send {
      width: 100%;
      margin-bottom: 63px;
    }

    .back {
      align-self: center;
    }
  }
`;
