import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import EmailLink from 'components/EmailLink';
import { device } from 'styles/theme';

function SignUpPage(): JSX.Element {
  const { t } = useTranslation();
  document.getElementById('main')?.scrollTo(0, 0);

  return (
    <Container>
      <div className='join-as-individual'>
        <div className='form-container'>
          <h5>{t('Join as an Individual')}</h5>
          <h3>{t('I am an individual healthcare professional or researcher.')}</h3>
          <p>
            {t('Sign up to receive the weekly Sosido email digest with updates from healthcare communities you choose and summaries of publications in your field. ')}
            {t('Accounts on Sosido are free of charge.')}
          </p>
          <a href='https://forms.gle/qhZkrce8vfJXxEb9A' target='_blank' rel='noreferrer'><button className='sign-up'>{t('Sign up')}</button></a>
        </div>
      </div>
      <div className='line' />
      <div className='join-as-group'>
        <div className='form-container'>
          <h5>{t('Join as a Group')}</h5>
          <h3>{t('I am a leader of a group of healthcare professionals (e.g., association, research network, hospital or academic department).')}</h3>
          <p>
            {t('Our network of healthcare groups on Sosido is growing steadily and we welcome new groups. ')}
            <Trans>Email us directly at <EmailLink>contact@sosido.com</EmailLink> with some basic information
              about your group (e.g., name of group, number of members).
            </Trans>
            {t('We will be pleased to provide more information.')}
          </p>
        </div>
      </div>
    </Container>
  );
}

export default SignUpPage;

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 75px;

  h3 {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 10px;
    font-weight: 500;
  }

  h5 {
    margin-bottom: 5px;
  }

  p {
    font-size: 16px;
    color: #6D6D6D;
    letter-spacing: 0.5px;
    line-height: 30px;
    margin-bottom: 15px;
  }

  .join-as-individual,
  .join-as-group {
    width: 383px;
    height: 234px;
    position: relative;
  }

  .join-as-individual {
    left: 20px;
  }

  .join-as-group {
    right: 70px;
  }

  .sign-up {
    width: 191px;
  }

  .line {
    height: 500px;
    border: 1.25px solid #F0F6FF;
    background-color: #F0F6FF;
    position: relative;
    bottom: 30px;
  }

  .form-container {
    width: 464px;

    h5 {
      margin-bottom: 10px;
    }

    h1 {
      font-size: 40px;
      margin-bottom: 25px;
    }
  }

  ${device.tablet} {
    width: 100%;
    margin-bottom: 90px;
    flex-direction: column;
    margin-top: 30px;

    .join-as-group,
    .join-as-individual {
      width: 100%;
      display: flex;
      justify-content: center;
      right: 0;
      left: 20px;
      padding-right: 30px;
    }

    .line {
      height: 0;
      width: 90%;
      margin: 0 auto;
      margin-top: 60px;
    }

    .form-container {
      width: 100%;

      h1 {
        font-size: 35px;
      }
    }
  }

  ${device.mobile} {
    padding-bottom: 175px;
    gap: 15px;

    .line {
      margin-top: 100px;
      bottom: 0;
      border: 0 solid;
    }

    .join-as-group,
    .join-as-individual {
      left: 0;
      padding-right: 5px;
    }

    .sign-up {
      position: relative;
      width: calc(100vw - 43px);
      right: 5px;
    }

    .join-as-group {
      margin-top: 50px;
    }
  }
`;
