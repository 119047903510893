import { darken } from 'polished';
import styled from 'styled-components';

interface IBanner {
  className: string;
  colour: string;
  src?: string;
}

function Banner({ className, colour, src }: IBanner): JSX.Element {
  return (
    <Container colour={colour} className={className} src={src} />
  );
}

export default Banner;

interface IStyling {
  colour: string;
  src?: string;
}

const Container = styled.div<IStyling>`
  background:
    ${(props) => (props.src ? `url(${props.src}) ` : `linear-gradient(
      90deg,
      ${darken(0.2, `#${props.colour}`)} 0%,
      #${props.colour} 50%,
      ${darken(0.2, `#${props.colour}`)} 99%
    ) `)};
  background-size: cover;
  background-position: center;
  max-width: calc(1584px * 0.858); /* downscale via calc(); to align banner */
  max-height: calc(320px * 0.858);  /* transform: scale(); doesn't work well */
  min-height: 40px;
  min-width: 198px;
  aspect-ratio: 4.95 / 1;
  margin-right: -10px;
  margin-left: -40px;
`;
