import { Redirect, Route, RouteProps } from 'react-router-dom';

import { useGlobalUserContext } from 'contexts/user-context/UserContext';

export default function PrivateRoute({ children, ...rest }: RouteProps): JSX.Element {
  const { userState: { user } } = useGlobalUserContext();

  return (
    <Route {...rest}>
      {user ? children : <Redirect to='/login' />}
    </Route>
  );
}
