import { ErrorMessage } from '@hookform/error-message';
import axios from 'axios';
import { t } from 'i18next';
import { useCallback } from 'react';
import Dropzone from 'react-dropzone';
import { useController } from 'react-hook-form';

import { IUpdateProfileForm } from './IUpdateProfileForm';
import { IFile } from 'api/tables';
import UserAvatar from 'components/page-template/UserAvatar';
import { useLoggedInUser } from 'contexts/user-context/UserContext';
import { FormError } from 'styles/styled-components/FormError';

export default function UploadAvatar(): JSX.Element {
  const { user } = useLoggedInUser();
  const { field, formState: { errors } } = useController<IUpdateProfileForm>({
    name: 'avatar_url',
  });

  const uploadAvatar = useCallback(async (acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) return;

    const file = acceptedFiles[0];
    const formData = new FormData();
    const imageObject = {
      file,
      localSrc: URL.createObjectURL(file),
    };

    const extension = imageObject.file.name.split('.').at(-1) as string;
    formData.append(`image/${extension}`, imageObject.file);
    const image = await axios.post<IFile[]>('/api/files/upload', formData);
    field.onChange(image.data[0].id as string);
  }, [field]);

  async function removeAvatar(): Promise<void> {
    field.onChange(null);
  }

  return (
    <>
      <UserAvatar className='avatar' user={user} fileId={field.value as string | undefined} size={86} showMenu={false} />
      <Dropzone {...field} onDrop={uploadAvatar}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div className='btn-group'>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div className='upload'>{t('Upload image')}</div>
              </div>
              <div className='remove' onClick={removeAvatar}>{t('Remove')}</div>
            </div>
            <div className='guidance-text'>
              <p>For best results, please upload a square image.</p>
            </div>
            <ErrorMessage name='avatar_url' errors={errors} render={FormError} />
          </section>
        )}
      </Dropzone>
    </>
  );
}
