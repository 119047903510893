/* eslint-disable @typescript-eslint/naming-convention */
import { useTranslation } from 'react-i18next';
import { When } from 'react-if';
import { useLocation } from 'react-router-dom';

import { IUserDto } from 'api/dtos/user.dto';

interface IPageTitle {
  className?: string;
  user: IUserDto;
}

function PageTitle({ className, user }: IPageTitle): JSX.Element {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const profileId = Number(pathname.replace('/profile/', ''));

  const titles: {
    [index: string]: string;
  } = {
    '/articles': t('Articles'),
    '/feeds': t('Sources'),
    '/follow': t('Sources'),
    '/news': t('News'),
    '/resources': t('Resources'),
    '/discussions/new': t('Ask a question'),
    '/discussions': t('Q&A'),
    '/communities': '',
    '/profile': user.id === profileId ? t('My Profile') : t('Profile'),
  };

  const titleKey = Object.keys(titles).find((key) => pathname.includes(key));
  const title = titleKey ? titles[titleKey] : undefined;

  return (
    <When condition={!!title}>
      <h1 className={className}>{title}</h1>
    </When>
  );
}

export default PageTitle;
