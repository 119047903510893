import { When } from 'react-if';
import styled from 'styled-components';

import { IDiscussionAttachment, IDiscussionReplyAttachment } from 'api/tables';
import DownloadAttachment from 'components/DownloadAttachment';

interface IAttachments {
  attachments?: IDiscussionAttachment[] | IDiscussionReplyAttachment[] | null;
}

export default function Attachments({ attachments }: IAttachments): JSX.Element {
  const attachmentUrls = attachments?.map((attachment) => <DownloadAttachment key={attachment.file_id} attachmentId={attachment.file_id} />).reverse();

  return (
    <When condition={attachments?.length !== 0}>
      <Container className='container'>
        {attachmentUrls || null}
      </Container>
    </When>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 18px 0 0;
  padding-bottom: 40px;
`;
