export interface IAuthor {
  affiliation?: string | undefined | null;
  first_name: string;
  full_name: string;
  last_name: string;
  position: number;
}
/*
* This file was generated by a tool.
* Rerun [ npm run build:types ] to regenerate this file.
*/
export interface IArchivedUser {
  created_at: Date;
  reason: ArchiveReason;
  updated_at: Date;
  user_id: number;
}

export interface IArticle {
  authors: string[];
  authors_raw?: string | undefined | null;
  channel_name?: string | undefined | null;
  content: string;
  content_raw: string;
  created_at: Date;
  cropped: boolean;
  description?: string | undefined | null;
  description_raw?: string | undefined | null;
  digest_content_override_id?: number | undefined | null;
  doi_id?: string | undefined | null;
  feed_id?: number | undefined | null;
  guid: string;
  hidden: boolean;
  id: number;
  legacy_slug?: string | undefined | null;
  open_access?: boolean | undefined | null;
  original_pub_date: Date;
  pub_date: Date;
  publication_name?: string | undefined | null;
  pubmed_id?: string | undefined | null;
  title: string;
  title_raw: string;
  updated_at: Date;
  url: string;
}

export interface ICommunity {
  colour?: string | undefined | null;
  created_at: Date;
  default_send_digest_at?: Date | undefined | null;
  description?: string | undefined | null;
  digest_login_enabled?: boolean;
  facebook_url?: string | undefined | null;
  id: number;
  linkedin_url?: string | undefined | null;
  logo_url?: string | undefined | null;
  name: string;
  private: boolean;
  slug: string;
  twitter_url?: string | undefined | null;
  updated_at: Date;
  url?: string | undefined | null;
}

export interface ICommunityDigest {
  approved_at?: Date | undefined | null;
  community_id: number;
  id: number;
  send_at: Date;
  sent_at?: Date | undefined | null;
}

export interface ICommunityFeed {
  community_id: number;
  created_at: Date;
  default?: boolean;
  feed_id: number;
  id: number;
  updated_at: Date;
}

export interface ICountry {
  created_at: Date;
  id: number;
  name: string;
  updated_at: Date;
}

export interface IDigestContentOverride {
  expiry?: Date | undefined | null;
  id: number;
  priority?: number;
  publish?: boolean | undefined | null;
}

export interface IDiscussionAttachment {
  discussion_id: number;
  file_id: string;
}

export interface IDiscussionReply {
  body: string;
  created_at: Date;
  discussion_id: number;
  hidden: boolean;
  id: number;
  parent_id?: number | undefined | null;
  updated_at: Date;
  user_id: number;
}

export interface IDiscussionReplyAttachment {
  discussion_reply_id: number;
  file_id: string;
}

export interface IDiscussion {
  body?: string | undefined | null;
  community_id: number;
  created_at: Date;
  digest_content_override_id?: number | undefined | null;
  hidden: boolean;
  id: number;
  title: string;
  updated_at: Date;
  user_id: number;
}

export interface IFeed {
  colour?: string | undefined | null;
  created_at: Date;
  crop_articles?: boolean;
  enabled?: boolean;
  id: number;
  last_fetch?: Date | undefined | null;
  rss_url?: string | undefined | null;
  title: string;
  type: FeedTypes;
  updated_at: Date;
}

export interface IFile {
  created_at: Date;
  extension: string;
  id: string;
  name: string;
  size: number;
  updated_at: Date;
}

export interface IJobTitle {
  index?: number;
  name: string;
  organization_id: number;
  user_id: number;
}

export interface ILinkedAuthor {
  affiliation?: string | undefined | null;
  article_id: number;
  created_at: Date;
  pending?: boolean;
  position: number;
  probability?: number | undefined | null;
  updated_at: Date;
  user_id: number;
}

export interface IOrganization {
  city?: string | undefined | null;
  country_id?: number | undefined | null;
  created_at: Date;
  id: number;
  prov_state?: string | undefined | null;
  title: string;
  updated_at: Date;
}

export interface IPendingEmail {
  article_id?: number | undefined | null;
  created_at: Date;
  discussion_id?: number | undefined | null;
  id: number;
  queued_at?: Date | undefined | null;
  reply_id?: number | undefined | null;
  sent_at?: Date | undefined | null;
  title: string;
  type: EmailTypes;
  user_id: number;
}

export interface IPendingMembersPublication {
  article_id: number;
  created_at: Date;
  id: number;
  imported?: boolean | undefined | null;
  updated_at: Date;
  user_id: number;
}

export interface IPracticeArea {
  created_at: Date;
  id: number;
  name: string;
  updated_at: Date;
}

export interface IProfessionalRole {
  created_at: Date;
  id: number;
  name: string;
  updated_at: Date;
}

export interface IRejectedGuid {
  guid: string;
}

export interface ISuppressedArticle {
  article_id: number;
  community_id: number;
  created_at: Date;
  updated_at: Date;
}

export interface IUser {
  avatar_url?: string | undefined | null;
  city?: string | undefined | null;
  country_id?: number | undefined | null;
  created_at: Date;
  credentials?: string | undefined | null;
  email: string;
  first_name?: string | undefined | null;
  id: number;
  lang?: string | undefined | null;
  last_name?: string | undefined | null;
  last_name_unaccented?: string | undefined | null;
  last_sign_in_at?: Date | undefined | null;
  middle_name?: string | undefined | null;
  notes?: string | undefined | null;
  password?: string | undefined | null;
  prov_state?: string | undefined | null;
  role: string;
  rss_url?: string | undefined | null;
  show_email?: boolean;
  similar_author?: boolean;
  title?: string | undefined | null;
  unsubscribed_at?: Date | undefined | null;
  updated_at: Date;
}

export interface IUsersCommunity {
  community_id: number;
  created_at: Date;
  id: number;
  priority?: number | undefined | null;
  relationship: string;
  updated_at: Date;
  user_id: number;
}

export interface IUsersFeed {
  created_at: Date;
  feed_id: number;
  id: number;
  updated_at: Date;
  user_id: number;
}

export interface IUsersOrganization {
  created_at: Date;
  id: number;
  index?: number;
  organization_id: number;
  updated_at: Date;
  user_id: number;
}

export interface IUsersPracticeArea {
  created_at: Date;
  index?: number;
  practice_area_id: number;
  updated_at: Date;
  user_id: number;
}

export interface IUsersProfessionalRole {
  created_at: Date;
  index?: number;
  professional_role_id: number;
  updated_at: Date;
  user_id: number;
}

export const ArchiveReason: {
  ARCHIVED: any;
  COMMUNITY_CLOSED: any;
  EMAIL_BOUNCED: any;
} = {
  ARCHIVED: 'ARCHIVED',
  COMMUNITY_CLOSED: 'COMMUNITY_CLOSED',
  EMAIL_BOUNCED: 'EMAIL_BOUNCED'
};

export type ArchiveReason = (typeof ArchiveReason)[keyof typeof ArchiveReason];

export const EmailTypes: {
  NEW_PUBLICATION: any;
  NEW_REPLY: any;
  NEW_DISCUSSION: any;
  NEW_USER: any;
} = {
  NEW_PUBLICATION: 'NEW_PUBLICATION',
  NEW_REPLY: 'NEW_REPLY',
  NEW_DISCUSSION: 'NEW_DISCUSSION',
  NEW_USER: 'NEW_USER'
};

export type EmailTypes = (typeof EmailTypes)[keyof typeof EmailTypes];

export const FeedTypes: {
  NEWS: any;
  RESOURCES: any;
  JOURNAL: any;
} = {
  NEWS: 'NEWS',
  RESOURCES: 'RESOURCES',
  JOURNAL: 'JOURNAL'
};

export type FeedTypes = (typeof FeedTypes)[keyof typeof FeedTypes];

export type INewArchivedUser = Omit<IArchivedUser, 'id' | 'created_at' | 'updated_at'>;
export type INewArticle = Omit<IArticle, 'id' | 'created_at' | 'updated_at'>;
export type INewCommunity = Omit<ICommunity, 'id' | 'created_at' | 'updated_at'>;
export type INewCommunityDigest = Omit<ICommunityDigest, 'id' | 'created_at' | 'updated_at'>;
export type INewCommunityFeed = Omit<ICommunityFeed, 'id' | 'created_at' | 'updated_at'>;
export type INewCountry = Omit<ICountry, 'id' | 'created_at' | 'updated_at'>;
export type INewDigestContentOverride = Omit<IDigestContentOverride, 'id' | 'created_at' | 'updated_at'>;
export type INewDiscussionAttachment = Omit<IDiscussionAttachment, 'id' | 'created_at' | 'updated_at'>;
export type INewDiscussionReply = Omit<IDiscussionReply, 'id' | 'created_at' | 'updated_at'>;
export type INewDiscussionReplyAttachment = Omit<IDiscussionReplyAttachment, 'id' | 'created_at' | 'updated_at'>;
export type INewDiscussion = Omit<IDiscussion, 'id' | 'created_at' | 'updated_at'>;
export type INewFeed = Omit<IFeed, 'id' | 'created_at' | 'updated_at'>;
export type INewFile = Omit<IFile, 'id' | 'created_at' | 'updated_at'>;
export type INewJobTitle = Omit<IJobTitle, 'id' | 'created_at' | 'updated_at'>;
export type INewLinkedAuthor = Omit<ILinkedAuthor, 'id' | 'created_at' | 'updated_at'>;
export type INewOrganization = Omit<IOrganization, 'id' | 'created_at' | 'updated_at'>;
export type INewPendingEmail = Omit<IPendingEmail, 'id' | 'created_at' | 'updated_at'>;
export type INewPendingMembersPublication = Omit<IPendingMembersPublication, 'id' | 'created_at' | 'updated_at'>;
export type INewPracticeArea = Omit<IPracticeArea, 'id' | 'created_at' | 'updated_at'>;
export type INewProfessionalRole = Omit<IProfessionalRole, 'id' | 'created_at' | 'updated_at'>;
export type INewRejectedGuid = Omit<IRejectedGuid, 'id' | 'created_at' | 'updated_at'>;
export type INewSuppressedArticle = Omit<ISuppressedArticle, 'id' | 'created_at' | 'updated_at'>;
export type INewUser = Omit<IUser, 'id' | 'created_at' | 'updated_at'>;
export type INewUsersCommunity = Omit<IUsersCommunity, 'id' | 'created_at' | 'updated_at'>;
export type INewUsersFeed = Omit<IUsersFeed, 'id' | 'created_at' | 'updated_at'>;
export type INewUsersOrganization = Omit<IUsersOrganization, 'id' | 'created_at' | 'updated_at'>;
export type INewUsersPracticeArea = Omit<IUsersPracticeArea, 'id' | 'created_at' | 'updated_at'>;
export type INewUsersProfessionalRole = Omit<IUsersProfessionalRole, 'id' | 'created_at' | 'updated_at'>;

