import { Trans, useTranslation } from 'react-i18next';

import ErrorPage from './ErrorPage';
import NotFoundGraphic from 'assets/graphics/page-not-found.png';
import { StyledLink } from 'styles/styled-components';

export function PageNotFound(): JSX.Element {
  const { t } = useTranslation();

  return (
    <ErrorPage imageSrc={NotFoundGraphic}>
      <h2>{t('Oops! Page not found.')}</h2>
      <p>
        <Trans>This page does not exist or was removed.</Trans>
      </p>
      <p>
        <Trans>We suggest you go back to <StyledLink to="/">home.</StyledLink></Trans>
      </p>
    </ErrorPage>
  );
}
