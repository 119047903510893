import 'rc-tabs/assets/index.css';
import { transparentize } from 'polished';
import Tabs, { TabPane } from 'rc-tabs';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import theme, { device } from 'styles/theme';

interface ITab {
  component: JSX.Element;
  label: string;
}

interface ITabNavigator {
  tabs: ITab[];
  className?: string;
  enableRouter?: never;
}

interface IRouterTabNavigator {
  tabs: (ITab & { path: string, q?: string })[];
  className?: string;
  enableRouter: true;
}

function TabNavigator({ tabs, enableRouter, className }: ITabNavigator | IRouterTabNavigator): JSX.Element {
  const { pathname } = useLocation();
  const history = useHistory();
  const [activeKey, setActiveKey] = useState('0');

  useEffect(() => {
    if (!enableRouter) return;

    const index = tabs.findIndex((tab) => tab.path === pathname);
    if (index === -1) throw new Error('Missing tab');
    setActiveKey(index.toString());
  }, [enableRouter, pathname, tabs]);

  function onChange(key: string): void {
    const searchParams = new URLSearchParams();
    if (!enableRouter) {
      setActiveKey(key);
      return;
    }
    if (tabs[Number(key)].q) {
      searchParams.set('q', tabs[Number(key)].q || '');
    }
    const newPath = tabs[Number(key)].path;
    if (!newPath) throw new Error('Missing Path');
    history.push({
      pathname: tabs[Number(key)].path,
      search: searchParams.toString(),
    });
  }

  const tabPanes = tabs.map((tab, index) => <TabPane forceRender key={index.toString()} tab={tab.label}>{tab.component}</TabPane>);

  return (
    <StyledTabs className={className} activeKey={activeKey} defaultActiveKey='0' onChange={(k) => onChange(k)}>
      {tabPanes}
    </StyledTabs>
  );
}
export default TabNavigator;

const StyledTabs = styled(Tabs)`
  border: none;
  overflow: visible; /* allows box-shadow effects to overflow */

  .rc-tabs-nav {
    font-size: initial;
    height: 38px;

    /*
      Using a linear gradient instead of border so that we could offset it by 3px
      https://stackoverflow.com/questions/52826280/add-offset-to-border-bottom-in-css
    */
    background:
      linear-gradient(
        ${transparentize(0.9, theme.black)},
        ${transparentize(0.9, theme.black)}
      ) bottom/100% 3px no-repeat;
    margin-bottom: 65px;

    .rc-tabs-ink-bar {
      background: ${theme.light};
    }
  }

  .rc-tabs-tab {
    font-weight: 400;
    font-size: 18px;
    margin-right: 165px;
    background: transparent;
    color: black;

    .rc-tabs-tab-btn {
      line-height: normal;
      align-self: baseline;
    }
  }

  .rc-tabs-nav-more,
  .rc-tabs-nav-wrap-ping-left::before,
  .rc-tabs-nav-wrap-ping-right::after {
    display: none;
  }

  ${device.tablet} {
    .rc-tabs-tab {
      margin-right: 135px;
    }
  }

  ${device.mobile} {
    margin-top: -40px;

    .rc-tabs-tab {
      margin-right: 40px;
      font-size: 16px;
    }
  }
`;
