import { useTranslation } from 'react-i18next';

import FeedsPage from './FeedsPage';
import FollowCommunitiesPage from 'communities/FollowCommunitiesPage';
import TabNavigator from 'components/TabNavigator';

export default function CustomizeFeeds(): JSX.Element {
  const { t } = useTranslation();

  const tabs = [
    {
      label: t('My journal sources'),
      path: '/feeds',
      component: <FeedsPage />,
    },
    {
      label: t('My communities'),
      path: '/follow',
      component: <FollowCommunitiesPage />,
    },
  ];

  return (
    <TabNavigator tabs={tabs} enableRouter />
  );
}
