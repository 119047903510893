import { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share';
import styled from 'styled-components';

import CopyIcon from 'assets/icons/copy-to-clipboard.svg';
import Exit from 'assets/icons/x.svg';

interface IShareModal {
  title: string;
  url: string;
  isOpen: boolean;
  setIsOpen(value: boolean): void;
}

export default function ShareModal({
  title, url, isOpen, setIsOpen,
}: IShareModal): JSX.Element {
  const { t } = useTranslation();

  const trackPageView = (): void => {
    ReactGA.send({
      hitType: 'pageview',
      page: url,
      title,
    });
  };
  function closeModal(): void {
    setIsOpen(false);
  }

  if (isOpen) {
    trackPageView();
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      ariaHideApp={false}
      style={modalStyles}
    >
      <Container>
        <img className='exit' src={Exit} onClick={closeModal} />
        <h4>{t('Share this article')}</h4>
        <p>{t('If you like this article share it with your friends.')}</p>
        <div className='share-buttons'>
          <FacebookShareButton url={url} quote={title} className='button' title={t('Share on Facebook')}>
            <FacebookIcon size={40} round />
          </FacebookShareButton>
          <TwitterShareButton url={url} title={title} className='button'>
            <TwitterIcon size={40} round />
          </TwitterShareButton>
          <LinkedinShareButton url={url} title={title} className='button'>
            <LinkedinIcon size={40} round />
          </LinkedinShareButton>
          <EmailShareButton url={url} subject={title} className='button' title={t('Share on Email')}>
            <EmailIcon size={40} round />
          </EmailShareButton>
        </div>
        <div className="clipboard">
          <input type="text" defaultValue={url} />
          <CopyToClipboard text={url}>
            <img src={CopyIcon} title={t('Copy to clipboard')} alt={t('Copy to clipboard')} />
          </CopyToClipboard>
        </div>
      </Container>
    </Modal>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .exit {
    height: 11px;
    width: 11px;
    align-self: flex-end;

    &:hover {
      cursor: pointer;
    }
  }

  h4 {
    font-weight: 500;
    text-transform: none;
    margin: 14px 0;
    font-family: Inter, serif;
  }

  p {
    color: #888;
    font-family: Roboto, serif;
  }

  .share-buttons {
    height: 40px;
    margin: 31px 0;

    .button {
      height: 28px;
      margin: 0 7.5px;
    }
  }

  .clipboard {
    width: 100%;
    height: 48px;
    border: 1px solid rgb(52 67 75 / 15%);
    border-radius: 4px;
    display: flex;
    align-items: center;

    input {
      color: #8D9CA5;
      border: none;
      height: 90%;
      font-size: 12px;
      font-family: Roboto, serif;
    }

    img {
      width: 16px;
      height: 16px;
      margin: 0 18px;

      &:hover {
        cursor: pointer;
      }
    }
  }
`;

const modalStyles = {
    overlay: {
      backgroundColor: 'none',
    },

    content: {
      width: '80%',
      maxWidth: '464px',
      height: '291px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      border: 'none',
      borderRadius: '4px',
      boxShadow: '0 14px 54px rgb(112 112 112 / 35%)',
    },
};
