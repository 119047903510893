import { When } from 'react-if';
import styled from 'styled-components';

import { device } from 'styles/theme';

interface IErrorPage {
  children: JSX.Element | JSX.Element[];
  imageSrc?: string;
  className?: string;
}

function ErrorPage({ children, imageSrc, className }: IErrorPage): JSX.Element {
  return (
    <Container className={className}>
      <div className='left-side'>
        {children}
      </div>
      <div className='right-side'>
        <When condition={!!imageSrc}>
          <img src={imageSrc} />
        </When>
      </div>
    </Container>
  );
}
export default ErrorPage;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-height: 300px;
  border-top: 3px solid #F0F6FF;
  padding-top: 112px;

  h2 {
    margin-bottom: 17px;
    font-size: 25px;
    font-weight: 700;
  }

  .right-side {
    width: 385px;

    img {
      width: 100%;
    }
  }

  .left-side {
    align-self: flex-end;
    margin-bottom: 80px;
  }

  ${device.tablet} {
    border-top: none;

    img {
      width: 300px;
    }
  }

  ${device.mobile} {
    display: flex;
    flex-direction: column;
    padding: 126px 0 204px;

    .left-side {
      align-self: initial;
      margin-bottom: 76px;
    }

    img {
      margin-bottom: 20px;
    }
  }
`;
