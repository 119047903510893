import { ReactNode } from 'react';

import { IArticleDto } from 'api/dtos/article.dto';
import ArticleCard from 'articles/ArticleCard';
import { ListContainer } from 'components/Discussions';
import PaginatedScroll from 'components/PaginatedScroll';

interface IArticleList {
  url: string,
  emptyTabHeader: string,
  emptyTabMessage?: string | JSX.Element
}

export default function ArticleList({ url, emptyTabHeader, emptyTabMessage }: IArticleList): JSX.Element {
  function articleItemList(articles: IArticleDto[]): ReactNode {
    return articles.map((a) => <ArticleCard key={a.id} article={a} />);
  }

  return (
    <ListContainer style={container}>
      <PaginatedScroll
        url={url}
        emptyTabHeader={emptyTabHeader}
        emptyTabMessage={emptyTabMessage}
      >
        {(data: any) => articleItemList(data)}
      </PaginatedScroll>
    </ListContainer>
  );
}

const container = {
    width: '100%',
};
