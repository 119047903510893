import styled from 'styled-components';

import theme, { device } from 'styles/theme';

const Card = styled.div`
  background-color: white;
  box-shadow: 0 3px 15px #E6E6E6;
  width: 85%;
  border-radius: 10px;
  padding: 34px 46px;
  text-decoration: none;
  color: ${theme.text};

  h4 {
    font-size: 18px;
    font-weight: 900;
    color: #000;
    text-transform: none;
    margin: 0;
  }

  .bottom {
    p {
      font-size: 14px;
    }
  }

  ${device.tablet} {
    padding: 34px 25px;
    width: 100%;
  }
`;

export default Card;
