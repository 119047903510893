import { Trans, useTranslation } from 'react-i18next';

import ResourceItem from './ResourceItem';
import { IArticleDto } from 'api/dtos/article.dto';
import PaginatedScroll from 'components/PaginatedScroll';
import { StyledLink } from 'styles/styled-components';

interface IResources {
  communityId?: number;
}

export default function Resources({ communityId }: IResources): JSX.Element {
  const { t } = useTranslation();

  return (
    <PaginatedScroll<IArticleDto>
      url={communityId ? `/api/communities/${communityId}/resources` : '/api/communities/resources'}
      emptyTabHeader={t('There are no resources currently available to you.')}
      emptyTabMessage={(
        <Trans>
          <p>You may not have access to the resources because you are not a community member
            {' '}<StyledLink to='/follow'>(view and change your communities here).</StyledLink>
            {' '}Or, there may be no resources posted in your communities.
          </p>
        </Trans>
      )}
    >
      {(resources) => resources.map((r) => <ResourceItem key={r.id} article={r} />)}
    </PaginatedScroll>
  );
}
