import { useTranslation } from 'react-i18next';
import { When } from 'react-if';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import FollowButton from './FollowButton';
import { ICommunity } from 'api/tables';
import { sanitizeText } from 'api/utils';
import CommunityLogo from 'components/CommunityLogo';
import LinkifyInNewTab from 'components/LinkifyInNewTab';
import Card from 'styles/styled-components/Card';
import { device } from 'styles/theme';

export default function CommunityCard({ community }: { community: ICommunity }): JSX.Element {
  const { t } = useTranslation();

  return (
    <Container>
      <div className='community-logo'>
        <Link to={`/communities/${community.slug}`}>
          <CommunityLogo id={community.id} logoUrl={community.logo_url || ''} name={community.name} />
        </Link>
      </div>
      <When condition={community.description}>
        <p>
          <LinkifyInNewTab>
            {community.description ? sanitizeText(community.description) : ''}
          </LinkifyInNewTab>
          <Link to={`/communities/${community.slug}`}>{` ${t('View')} »`}</Link>
        </p>
      </When>
      <FollowButton community={community} className='sources-follow-button' />
    </Container>
  );
}

const Container = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
  line-height: 30px;

  p {
    color: black;
    margin: 0 20px;
    font-size: 16px;
    text-transform: none;

    a {
      display: inline-block;
      color: #0038FF;
    }
  }

  & > div:first-of-type {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 150px;

    img {
      max-width: 150px;
    }
  }

  ${device.tablet} {
    flex-direction: column;
    justify-content: center;

    img {
      margin-bottom: 25px;
      max-width: 180px;
    }

    p {
      margin: 0;
    }

    .follow-button {
      margin-top: 25px;
      width: 100%;
    }

    .member {
      margin-top: 15px;

      .text {
        bottom: 18px;
      }

      img {
        position: relative;
        top: 10px;
      }
    }
  }

  ${device.mobile} {
    padding: 50px 36px;

    & > div:first-of-type {
      min-width: 172px;

      img {
        max-width: 172px;
      }
    }
  }
`;
