import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import theme from 'styles/theme';

const Anchor = css`
  color: ${theme.blue};
  text-decoration: none;
  font-weight: 400;

  &:hover {
    color: ${theme.darker};
  }
`;

const StyledLink = styled(Link)`
  ${Anchor}
`;

export { StyledLink };

export default Anchor;
