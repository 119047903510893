import axios from 'axios';
import { DetectorOptions } from 'i18next-browser-languagedetector';

import { IAsyncCustomDetector } from '../asyncBrowserLanguageDetector';
import { IUser } from 'api/tables';

const userDetector: IAsyncCustomDetector = {
  name: 'user',
  lookup(options: DetectorOptions): string | string[] | undefined {
    throw new Error('Function not implemented.');
  },
  async lookupAsync(options: DetectorOptions) {
    try {
      const response = await axios.get<IUser>('/api/auth/me', {
        validateStatus: (status) => (status >= 200 && status < 300) || status === 403,
      });
      if (response.status === 403) return;

      const userLanguage = response.data.lang;
      return userLanguage || undefined;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  },
};
export default userDetector;
