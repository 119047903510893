import styled from 'styled-components';

import FeedToggle from './FeedToggle';
import { IFeed } from 'api/tables';
import { device } from 'styles/theme';

export default function FeedToggleList({ feeds }: { feeds: IFeed[] }): JSX.Element {
  const feedToggles = feeds.map((feed, index) => <FeedToggle key={index} className='feed' feed={feed} />);

  return (
    <Container>
      {feedToggles}
    </Container>
  );
}
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .feed {
    margin-bottom: 24px;
    width: 45%;
  }

  ${device.mobile} {
    flex-direction: column;
    align-items: flex-start;

    .feed {
      width: 100%;
    }
  }
`;
