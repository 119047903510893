import { createContext, useContext } from 'react';

import { IUserState } from './interfaces/IUserState';
import { UserAction } from './userContextReducer';

export interface IUserContext {
  userState: IUserState;
  userStateDispatch: React.Dispatch<UserAction>;
}

export const GlobalUserContext = createContext<IUserContext>({
  userState: {
    user: undefined,
    communities: [],
  },
  userStateDispatch: () => {

  },
});

export function useGlobalUserContext(): IUserContext {
  return useContext(GlobalUserContext);
}

type LoggedUserState = Required<IUserState>;

export function useLoggedInUser(): LoggedUserState {
  const { userState } = useGlobalUserContext();
  const { user, communities } = userState;

  if (!user) throw new Error('Missing user or community data');

  return { user, communities };
}
