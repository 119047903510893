import styled from 'styled-components';

import LoginImage from 'assets/graphics/login-page-image.svg';
import { device } from 'styles/theme';

export interface ISignIn {
  children: JSX.Element | JSX.Element[];
}

export default function SignInTemplate({ children }: ISignIn): JSX.Element {
  return (
    <Container>
      <div className="image-container">
        <img src={LoginImage} />
      </div>
      <div className="vl" />
      <div className="children">
        {children}
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;

  .image-container {
    width: 383px;
    height: 234px;
  }

  .vl {
    height: 466px;
    border: 1.25px solid #F0F6FF;
    background-color: #F0F6FF;
  }

  .children {
    width: 464px;
  }

  ${device.tablet} {
    flex-direction: column;
    align-items: center;

    .image-container {
      margin-bottom: 100px;
    }

    .vl {
      display: none;
    }
  }

  ${device.mobile} {
    margin-bottom: 90px;

    .image-container,
    .children {
      width: 100%;
    }

    .image-container {
      display: flex;
      justify-content: center;
    }
  }
`;
