import styled from 'styled-components';

import Article from './Article';
import { IArticleDto } from 'api/dtos/article.dto';
import { device } from 'styles/theme';

export default function ArticleTemplate({ article, children }: { article: IArticleDto | undefined | null, children: JSX.Element }): JSX.Element {
  return (
    <Container>
      <div className="children">
        {children}
      </div>
      <div className="article-container">
        <Article article={article} showPreview={false} />
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  margin-top: 50px;

  .article-container {
    width: 647px;
    border-radius: 3px;

    h4 {
      text-transform: none;
    }
  }

  .children {
    width: 364px;
  }

  ${device.tablet} {
    flex-direction: column-reverse;
    align-items: center;
  }

  ${device.mobile} {
    margin-bottom: 90px;

    .article-container,
    .children {
      width: 100%;
    }

    .article-container {
      display: flex;
      justify-content: center;
    }
  }
`;
