import { ErrorMessage } from '@hookform/error-message';
import { Control, Controller } from 'react-hook-form';
import Select, { Options, createFilter } from 'react-select';

import createStyledSelect from './createStyledSelect';
import CustomOption from './CustomOption';
import { FormError } from 'styles/styled-components/FormError';

export interface IOption {
  value: string | number; label: string;
}

interface ISelect {
  control: Control<any>;
  options: Options<IOption>;
  name: string;
  placeholder?: string;
  color?: string;
  defaultValue?: IOption;
  noOptionsMessage?: string;
}

const prefix = 'controlled-select';

function ControlledSelect({
  control, options, name, placeholder, color, defaultValue, noOptionsMessage,
}: ISelect): JSX.Element {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange }, formState }) => (
        <>
          <StyledSelect
            id={name}
            classNamePrefix={prefix}
            noOptionsMessage={() => noOptionsMessage ?? 'No options'}
            onChange={(newValue: any) => onChange(newValue?.value)}
            value={options.find((opt) => opt.value === value)}
            placeholder={<span style={{ color }}>{placeholder}</span>}
            filterOption={createFilter({ ignoreAccents: false })}
          // eslint-disable-next-line @typescript-eslint/naming-convention
            components={{ Option: CustomOption }}
            options={options}
            defaultValue={defaultValue}
            isLoading={false}
          />
          <ErrorMessage errors={formState.errors} name={name} render={FormError} />
        </>

      )}
    />
  );
}

const StyledSelect = createStyledSelect(Select, prefix);

export default ControlledSelect;
