import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import SearchArticlesPage from 'articles/SearchArticlesPage';
import TabNavigator from 'components/TabNavigator';
import SearchDiscussionPage from 'discussions/SearchDiscussionPage';

export default function SearchTabs(): JSX.Element {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const q = searchParams.get('q') || '';
  const tabs = [
    {
      label: 'Articles',
      path: '/articles/search',
      component: <SearchArticlesPage />,
      q,
    },
    {
      label: 'Q&A',
      path: '/discussions/search',
      component: <SearchDiscussionPage />,
      q,
    },
  ];

  return (
    <Container>
      <TabNavigator tabs={tabs} enableRouter />
    </Container>
  );
}

const Container = styled.div`
  border-top: 3px solid #F0F6FF;
  padding-top: 74px;

  .rc-tabs-nav {
    font-size: initial;
    height: 38px;
    background: none;
    margin-bottom: 65px;

    .rc-tabs-tab-active {
      color: #0038FF;
    }
  }

  .rc-tabs-tab {
    font-weight: 700;
    font-size: 16px;
    margin-right: 31px;
    background: transparent;
    color: black;
  }
`;
