import styled from 'styled-components';

import theme, { device } from 'styles/theme';

export const EditProfileForm = styled.form`
  margin: 0 0 55px;
  overflow-x: hidden;

  h3 {
    margin-top: 60px;
    margin-bottom: 40px;
    font-size: 21px;
    font-weight: 700;
    font-family: 'Liberation Sans', Inter, sans-serif;
  }

  .user-avatar {
    display: flex;
    align-items: center;
    margin: 0 0 49px;

    button {
      margin: 0;
      font-size: 18px;
      text-transform: none;
      background: #FFF;
      color: #000;
    }

    .btn-group {
      display: flex;
      margin-top: 4px;
      flex-direction: row;
      font-size: 18px;
      gap: 10px;

      .upload {
        border-radius: 10px;
        padding: 10px 26px;
        border: 1px solid ${theme.primary};
        margin: 0 23px;
        cursor: pointer;
      }

      .remove {
        margin: auto -9px;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .guidance-text {
      padding: 10px 26px;
      color: ${theme.gray};
    }
  }

  .top {
    display: flex;
    flex-wrap: wrap;
    column-gap: 42px;

    & > div {
      width: 415px;
      margin-bottom: 28px;
    }

    & > div:nth-of-type(4) {
      margin-bottom: 28px;
    }

    & > div:nth-of-type(5),
    & > div:nth-of-type(6) {
      margin-bottom: 58px;
    }

    & > div:nth-of-type(7) {
      margin-bottom: 29px;
    }

    label {
      margin-bottom: 10px;
      font-size: 18px;
      color: black;
      font-weight: 400;
    }

    .checkbox {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 0;

      label {
        margin: 0 0 0 10px;
        font-size: 16px;
      }
    }
  }

  .mid {
    display: flex;
    position: relative;

    & > div:nth-of-type(1),
    & > div:nth-of-type(2),
    & > div:nth-of-type(3) {
      width: 942px;
      margin-bottom: 60px;
      padding-right: 60px;

      .info {
        font-size: 13.5px;
        margin-top: -38px;
        margin-left: 469px;
        width: 100%;
        color: ${theme.text} !important;

        @media only screen and (min-width: 1120px) and (max-width: 1285px) {
          margin-left: 0;
          margin-top: -9px;
        }

        @media only screen and (max-width: 1007px) {
          margin-left: 0;
          margin-top: -10px;
        }

        ${device.mobile} {
          margin-left: 0;
          margin-top: -12.5px;
        }
      }
    }

    & > div:nth-of-type(4) {
      width: 872px;
    }

    .controlled-select__control { /* stylelint-disable-line selector-class-pattern */
      border: 0.5px solid #A4A4A4 !important;
    }

    button {
      padding: 0;
      margin: 0;
      font-size: 14px;
      text-transform: none;
      text-decoration: underline;
      background: #FFF;
      color: #0038FF;
      position: relative;
    }

    button.add-affiliation {
      bottom: 20px;
      box-shadow: none;
    }

    button.add-professional-role,
    button.add-practice-area {
      bottom: 15px;
      box-shadow: none;
    }

    .job-form {
      display: flex;
      flex-wrap: wrap;
      column-gap: 57px;
      margin-bottom: 13px;

      .job-title {
        width: 410px;
        margin-bottom: 15px;
        grid-column: 1 / 2;
      }

      .input-group {
        width: 415px;
      }

      .job-org {
        width: 415px;
        grid-column: 1 / 2;
      }
    }

    .space {
      height: 20px;
    }

    .org-form {
      margin-bottom: 8.5px;
    }

    .role-form,
    .area-form {
      margin-bottom: 12px;
    }

    .remove-job,
    .remove-role,
    .remove-area {
      font-size: 14px;
      background: #FFF;
      color: #F00;
      text-decoration: underline;
      cursor: pointer;
      box-shadow: none;
    }

    .remove-job {
      left: 441px;
      bottom: 36px;

      @media only screen and (min-width: 600px) and (max-width: 997px) {
        position: relative;
        margin-top: 25px;
        left: 0;
        bottom: 15px;
      }
    }

    .remove-role {
      left: 102.9%;
      bottom: 36px;

      @media only screen and (min-width: 600px) and (max-width: 997px) {
        position: static;
        margin-top: 15px;
        margin-bottom: 12px;
      }
    }

    .remove-area {
      left: 102.9%;
      bottom: 36px;

      @media only screen and (min-width: 600px) and (max-width: 997px) {
        position: static;
        margin-top: 13px;
        margin-bottom: 12px;
      }
    }
  }

  div.hl {
    margin: 42px 0;
    border: 2.5px solid #F0F6FF;
  }

  .bottom {
    width: 435px;

    h3 {
      margin-bottom: 40px;
      font-size: 24px;
      font-weight: 700;
    }

    button {
      margin: 25px 0 69px;
    }
  }

  .change-communities {
    text-decoration: underline;
    color: #0038FF;
    position: relative;
    bottom: 18px;
  }

  ${device.mobile} {
    margin: -30px 0 55px;
    overflow-x: hidden;
    width: 104%;

    input {
      width: 88.5%;
      overflow-x: hidden;
    }

    .user-avatar {
      display: grid;
      grid-template-columns: 86px auto;
      grid-gap: 22px 0;
      margin-bottom: 30px;

      .avatar {
        grid-row: 1 / 3;
      }

      .btn-group {
        position: relative;
        flex-direction: column;
        gap: 18px;
        top: 18px;

        .upload {
          width: calc(70vw - 80px);
          text-align: center;
          margin: 0 19px;
        }

        .remove {
          margin-left: 18px;
          width: 70px;
        }
      }
    }

    .top {
      flex-direction: column;

      & > div {
        width: 109.5%;
        margin-bottom: 18px;
      }

      & > div:first-of-type {
        order: -3;
      }

      & > div:nth-of-type(2) {
        margin-top: 0;
        order: -1;
      }

      & > div:nth-of-type(3) {
        margin-bottom: 17px;
        order: -2;
      }

      & > div:nth-of-type(4) {
        margin-bottom: 17px;
        width: 97%;
      }

      & > div:nth-of-type(5) {
        margin-bottom: 15px;
      }

      & > div:last-of-type {
        margin-bottom: 23px;
        width: 97%;
      }
    }

    div.hl {
      display: none;
    }

    .bottom {
      width: 100%;

      & > div {
        width: 97%;
        margin-bottom: 18px;
      }

      h3 {
        margin-bottom: 42px;
      }

      button {
        width: 97%;
        margin: 65px 0 72px;
      }
    }

    .mid {
      p,
      input,
      .role-form,
      .area-form,
      .job-org {
        width: calc(100vw - 61px) !important;
      }

      & > div {
        margin-bottom: 18px;
      }

      & > div:nth-of-type(1) {
        margin-bottom: 60px;
      }

      & > div:nth-of-type(2) {
        margin-bottom: 60px;
        order: revert;
      }

      & > div:nth-of-type(3) {
        margin-bottom: 60px;
        order: revert;
      }

      & > div:nth-of-type(4) {
        order: revert;
      }

      .job-form {
        display: grid;
        grid-template-columns: 86px auto;
        grid-gap: 22px 20px;

        .job-title {
          margin-bottom: -10px;
        }

        .job-org {
          width: calc(100vw - 61px);
        }
      }

      button.add-affiliation {
        bottom: 22px;
      }

      .remove-job,
      .remove-role,
      .remove-area {
        position: static;
        margin-top: 11px;
        padding-bottom: 2px;
        flex-direction: row;
        font-size: 14px;
        background: #FFF;
        color: #F00;
        text-decoration: underline;
        cursor: pointer;
      }

      .remove-job {
        margin-bottom: 15px;
      }

      .remove-role,
      .remove-area {
        margin-bottom: 9px;
      }
    }
  }
`;
