import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { useNavBarContext } from './NavBarContext';
import { ICommunity } from 'api/tables';
import CommunityLogo from 'components/CommunityLogo';
import { device } from 'styles/theme';

interface ICommunityLink {
  community: ICommunity;
}

function CommunityLink({ community }: ICommunityLink): JSX.Element {
  const { isMobileMenuOpen, setMobileMenuOpen } = useNavBarContext();
  const link = `/communities/${community.slug}`;

  function onClick(): void {
    if (isMobileMenuOpen) setMobileMenuOpen(false);
  }

  return (
    <StyledLink to={link} onClick={onClick} activeClassName='is-active'>
      <CommunityLogo id={community.id} logoUrl={community.logo_url || ''} name={community.name} className='community-link' />
    </StyledLink>
  );
}

export default CommunityLink;

const StyledLink = styled(NavLink)`
  display: block;
  padding: 15px 0;
  height: 80px;

  img {
    max-height: 50px;
    justify-content: left;
    padding-left: 35px;
    display: block;
    object-fit: contain;
    width: 77%;
  }

  &:hover {
    background-color: white;
  }

  &.is-active {
    background-color: white;
  }

  @media only screen and (max-height: 590px) {
    height: 55px;

    img {
      max-height: 38px;
    }
  }

  ${device.tablet} {
    img {
      margin: 0 auto;
      padding-left: 0;
    }

    &.is-active,
    &:hover {
      background-color: transparent;
    }
  }
`;
