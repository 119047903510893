import { IUserDto } from './dtos/user.dto';
import { IUserCommunityDto } from './dtos/userCommunity.dto';
import { ICountry, IUser } from './tables';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';

interface IUserWithCountry extends IUser {
  country?: ICountry | string | null;
}

export function getDisplayName(user: IUserDto | IUserWithCountry, includeMiddle = true): string {
  if(!user){
    return "User";
  }
  const { first_name, middle_name, last_name } = user;
  const nameDetails: string[] = [];

  if (first_name) nameDetails.push(first_name);
  if (middle_name && includeMiddle) nameDetails.push(middle_name);
  if (last_name) nameDetails.push(last_name);

  return nameDetails.join(' ');
}

export function getDisplayLocation(user: IUserDto | IUserWithCountry): string {
  const { city, prov_state, country } = user;
  const locationDetails: string[] = [];

  if (city) locationDetails.push(city);
  if (prov_state) locationDetails.push(prov_state);
  if (country) locationDetails.push(typeof country === 'string' ? country : country.name);

  return locationDetails.join(', ');
}

export function isMember(community: IUserCommunityDto): boolean {
  return community.user.relationship === 'MEMBER';
}

export function isFollower(community: IUserCommunityDto): boolean {
  return community.user.relationship === 'FOLLOWER';
}

export function sanitizeText(title: string): JSX.Element[] | JSX.Element | string {
  const sanitized = DOMPurify.sanitize(title, { ADD_TAGS: ['b', 'br'] })
  return parse(cleanTitle(sanitized));
}

const pubMedArtifact = /;[\d\-():]+/gmi
const doiArtifact = /([0-9]):(?![0-9]{1,2}(am|pm|AM|PM)?)[A-z0-9\-()]+/gmi

export function cleanTitle(title: string): string {
  return title.replace(pubMedArtifact, '.')
    .replace(/https?:\/\/blog\.sosido\.com\/about\/our-partners\//gmi, '/follow')
    .replace(/&[a-z]+;/g, '')
    .replace(/\/ /g, '') // don't allow '/' with a space before it
    .replace(doiArtifact, '');
}
