import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';
import {
  ForwardedRef, HTMLInputTypeAttribute, forwardRef, useState,
} from 'react';
import {
  Control, UseFormRegisterReturn, useFormState,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { If, Then, When } from 'react-if';
import styled from 'styled-components';

import EyeIcon from 'assets/icons/eye.svg';
import { FormError } from 'styles/styled-components/FormError';
import theme from 'styles/theme';

interface IInput extends UseFormRegisterReturn {
  id?: string;
  label?: string;
  placeholder?: string;
  className?: string;
  type: HTMLInputTypeAttribute;
  control?: Control<any>;
  maxLength?: number;
}

function Input({
  className, label, name, control, maxLength, ...attr
}: IInput, ref: ForwardedRef<HTMLInputElement>): JSX.Element {
  const { errors } = useFormState({ control });
  const { t } = useTranslation();
  const [count, setCount] = useState(0);
  const { disabled } = attr;

  const [showPassword, setShowPassword] = useState(attr.type !== 'password');
  const togglePasswordVisibility = (): void => {
    setShowPassword(!showPassword);
  };

  const computeType = (): HTMLInputTypeAttribute => {
    if (showPassword) {
      return attr.type === 'password' ? 'text' : attr.type;
    }

    return 'password';
  };

  return (
    <Container className={className}>
      <div className='input-group'>
        <When condition={label}>
          <label>{label}</label>
        </When>
        <input
          id={disabled ? className : name}
          className={classNames({ invalid: !!errors[name] })}
          name={name}
          {...attr}
          type={computeType()}
          placeholder={attr.placeholder}
          ref={ref}
          maxLength={maxLength}
          onChange={(e) => setCount(e.target.value.length)}
        />
        <When condition={attr.type === 'password'}>
          <i onClick={togglePasswordVisibility}><img src={EyeIcon} style={{ paddingTop: '4px' }} /></i> {' '}
        </When>
      </div>
      <ErrorMessage errors={errors} name={name} render={FormError} />
      <If condition={!!maxLength}>
        <Then>
          <div className='character-counter'>
            {`${t('Characters remaining')}: `} <span>{maxLength as number - count}</span>
          </div>
        </Then>
      </If>
    </Container>
  );
}

export default forwardRef(Input);

const Container = styled.div`
  label {
    text-transform: none;
  }

  input {
    border-radius: 10px;
    border: 0.5px solid #A4A4A4;
  }

  .character-counter {
    margin-top: 6px;
  }

  span {
    font-size: 14px;
    font-weight: 700;
  }

  .input-group {
    position: relative;
  }

  i {
    position: absolute;
    top: 50%;
    right: 20px;

    img {
      width: 1.125em;
    }

    img:hover {
      /* Impossible to directly use fill: $theme.light while using
         an svg as a source for an img, this tries to emulate it */
      filter: invert(22%) sepia(66%) saturate(6545%) hue-rotate(230deg) brightness(97%) contrast(102%);
    }
  }

  i:hover {
    color: ${theme.light};
    cursor: pointer;
  }
`;
