import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import CommunityLink from './CommunityLink';
import { IUserCommunityDto } from 'api/dtos/userCommunity.dto';
import { ICommunity, IUsersCommunity } from 'api/tables';
import { useLoggedInUser } from 'contexts/user-context/UserContext';
import theme from 'styles/theme';

interface IMyCommunities {
  className?: string;
  setMobileMenuOpen(isMobileMenuOpen: boolean): void;
}

function sortUserCommunities(userCommunities: IUserCommunityDto[]): IUserCommunityDto[] {
  userCommunities.sort((a, b) => new Date(a.user.created_at).getTime() - new Date(b.user.created_at).getTime());

  userCommunities.sort((a, b) => {
    if (a.user.relationship === 'MEMBER' && b.user.relationship !== 'MEMBER') {
      return -1;
    } if (a.user.relationship !== 'MEMBER' && b.user.relationship === 'MEMBER') {
      return 1;
    }
    return 0;
  });

  return userCommunities;
}

function MyCommunities({ className, setMobileMenuOpen }: IMyCommunities): JSX.Element {
  const { t } = useTranslation();
  const { communities } = useLoggedInUser();
  const sortedCommunities = sortUserCommunities(communities);
  const communityLinks = sortedCommunities.map((community: ICommunity) => <CommunityLink key={community.slug} community={community} />);

  return (
    <Container className={className}>
      {communityLinks}
      <div className='my-communities-link' onClick={() => setMobileMenuOpen(false)}>
        <StyledLink to='/follow'>{`${t('My communities')} »`}</StyledLink>
      </div>
    </Container>
  );
}

export default MyCommunities;

const Container = styled.div`
  overflow-y: hide;
  max-height: 550px;

  @media (max-height: 1000px) {
    max-height: 390px;
  }

  @media (max-height: 890px) {
    max-height: 240px;
  }

  @media only screen and (max-height: 680px) {
    max-height: 160px;
  }

  @media only screen and (max-height: 590px) {
    max-height: 110px;
  }

  a {
    color: ${theme.mid};
  }

  p {
    color: ${theme.mid};
    text-align: center;
    margin-bottom: 15px;
    margin-top: 45px;
  }
`;

const StyledLink = styled(Link)`
  font-size: 12px;
  text-decoration: none;
  color: #9A9A9A !important;

  &:hover {
    color: ${theme.primary} !important;
  }
`;
