import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import ArticleList from 'components/page-template/ArticleList';
import Publications from 'components/Publications';
import TabNavigator from 'components/TabNavigator';
import { useLoggedInUser } from 'contexts/user-context/UserContext';
import { StyledLink } from 'styles/styled-components';
import { ActionButton } from 'styles/styled-components/button';
import { getMarketingUrl } from 'utils';

function ArticlesTabs(): JSX.Element {
  const { t } = useTranslation();
  const { user } = useLoggedInUser();
  const history = useHistory();

  const tabs = [
    {
      label: t('From my sources'),
      component: <ArticleList
        url='/api/articles/stream'
        emptyTabHeader={t('There have been no articles pulled in from your sources.')}
        emptyTabMessage={(
          <Trans>
            <StyledLink to='/feeds'>Follow sources of interest</StyledLink> to receive abstracts here and in your weekly email digest.
          </Trans>
      )}
      />,
    },
    {
      label: t('From my communities'),
      component: <ArticleList
        url='/api/articles/stream/communities'
        emptyTabHeader={t('There have been no member publications from your communities.')}
        emptyTabMessage={(
          <Trans>
            <StyledLink to='/follow'>Follow communities of interest</StyledLink> to see their member publications here and in your weekly email digest.
          </Trans>
        )}
      />,
    },
    {
      label: t('My articles'),
      component: <Publications
        emptyTabHeader={t('You have no articles featured on Sosido yet.')}
        emptyTabMessage={(
          <Trans>
            Learn more about <a target='_blank' href={`${getMarketingUrl()}/faq`} rel="noreferrer">how Sosido features member publications.</a>
          </Trans>
        )}
        userId={user.id}
      />,
    },
  ];

  return (
    <>
      <TabNavigator tabs={tabs} />
      <ActionButton onClick={() => history.push('/feeds')}>{t('Customize sources')}</ActionButton>
    </>
  );
}

export default ArticlesTabs;
