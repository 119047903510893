import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { AsyncButton } from './form/AsyncButton';
import { useGlobalUserContext } from 'contexts/user-context/UserContext';

function LogoutButton({ className }: { className?: string }): JSX.Element {
  const { userStateDispatch } = useGlobalUserContext();
  const { t } = useTranslation();
  const history = useHistory();

  async function handleLogout(): Promise<void> {
    await axios.post('/api/auth/logout');
    userStateDispatch({ type: 'logout' });
    history.push('/login');
  }

  return (
    <Container>
      <AsyncButton className={className} onClick={handleLogout}>{t('Log out')}</AsyncButton>
    </Container>
  );
}

export default LogoutButton;

const Container = styled.div`
  button {
    font-size: initial;
    box-shadow: none;
  }
`;
