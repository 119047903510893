import './index.css';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React, { Suspense } from 'react';
import { render } from 'react-dom';

import App from './App';
import './i18next/config';
import reportWebVitals from './reportWebVitals';
import Spinner from 'components/spinner/Spinner';

const consoleWarn = console.warn;
const SUPPRESSED_WARNINGS = ['The component styled.ul with the id of'];

console.warn = function filterWarnings(msg) {
  if (!SUPPRESSED_WARNINGS.some((entry) => msg.includes(entry))) {
    consoleWarn(msg);
  }
};

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_ENV,
  tracesSampleRate: 1.0,
  beforeBreadcrumb(breadcrumb, hint) {
    if (breadcrumb.category === 'console' && breadcrumb.level === 'log' && breadcrumb.message?.includes('i18next')) return null;
    return breadcrumb;
  },
});

render(
  <React.StrictMode>
    <Suspense fallback={<Spinner />}>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.querySelector('#root'),
);

reportWebVitals();
