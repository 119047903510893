import styled from 'styled-components';

import PageTitle from './PageTitle';
import SearchBar from './SearchBar';
import UserAvatar from './UserAvatar';
import { useLoggedInUser } from 'contexts/user-context/UserContext';
import { device } from 'styles/theme';

interface ITopBar {
  className?: string;
}

function TopBar({ className }: ITopBar): JSX.Element {
  const { user } = useLoggedInUser();

  return (
    <Header className={className}>
      <PageTitle user={user} className='page-title' />
      <div className='right-side'>
        <SearchBar className='search-bar' />
        <UserAvatar user={user} className='user-avatar' />
      </div>
    </Header>
  );
}

export default TopBar;

const Header = styled.header`
  width: 100%;
  display: flex;
  align-items: center;

  .right-side {
    display: flex;
    align-items: center;
    margin-left: auto;

    .search-bar {
      width: 374px;
    }
  }

  ${device.tablet} {
    flex-direction: column-reverse;
    align-items: flex-start;

    .right-side {
      width: 100%;
      margin-left: 0;
      margin-bottom: 20px;

      .user-avatar {
        display: none !important;
      }
    }
  }
`;
