import { IUserState } from './interfaces/IUserState';
import { IUserDto } from 'api/dtos/user.dto';
import { IUserCommunityDto } from 'api/dtos/userCommunity.dto';

export type UserAction =
  | { type: 'update', user?: IUserDto, communities?: IUserCommunityDto[] }
  | { type: 'logout' };

function userContextReducer(state: IUserState, action: UserAction): IUserState {
  switch (action.type) {
    case 'update': {
      const { user, communities } = action;
      return {
        ...state,
        user: user || state.user,
        communities: communities || state.communities,
      };
    }
    case 'logout': {
      return {
        user: undefined, communities: [],
      };
    }
    default:
      return state;
  }
}

export default userContextReducer;
