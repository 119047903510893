import * as Sentry from '@sentry/react';
import axios from 'axios';
import {
  useCallback, useEffect, useReducer, useState,
} from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { useLocation } from 'react-router-dom';

import { IUserState } from './interfaces/IUserState';
import { GlobalUserContext } from './UserContext';
import userContextReducer from './userContextReducer';
import { setupAnalyticsWithUser } from 'analytics';
import { IUserDto } from 'api/dtos/user.dto';
import { IUserCommunityDto } from 'api/dtos/userCommunity.dto';
import { AxiosWrapper } from 'components/AxiosWrapper';

interface IProps {
  children: JSX.Element | JSX.Element[];
}

const initialUserState: IUserState = {
  user: undefined,
  communities: [],
};

function validateStatus(status: number): boolean {
  return (status >= 200 && status < 300) || status === 403 || status === 401;
}

export default function UserContextProvider({ children }: IProps): JSX.Element {
  const { pathname, search } = useLocation();
  const { showBoundary } = useErrorBoundary();
  const [loading, setLoading] = useState(false);
  const [userState, userStateDispatch] = useReducer(userContextReducer, initialUserState);

  const authenticate = useCallback(async () => {
    const params = new URLSearchParams(search);
    const token = params.get('token');

    if (token) {
      const tokenAuth = await axios.post<IUserDto>('/api/auth/login/jwt', { jwt: token }, { validateStatus });
      if (tokenAuth.status === 200) {
        params.delete('token');
        return tokenAuth;
      }
    }

    const cookieAuth = await axios.get<IUserDto>('/api/auth/me', { validateStatus });
    return cookieAuth;
  }, [search]);

  useEffect(() => {
    async function fetchUserState(): Promise<void> {
      const nonAuthPaths = ['/login', '/signup', '/password-reset', '/'];
      if (userState.user) {
        return;
      }

      try {
        if (!nonAuthPaths.includes(pathname) && !pathname.startsWith('/articles/')) setLoading(true);

        const userResponse = await authenticate();
        const user = userResponse.data;
        if (userResponse.status === 403 || userResponse.status === 401) {
          setupAnalyticsWithUser();
          return;
        }

        const communitiesResponse = await axios.get<IUserCommunityDto[]>(`/api/communities/user/${user.id}`);

        userStateDispatch({
          type: 'update', user, communities: communitiesResponse.data,
        });

        setupAnalyticsWithUser({ ...user, communities: communitiesResponse.data });
      } catch (e) {
        console.log('error', e);
        showBoundary(e);
      } finally {
        setLoading(false);
      }
    }
    void fetchUserState();
  }, [pathname, userState, userState.user, showBoundary, search, authenticate]);

  useEffect(() => {
    const { user } = userState;
    if (user) Sentry.setUser({ email: user.email });
    else Sentry.setUser(null);
  }, [userState.user]);

  return (
    <AxiosWrapper requests={[{ loading, error: null }]}>
      <GlobalUserContext.Provider value={{ userState, userStateDispatch }}>
        {children}
      </GlobalUserContext.Provider>
    </AxiosWrapper>
  );
}
