import { createContext, useContext } from 'react';

interface INavbarContext {
  isMobileMenuOpen: boolean;
  setMobileMenuOpen(this: void, value: boolean): void;
}

export const NavbarContext = createContext<INavbarContext>({
  isMobileMenuOpen: false,
  setMobileMenuOpen: () => {

  },
});

export function useNavBarContext(): INavbarContext {
  return useContext(NavbarContext);
}
