import Linkify from 'react-linkify';

export default function LinkifyInNewTab({ children }: { children: JSX.Element[] | JSX.Element | string }): JSX.Element {
  const openInNewTab = (href: string, text: string, key: number): JSX.Element => (
    <a href={href} key={key} target='_blank' rel='noopener noreferrer'>
      {text}
    </a>
  );

  return (
    <Linkify componentDecorator={openInNewTab}>
      {children}
    </Linkify>
  );
}
