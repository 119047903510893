import { format } from 'date-fns';
import styled from 'styled-components';

import { IArticleDto } from 'api/dtos/article.dto';
import { FeedTypes } from 'api/tables';
import FeedBadgeSquare from 'styles/styled-components/FeedBadgeSquare';
import theme, { device } from 'styles/theme';

interface IArticleHeader {
  article: IArticleDto;
  className?: string
}

export default function ArticleHeader({ article, className }: IArticleHeader): JSX.Element {
  const formattedDate = format(new Date(article.pub_date), 'PP');

  if (article.linked_authors) {
    article.feed ??= {
      title: 'Sosido Member Publication', colour: theme.primary.substring(1), type: FeedTypes.JOURNAL,
    };
  }

  return (
    <Container className={className}>
      <div className='left-side'>
        <FeedBadgeSquare color={`#${article.feed?.colour || 'ffffff'}`} />
        <h4>{article.feed?.title}</h4>
      </div>
      <p className='date'>{formattedDate}</p>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;

  .date {
    white-space: nowrap;
    color: #BAB9B9;
    margin-bottom: 15px;
    font-family: Inter, sans-serif;
  }

  .left-side {
    display: flex;
    align-items: center;

    h4 {
      text-align: left;
      color: #BAB9B9;
      width: auto;
      font-weight: 400;
      font-size: 14px;
      font-family: Inter, sans-serif;
    }
  }

  ${device.tablet} {
    flex-direction: row;
    align-items: baseline;

    .left-side {
      margin-bottom: 16px;
    }

    .date {
      font-size: 14px !important;
      position: relative;
      bottom: 7.5px;
    }
  }

  ${device.mobile} {
    flex-direction: row;
    margin-bottom: -16px;

    .left-side {
      h4 {
        font-size: 14px;
        max-width: 340px;
      }
    }
  }
`;
