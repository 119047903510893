import {
  AtomicBlockUtils, ContentBlock, ContentState, Modifier, EditorState as OldEditorState, SelectionState, convertFromHTML,
} from 'draft-js';

export class EditorState extends OldEditorState {
  static removeBlock(state: EditorState, block: ContentBlock): EditorState {
    const currentContent = state.getCurrentContent();
    let newCurrentContent = Modifier.removeRange(
      currentContent,
      new SelectionState({
        anchorKey: block.getKey(),
        anchorOffset: 0,
        focusKey: block.getKey(),
        focusOffset: block.getLength(),
      }),
      'backward',
    );
    const blockMap = newCurrentContent.getBlockMap().delete(block.getKey());
    newCurrentContent = newCurrentContent.merge({
      blockMap,
    }) as ContentState;
    return EditorState.createWithContent(newCurrentContent);
  }

  /**
    Unnecessary empty text blocks can appear if an atomic block was inserted
    at the beginning of the draft and then moved somewhere else
  * */
  static trimEmptyBlocks(state: EditorState): EditorState {
    const blocks = state.getCurrentContent().getBlocksAsArray();
    const anyAtomicBlockAtStart = blocks.findIndex((block) => block.getType() === 'atomic') === 1;
    if (anyAtomicBlockAtStart) return state;

    const anyEmptyUnstyledBlockAtStart = blocks.findIndex((block) => block.getType() === 'unstyled' && block.getText().trim() === '') === 0;
    if (!anyEmptyUnstyledBlockAtStart) return state;

    const newBlocks = blocks.slice(1);
    const newContentState = ContentState.createFromBlockArray(newBlocks);

    return EditorState.createWithContent(newContentState);
  }

  /**
   * Any blocks with an image entity need to be converted to atomic blocks to properly render
  */
  private static insertAtomicBlocks(state: EditorState): EditorState {
    const blocks = state.getCurrentContent().getBlocksAsArray();
    let newEditorState = state;

    const blocksWithImagesKeys = blocks.filter((block) => {
      const entityKey = block.getEntityAt(0);
      if (entityKey === null) return false;

      const entity = state.getCurrentContent().getEntity(entityKey);
      return entity.getType() === 'IMAGE';
    }).map((block) => block.getKey());

    blocksWithImagesKeys.forEach((blockKey) => {
      let imageEntityKey = newEditorState
        .getCurrentContent()
        .getBlockForKey(blockKey)
        .getEntityAt(0);
      // process of creating an atomic block attempts to create empty text blocks before and after the atomic block
      newEditorState = AtomicBlockUtils.insertAtomicBlock(newEditorState, imageEntityKey, ' ');

      // imageEntity will be null after the atomic block is inserted if it was at index 0
      // if it wasn't, then it need to be manually replaced
      imageEntityKey = newEditorState
        .getCurrentContent()
        .getBlockForKey(blockKey)
        .getEntityAt(0);
      if (!imageEntityKey) return;

      const lastInsertedAtomicBlock = newEditorState
        .getCurrentContent().getBlocksAsArray()
        .find((block) => block.getType() === 'atomic')!;
      const selectionState = SelectionState.createEmpty(blockKey);
      newEditorState = AtomicBlockUtils.moveAtomicBlock(newEditorState, lastInsertedAtomicBlock, selectionState, 'replace');
      newEditorState = this.trimEmptyBlocks(newEditorState);
    });

    return newEditorState;
  }

  static createWithBody(body: string): EditorState {
    const blockArray = convertFromHTML(body);
    const contentState = ContentState.createFromBlockArray(blockArray.contentBlocks, blockArray.entityMap);
    let state = EditorState.createWithContent(contentState);

    state = this.insertAtomicBlocks(state);

    return state;
  }
}
