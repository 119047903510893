import { DetectorOptions } from 'i18next-browser-languagedetector';

import { IAsyncCustomDetector } from '../asyncBrowserLanguageDetector';

// based on https://github.com/i18next/i18next-browser-languageDetector/blob/master/browserLookups/navigator.js
const navigatorDetector: IAsyncCustomDetector = {
  name: 'navigator',

  lookup(options: DetectorOptions) {
    const found = [];

    if (typeof navigator !== 'undefined') {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (navigator.languages) { // chrome only; not an array, so can't use .push.apply instead of iterating
        for (const lang of navigator.languages) {
          found.push(lang);
        }
      }
      if (navigator.language) {
        found.push(navigator.language);
      }
    }
    return found.length > 0 ? found : undefined;
  },
  lookupAsync(options: DetectorOptions) {
    return Promise.resolve(this.lookup(options));
  },
};

export default navigatorDetector;
