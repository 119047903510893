import styled from 'styled-components';

const ArticleTitle = styled.h3`
  font-family: Lora, serif !important;
  font-size: 23px;
  line-height: 30px;
  color: #000;
  margin-bottom: 20px;
  padding-bottom: 6px;
  padding-right: 4%;
  font-weight: 500;
`;

export default ArticleTitle;
