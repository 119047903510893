import { createGlobalStyle } from 'styled-components';

import {
  a, button, h1, h2, h3, h4, h5, h6, input, label, p,
} from './styled-components';
import theme, { device } from './theme';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Liberation Sans', Inter, sans-serif;
    -webkit-touch-callout: none;
  }

  hr {
    border-color: ${theme.grayDark};
  }

  button {
    ${button}
  }

  a {
    ${a}
  }

  h1 {
    ${h1}
  }

  h2 {
    ${h2}
  }

  h3 {
    ${h3}
  }

  h4 {
    ${h4}
  }

  h5 {
    ${h5}
  }

  h6 {
    ${h6}
  }

  input {
    ${input}
  }

  p {
    ${p}
  }

  label {
    ${label}
  }

  textarea {
    user-select: auto;
  }

  ${device.mobile} {
    html {
      display: block;
    }
  }
`;

export default GlobalStyle;
