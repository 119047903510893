import DOMPurify from 'dompurify';

// replace all `https://sosido.com/questions/${id}` hrefs with `/discussions/${id}`
export function sanitizeBody(body: string): string {
  const processedText = body
    .replace(/<a href="https?:\/\/sosido\.com\/questions\/(\d+)"/g, '<a href="$1"')
    .replace(/<a href="https?:\/\/www\.sosido\.com\/questions\/(\d+)"/g, '<a href="$1"');

  return DOMPurify.sanitize(processedText, { ADD_ATTR: ['target'] });
}
