import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import classNames from 'classnames';
import { parse } from 'query-string';
import { useEffect, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { When } from 'react-if';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { SchemaOf, object, string } from 'yup';

import { AsyncButton } from 'components/form/AsyncButton';
import Input from 'components/form/Input';
import ValidationErrors from 'components/form/validation-errors';
import SignInTemplate from 'components/page-template/SignInTemplate';
import { device } from 'styles/theme';

interface IChangePasswordInputs {
  newPassword: string;
  confirmPassword: string;
}

const schema: SchemaOf<IChangePasswordInputs> = object({
  newPassword: string().min(8, 'Your password must be at least 8 characters long')
    .required()
    .test(
      'is-same',
      'Passwords must match',
      (value, context) => value === context.parent.confirmPassword,
    ),
  confirmPassword: string().min(8, 'Your password must be at least 8 characters long')
    .required()
    .test(
      'is-same',
      'Passwords must match',
      (value, context) => value === context.parent.newPassword,
    ),
});

export default function ApplyPasswordResetPage(): JSX.Element {
  const { t } = useTranslation();
  const formMethods = useForm<IChangePasswordInputs>({
    resolver: yupResolver(schema),
  });
  const { register, handleSubmit, formState } = formMethods;
  const { showBoundary } = useErrorBoundary();
  const history = useHistory();
  const [verifiedToken, setVerifiedToken] = useState<boolean>(false);

  const { search } = useLocation();
  const values = parse(search);
  const jwt = values.token;

  useEffect(() => {
    async function verifyToken(): Promise<void> {
      try {
        const response = await axios.post('/api/auth/password/reset/verify', {
          jwt,
        });

        if (response.status === 200) {
          setVerifiedToken(true);
        }
      } catch (error) {
        showBoundary(error);
      }
    }
    void verifyToken();
  }, [showBoundary, jwt]);

  async function onSubmit(data: IChangePasswordInputs): Promise<void> {
    try {
      const response = await axios.post('/api/auth/password/reset/apply', {
        jwt,
        password: data.newPassword,
      });

      if (response.status === 200) {
        history.push('/login');
      }
    } catch (error) {
      showBoundary(error);
    }
  }

  const rule = { required: ValidationErrors.REQUIRED };
  const newPassword = classNames('new-password', { invalid: !!formState.errors.newPassword });
  const confirmPassword = classNames('confirm-password', { invalid: !!formState.errors.confirmPassword });

  return (
    <When condition={verifiedToken}>
      <SignInTemplate>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <h1>{t('Create new password')}</h1>
          <FormProvider {...formMethods}>
            <Input className={newPassword} label='Create password' type="password" {...register('newPassword', rule)} />
            <p><sup>*</sup>{t('Password must be at least 8 characters long')}</p>
            <Input className={confirmPassword} label='Confirm password' type="password" {...register('confirmPassword', rule)} />
            <p><sup>*</sup>{t('Both passwords must match')}</p>
            <AsyncButton className='change-password' formState={formState}>{t('Change password')}</AsyncButton>
          </FormProvider>
        </StyledForm>
      </SignInTemplate>
    </When>
  );
}

const StyledForm = styled.form`
  width: 100%;

  h1 {
    font-size: 40px;
    font-family: 'Liberation Sans', Helvetica, sans-serif;
    margin-bottom: 22px;
  }

  .new-password,
  .confirm-password {
    label {
      text-transform: none;
    }

    input {
      border-radius: 10px;
    }
  }

  p {
    color: #888;
    line-height: 40px;
    font-size: 16px;
  }

  & > p:first-of-type {
    margin-bottom: 22px;
  }

  & > p:last-of-type {
    margin-bottom: 27px;
  }

  .change-password {
    width: 268px;
    height: 61px;
  }

  ${device.mobile} {
    h1 {
      font-size: 35px;
      margin-bottom: 17px;
    }

    & > p:last-of-type {
      margin-bottom: 21px;
    }

    .change-password {
      width: 100%;
    }
  }
`;
