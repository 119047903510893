/* eslint-disable react/jsx-no-useless-fragment */
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import { IUserCommunityDto } from 'api/dtos/userCommunity.dto';

export function formatCommunityList(userCommunities: IUserCommunityDto[]): JSX.Element {
  const followedCommunities = userCommunities.filter((community) => community.user.relationship === 'FOLLOWER');
  const memberCommunities = userCommunities.filter((community) => community.user.relationship === 'MEMBER');
  const fPluralized = followedCommunities.length > 1 ? 'communities' : 'community';
  const mPluralized = memberCommunities.length > 1 ? 'communities' : 'community';

  if (memberCommunities.length > 0 && followedCommunities.length > 0) {
    return (
      <>
        <Trans><b>member</b> of the</Trans> {formatList(memberCommunities)} {t(mPluralized)}&nbsp;
        <Trans>on Sosido and a <b>follower</b> of the</Trans> {formatList(followedCommunities)} {t(fPluralized)}
      </>
    );
  }
  if (memberCommunities.length > 0) {
    return <><Trans><b>member</b> of the</Trans> {formatList(memberCommunities)} {t(mPluralized)}&nbsp;{t('on Sosido')}</>;
  }
  if (followedCommunities.length > 0) {
    return <><Trans><b>follower</b> of the</Trans> {formatList(followedCommunities)} {t(fPluralized)}</>;
  }

  throw new Error('Must be a member or a follower of a community to generate a community list!');

  function formatList(list: IUserCommunityDto[]): JSX.Element {
    if (list.length > 1) {
      const listWithoutLastElement = list.slice(0, list.length - 1);
      const linksWithoutLastElement = listWithoutLastElement.map((community, i) => (
        <><Link to={`/communities/${community.slug}`}><b>{community.name}</b></Link>{listWithoutLastElement.length === i + 1 ? '' : ', '}</>
      ));
      const lastElement = list[list.length - 1];
      linksWithoutLastElement.push(<> {t('and')} <Link to={`/communities/${lastElement.slug}`}><b>{lastElement.name}</b></Link></>);
      return <>{linksWithoutLastElement}</>;
    }

    const slug = userCommunities.find((community) => community.name === list[0].name.trim())?.slug;
    return <Link to={`/communities/${slug!}`}><b>{list[0].name}</b></Link>;
  }
}
