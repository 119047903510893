import { createRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import styled from 'styled-components';

import { device } from 'styles/theme';

interface IScrollbar {
  children: React.ReactNode;
  className?: string;
}

export default function Scrollbar({ children, className }: IScrollbar): JSX.Element {
  const scrollRef = createRef<Scrollbars>();

  return (
    <CustomScrollbar
      autoHide
      ref={scrollRef}
      autoHideTimeout={1000}
      autoHideDuration={200}
      className={className}
      renderView={({ style, ...props }) => <div {...props} style={style} className='view' />}
      renderThumbVertical={({ ...props }) => <div {...props} className='thumb' />}
      renderTrackVertical={({ ...props }) => <div {...props} className='track' />}
    >
      {children}
    </CustomScrollbar>
  );
}

const CustomScrollbar = styled(Scrollbars)`
  width: 100% !important;
  height: 75vh !important;

  ${device.short} {
    height: 70vh !important;
  }

  ${device.tiny} {
    height: 65vh !important;
  }

  .view {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: scroll;
  }

  .thumb {
    height: 528px;
    transform: translateY(195.701px);
    position: relative;
    display: block;
    width: 4.5px !important;
    cursor: pointer;
    border-radius: inherit;
    background-color: rgb(0 0 0 / 20%);

    ${device.tablet} {
      visibility: hidden;
    }
  }

  .track {
    position: absolute;
    width: 6px;
    transition: opacity 200ms ease 0s;
    opacity: 0;
    right: 2px;
    bottom: 2px;
    top: 2px;
    border-radius: 3px;
  }
`;
