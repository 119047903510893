import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { When } from 'react-if';
import styled from 'styled-components';

import { ICommunity } from 'api/tables';
import Clock from 'assets/icons/clock.svg';
import WebLink from 'assets/icons/community-web-link.svg';
import Facebook from 'assets/icons/facebook.svg';
import Linkedin from 'assets/icons/linkedin.svg';
import Twitter from 'assets/icons/twitter.svg';
import { device } from 'styles/theme';

export default function Info({ className, community }: { community: ICommunity, className?: string }): JSX.Element {
  const facebookUrl = community.facebook_url ? community.facebook_url : '' as string;
  const linkedinUrl = community.linkedin_url ? community.linkedin_url : '' as string;
  const twitterUrl = community.twitter_url ? community.twitter_url : '' as string;
  const { t } = useTranslation();
  const regex = /https?:\/\/www./g;

  return (
    <Container className={className}>
      <div className='joined'>
        <img src={Clock} />
        <p>{t('{{community}} joined Sosido in {{date}}', { community: community.name, date: format(new Date(community.created_at), 'MMM yyyy') })}</p>
      </div>

      <When condition={!!community.url}>
        <div className='web-link'>
          <img src={WebLink} />
          <a className='link' target='_blank' href={community.url as string} rel='noreferrer'>{community.url?.replace(regex, '')}</a>
        </div>
      </When>

      <div className='socials'>
        <When condition={community.facebook_url}>
          <a href={facebookUrl} target='_blank' rel='noopener noreferrer'><img src={Facebook} /></a>
        </When>
        <When condition={community.linkedin_url}>
          <a href={linkedinUrl} target='_blank' rel='noopener noreferrer'><img src={Linkedin} /></a>
        </When>
        <When condition={community.twitter_url}>
          <a href={twitterUrl} target='_blank' rel='noopener noreferrer'><img src={Twitter} /></a>
        </When>
      </div>

    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px 25px;
  font-size: 14px;
  gap: 30px;

  .joined,
  .web-link {
    display: flex;

    img {
      margin-right: 10px;
      width: 18px;
      height: 18px;
      filter: invert(11%) sepia(79%) saturate(7141%) hue-rotate(234deg) brightness(103%) contrast(112%);
    }

    p,
    a {
      inline-size: 162px;
      overflow-wrap: break-word;
    }

    p {
      color: black;
    }
  }

  .socials img {
    margin-right: 13px;
    filter: invert(85%) sepia(0%) saturate(653%) hue-rotate(165deg) brightness(91%) contrast(88%);
  }

  ${device.tablet} {
    padding: 30px 25px;

    .socials {
      img {
        margin-right: 24px;
      }
    }
  }

  ${device.mobile} {
    padding: 40px 30px;
    gap: 0;

    .joined {
      margin-right: 20px;

      p {
        inline-size: 132px;
        width: 100%;
      }
    }

    .weblink {
      width: 50%;

      a {
        overflow-wrap: break-word;
      }
    }

    .socials {
      margin-top: 23px;
    }
  }
`;
