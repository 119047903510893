import { ResponseValues } from 'axios-hooks';
import { ReactNode } from 'react';
import { useErrorBoundary } from 'react-error-boundary';

import Spinner from './spinner/Spinner';

interface IAxiosWrapper {
  requests: ResponseValues<unknown, unknown, Error>[];
  children?: ReactNode;
}

export function AxiosWrapper({ requests, children }: IAxiosWrapper): JSX.Element {
  const error = requests.find((c) => c.error)?.error;
  const loading = requests.some((c) => c.loading);
  const { showBoundary } = useErrorBoundary();

  if (error) showBoundary(error);
  if (loading) return <Spinner />;

  return (
    <>
      {children}
    </>
  );
}
