import styled from 'styled-components';

import { ICommunity } from 'api/tables';
import { sanitizeText } from 'api/utils';
import LinkifyInNewTab from 'components/LinkifyInNewTab';
import theme from 'styles/theme';

interface IAbout {
  className: string;
  community: ICommunity;
}

export default function About({ className, community }: IAbout): JSX.Element {
  return (
    <Container className={className}>
      <p>
        <LinkifyInNewTab>
          {sanitizeText(community.description || '')}
        </LinkifyInNewTab>
      </p>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  padding: 25px 32px;
  overflow-y: auto;

  p {
    color: ${theme.text};
    padding: 8px 0;
    font-size: 16px;
    line-height: 25px;
    font-family: 'Liberation Sans', Inter, sans-serif;
  }
`;
