import { useEffect, useRef } from 'react';

export default function useNonInitialEffect(effect: React.EffectCallback, deps?: React.DependencyList): void {
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      return effect();
    }
  }, deps);
}
