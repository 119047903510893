import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Check from 'assets/icons/check.svg';
import SignInTemplate from 'components/page-template/SignInTemplate';
import { StyledLink } from 'styles/styled-components';
import { device } from 'styles/theme';

export default function CheckEmail(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Container>
      <SignInTemplate>
        <div className="check-email">
          <img src={Check} alt="Check mark" />
          <h1>{t('Check your email')}</h1>
          <p>{t('Instructions to reset your password have been sent to your email.')}</p>
          <p>{t('Did not receive an email?')} <StyledLink to="/password-reset">{t('Resend')}</StyledLink></p>
        </div>
      </SignInTemplate>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;

  .check-email {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      margin-bottom: 28px;
    }

    h1 {
      font-size: 40px;
      font-family: 'Liberation Sans', Helvetica, sans-serif;
      margin-bottom: 37px;
      text-align: center;
    }

    p {
      font-size: 16px;
      font-family: 'Liberation Sans', Helvetica, sans-serif;
      margin-bottom: 38px;
      color: #888;
      text-align: center;
    }
  }

  ${device.mobile} {
    .check-email {
      p {
        font-size: 18px;
      }
    }
  }
`;
